import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { SignUpSendSMS, SignUpVerifySMSCode, changeUserProps, SignUpInitGoogle2fa, SignUpCompleteRegistration, LogOut } from '../../store/action-creators/UserActions';
import { Input, FormFeedback, Button, Row, Spinner, Modal, Col, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelectInput from '../../components/CustomSelectInput';
import { IoIosPhonePortrait } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import { isEmpty } from 'lodash';
import { ROUTE_SIGNUP, ROUTE_HOME } from '../../constants/routes';

const SignupSms = () => {
    const userStore = useSelector(store => JSON.stringify(store.user.user));
    let history = useHistory();

    // If no username, email and password loaded redirect to signup home
    if (isEmpty(JSON.parse(userStore))) {
        history.push(ROUTE_SIGNUP);
    }

    const dispatch = useDispatch();
    const googleAuthImg = useSelector(store => store.user.googleAuthImg);
    const googleAuthCode = useSelector(store => store.user.googleAuthCode);
    const errorStore = useSelector(store => store.user.error);

    const countries = [
        { imgCode: "ar", label: "Argentina", phoneCode: "+54" },
        { imgCode: "uy", label: "Uruguay", phoneCode: "+598" },
        { imgCode: "br", label: "Brasil", phoneCode: "+55" },
        { imgCode: "pe", label: "Perú", phoneCode: "+51" },
        { imgCode: "cl", label: "Chile", phoneCode: "+56" },
        { imgCode: "py", label: "Paraguay", phoneCode: "+595" },
        { imgCode: "ee", label: "Estonia", phoneCode: "+372" },
        { imgCode: "es", label: "España", phoneCode: "+34" }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryError, setCountryError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const [smsCode, setSmsCode] = useState("");
    const [smsCodeError, setSmsCodeError] = useState("");
    const [isVerifyLoading, setIsVerifyLoading] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    const [successVerificationModal, setSuccessVerificationModal] = useState(false);

    const [initGoogleLoading, setInitGoogleLoading] = useState(false);

    const [googleAuthModal, setGoogleAuthModal] = useState(false);
    const [googleAuthLoading, setGoogleAuthLoading] = useState(false);

    const [closeGoogleModal, setCloseGoogleModal] = useState(false);

    const [emailConfirmationModal, setEmailConfirmationModal] = useState(false);

    const [errorModal, setErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState("");

    const onChangeCountry = (selectedCountry) => {

        if (!isEmpty(selectedCountry)) {
            setCountry(selectedCountry);
            setCountryError("");
        }

    }

    const SendSMS = () => {

        if (isEmpty(country)) {
            setCountryError("Seleccione un pais.");
        }

        if (!phoneNumber) {
            setPhoneNumberError("Ingrese un Celular.");
        }

        if (!isEmpty(country) && phoneNumber) {
            let phone = country.phoneCode + phoneNumber;

            setIsLoading(true);

            dispatch(SignUpSendSMS({ telefono: phone }))
                .then(result => {
                    setIsLoading(false);
                    setVerifyModal(true);
                })
                .catch(error => {
                    console.log(error)
                    setIsLoading(false);
                    setVerifyModal(false);
                    showError();
                });
        }

    }

    const VerifySMSCode = () => {

        if (!smsCode) {
            setSmsCodeError("Debe ingresar el código.");
        } else {
            setSmsCodeError("");
            setIsVerifyLoading(true);
            let phone = country.phoneCode + phoneNumber;
            dispatch(SignUpVerifySMSCode({ codigo: smsCode, telefono: phone }))
                .then(result => {
                    const savedUser = JSON.parse(userStore);                    
                    let user = Object.assign({}, savedUser, { provider: "email", telefono: phone });
                    dispatch(changeUserProps({ user: user }))
                    setSmsCodeError("");
                    setIsVerifyLoading(false);
                    setVerifyModal(false);
                    setSuccessVerificationModal(true);
                })
                .catch(error => {
                    setIsVerifyLoading(false);
                    setSmsCodeError("Código incorrecto");                                    
                });
        }

    }

    const initGoogle2Fa = () => {
        let user = JSON.parse(userStore);
        let userEmail = user.email;

        if (userEmail) {
            setInitGoogleLoading(true)
            dispatch(SignUpInitGoogle2fa({ email: userEmail }))
                .then(result => {
                    setInitGoogleLoading(false);
                    setGoogleAuthModal(true);
                    setSuccessVerificationModal(false);
                })
                .catch(error => {
                    setInitGoogleLoading(false);
                    setGoogleAuthModal(false);
                    setSuccessVerificationModal(false);
                    showError();
                });
        }

    }

    const completeRegistration = () => {
        let user = JSON.parse(userStore);

        if (!isEmpty(user)) {
            setGoogleAuthLoading(true);
            dispatch(SignUpCompleteRegistration(user))
                .then(result => {
                    setGoogleAuthLoading(false);
                    setGoogleAuthModal(false);
                    setEmailConfirmationModal(true);
                })
                .catch(error => {
                    setGoogleAuthLoading(false);
                    setGoogleAuthModal(false);
                    setEmailConfirmationModal(false);
                    showError();
                });
        }
    }

    const showError = () => {
        setErrorModalMessage(errorStore);
        setErrorModal(true);
    }

    const cancelRegistration = (route) => {
        let token = sessionStorage.getItem('token');
        dispatch(LogOut({
            token
        }))
            .then(result => {
                history.push(route);
            })
            .catch(error => {
                history.push(route);
            });
    }

    const openInNewTab = (link) => {
        window.open(link, "_blank", "noreferrer noopener");
    };

    const toPlayStore = () => {
        openInNewTab("https://play.google.com/store/apps/details?id=com.greenbondmeter.gbmcoin");
    }

    const toAppStore = () => {
        openInNewTab("https://www.apple.com/la/ios/app-store/");
    }

    return (
        <div className='signup' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
            <img
                src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                alt="GBM Logo"
                className='gbm-logo'
            />
            {
                isLoading ?
                    <Spinner type="grow" color="success" style={{ marginTop: "2rem" }} />
                    :
                    <div className="form-container">
                        <div className="welcome-message" style={{ marginBottom: "1rem" }}>
                            {"Verificación telefónica:"}
                        </div>
                        <div className="signup-form signup-sms">
                            <Row className="basic-row">
                                <div className="signup-input-container">
                                    <CustomSelectInput countries={countries} handleChange={onChangeCountry} selectedOption={country} isInvalid={isEmpty(country) && countryError} />
                                    {
                                        isEmpty(country) && countryError ?
                                            <FormFeedback className="signup-formfeedback">{countryError}</FormFeedback>
                                            :
                                            null
                                    }
                                </div>
                            </Row>
                            <Row className="basic-row">
                                <div className="signup-input-container">
                                    <Input
                                        id={"phone"}
                                        placeholder="&#xf095; &nbsp; Número de Celular"
                                        type={"text"}                                        
                                        className="signup-input"
                                        autoComplete="new-password"
                                        invalid={!!phoneNumber && !!phoneNumberError}
                                        value={phoneNumber}
                                        onChange={event => setPhoneNumber(event.target.value)}
                                    />
                                    {
                                        !phoneNumber && phoneNumberError ?
                                            <FormFeedback className="signup-formfeedback">{phoneNumberError}</FormFeedback>
                                            :
                                            null
                                    }
                                </div>
                            </Row>
                            <Row className='basic-row' style={{ marginTop: "1rem" }}>
                                <Button
                                    color=""
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                    className='signup-button'
                                    onClick={() => SendSMS()}
                                >
                                    {'ENVIAR SMS'}
                                </Button>
                            </Row>
                        </div>
                    </div>
            }
            <Modal isOpen={verifyModal} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <IoIosPhonePortrait size={100} />
                    </Row>
                    {
                        isVerifyLoading ?
                            <Spinner type="grow" color="success" style={{ marginTop: "2rem" }} />
                            :
                            <>
                                <Row className="signup-modal-row">
                                    {"Ingrese el código de verificación recibido"}
                                </Row>
                                <Row className="signup-modal-row">
                                    <div className="signup-input-container">
                                        <Input
                                            id={"smsCode"}
                                            placeholder="&#xf05d; &nbsp; Código SMS"
                                            type={"text"}
                                            className="signup-input inputSMS"
                                            autoComplete="new-password"
                                            invalid={!!smsCodeError}
                                            value={smsCode}                                            
                                            onChange={event => setSmsCode(event.target.value)}
                                        />
                                        {
                                            smsCodeError ?
                                                <FormFeedback className="signup-formfeedback">{smsCodeError}</FormFeedback>
                                                :
                                                null
                                        }
                                    </div>
                                </Row>
                                <Row className='signup-modal-row button'>
                                    <Button
                                        color=""
                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                        className='signup-button'
                                        onClick={() => VerifySMSCode()}
                                    >
                                        {'VERIFICAR'}
                                    </Button>
                                </Row>
                                <Row className="signup-modal-row">
                                    {"El SMS con el código puede tardar hasta 10 min en llegar. Si ha transcurrido mas tiempo vuelva a enviarlo."}
                                </Row>
                                <Row className='signup-modal-row button'>
                                    <Button
                                        color=""
                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                        className='login-button'
                                        onClick={() => SendSMS()}
                                    >
                                        {'REENVIAR SMS'}
                                    </Button>
                                </Row>
                            </>
                    }

                </div>
            </Modal>

            <Modal isOpen={successVerificationModal} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <FaRegCheckCircle size={100} />
                    </Row>
                    {
                        initGoogleLoading ?
                            <Spinner type="grow" color="success" style={{ marginTop: "2rem" }} />
                            :
                            <>
                                <Row className="signup-modal-row">
                                    {"Verificación Exitosa!"}
                                </Row>
                                <Row className='signup-modal-row button'>
                                    <Button
                                        color=""
                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                        className='signup-button'
                                        onClick={() => initGoogle2Fa()}
                                    >
                                        {'CONTINUAR'}
                                    </Button>
                                </Row>
                            </>
                    }
                </div>
            </Modal>

            <Modal isOpen={googleAuthModal} centered className="signup-modal">
                <div className="signup">
                    <Row className="modal-google-row" >
                        <img
                            src={process.env.PUBLIC_URL + '/images/login/googleauth.png'}
                            alt="Google Authenticator"
                            className='modal-img'
                        />
                    </Row>
                    {
                        googleAuthLoading ?
                            <Spinner type="grow" color="success" style={{ marginTop: "2rem" }} />
                            :
                            <>
                                <Row className="modal-google-row" >
                                    <p>
                                        {"Debes descargar y configurar la aplicación \"Autenticador de Google\" para poder iniciar sesión. La misma se encuentra disponible en:"}
                                    </p>
                                </Row>
                                <Row className="modal-google-row" >
                                    <Col
                                        xs={12} sm={12} md={6} lg={6} xl={6}
                                        className="signup-modal-col store"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/home/04-descarga-app/home_12-botongoogleplay.svg'}
                                            alt="Google Play"
                                            className='modal-descarga-app android'
                                            onClick={() => toPlayStore()}
                                        />
                                    </Col>
                                    <Col
                                        xs={12} sm={12} md={6} lg={6} xl={6}
                                        className="signup-modal-col store"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/home/04-descarga-app/home_13-botonapplestore.svg'}
                                            alt="App Store"
                                            className='modal-descarga-app ios'
                                            onClick={() => toAppStore()}
                                        />
                                    </Col>
                                </Row>
                                <Row className="modal-google-row" >
                                    <p>
                                        {"Una vez instalada abre la App, Escanea el siguiente codigo QR o ingresar la clave que se muestra debajo del mismo y presiona Continuar."}
                                    </p>
                                </Row>
                                <Row className="modal-google-row" >
                                    <img
                                        src={googleAuthImg}
                                        alt="Google Authenticator QR Code"
                                        className='modal-qr'
                                    />
                                </Row>
                                <Row className="modal-google-row" >
                                    <span>
                                        {googleAuthCode}
                                    </span>
                                </Row>
                                <Row className="modal-google-row" >
                                    <Col
                                        xs={12} sm={12} md={6} lg={6} xl={6}
                                        className="signup-modal-col"
                                    >
                                        <Button
                                            color=""
                                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                            className='login-button'
                                            onClick={() => setCloseGoogleModal(true)}
                                        >
                                            {'CANCELAR'}
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={12} sm={12} md={6} lg={6} xl={6}
                                        className="signup-modal-col"
                                    >
                                        <Button
                                            color=""
                                            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                            className='signup-button'
                                            onClick={() => completeRegistration()}
                                        >
                                            {'CONTINUAR'}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </Modal>

            <Modal isOpen={closeGoogleModal} centered className="signup-modal">
                <div className="signup">
                    <ModalHeader>
                        <Row className="modal-google-row" >
                            <p>
                                {"ADVERTENCIA"}
                            </p>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row className="modal-google-row" >
                            <p>
                                {"Configurar el Autenticador de Google es un paso requerido, si lo cancela perderá su progreso y deberá realizar los pasos previos nuevamente."}
                            </p>
                            <p>
                                {"¿Desea cancelar su registro?"}
                            </p>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row className="modal-google-row" >
                            <Col
                                xs={12} sm={12} md={6} lg={6} xl={6}
                                className="signup-modal-col"
                            >
                                <Button
                                    color=""
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)`,
                                        paddingLeft: "3rem",
                                        paddingRight: "3rem"
                                    }}
                                    className='login-button'
                                    onClick={() => cancelRegistration(ROUTE_SIGNUP)}
                                >
                                    {'SI'}
                                </Button>
                            </Col>
                            <Col
                                xs={12} sm={12} md={6} lg={6} xl={6}
                                className="signup-modal-col"
                            >
                                <Button
                                    color=""
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)`,
                                        paddingLeft: "3rem",
                                        paddingRight: "3rem"
                                    }}
                                    className='signup-button'
                                    onClick={() => setCloseGoogleModal(false)}
                                >
                                    {'NO'}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </div>
            </Modal>

            <Modal isOpen={emailConfirmationModal} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                            alt="GBM Logo"
                            className='modal-img'
                        />
                    </Row>
                    <Row className="signup-modal-row">
                        <span className="signup-modal-title">{"Confirma tu correo electrónico"}</span>
                    </Row>
                    <Row className="modal-google-row" >
                        <p style={{ color: "#85ad3f" }}>
                            {"Gracias por elegir GBM COIN para tu próxima inversión. Recuerda que tu colaboración salvará nuestra biósfera, su flora y todas las especies que la habitan."}
                        </p>
                    </Row>
                    <Row className="modal-google-row" >
                        <p >
                            {"Te hemos enviado un mensaje a tu correo electrónico para que actives tu cuenta."}
                        </p>
                    </Row>
                    <Row className="modal-google-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/login/salida-sol-sobre-selva-bali.jpg'}
                            alt="jungle img"
                            className='signup-modal-email-img'
                        />
                    </Row>
                </div>
            </Modal>

            <Modal isOpen={errorModal} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                            alt="GBM Logo"
                            className='modal-img'
                        />
                    </Row>
                    <Row className="signup-modal-row">
                        <span className="signup-modal-error-title">{"Lo sentimos, algo no ha salido como debería."}</span>
                    </Row>
                    <Row className="modal-google-row" >
                        <p style={{ color: "red" }}>
                            {`${errorModalMessage ? errorModalMessage : "Ha ocurrido un error."}`}
                        </p>
                    </Row>
                    <Row className="modal-google-row" >
                        <Col
                            xs={12} sm={12} md={6} lg={6} xl={6}
                            className="signup-modal-col"
                        >
                            <Button
                                color=""
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                className='login-button'
                                onClick={() => cancelRegistration(ROUTE_HOME)}
                            >
                                {'HOME'}
                            </Button>
                        </Col>
                        <Col
                            xs={12} sm={12} md={6} lg={6} xl={6}
                            className="signup-modal-col"
                        >
                            <Button
                                color=""
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                className='signup-button'
                                onClick={() => cancelRegistration(ROUTE_SIGNUP)}
                            >
                                {'REGISTRO'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default SignupSms;