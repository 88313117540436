import React, { useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { Row, Card, Col, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { BsSearch } from 'react-icons/bs';
import { verifyRoles } from '../../functions/roles';


const InternalEarnings = () => {
	
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [sectionDetail, setSectionDetail] = useState(false);	
	const [selectedEarning, setSelectedEarning] = useState("");
	const [earningsList, setEarningsList] = useState([]);
	const [userSearched, setUserSearched] = useState("");	
	const [totalEarnings, setTotalEarnings] = useState(0);	
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);	

	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-internalEarnings">

						<span className="page-title">Ganancias internas</span>

						<Card className="admin-main-card">
							{sectionRegistros ? (
								<div className="registros">

									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Total = {totalEarnings}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={userSearched}
														placeholder='Buscar usuario'
														onChange={(event) => setUserSearched(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="earnings-list">
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>
													<Th>Usuario</Th>
													<Th>Ganancias</Th>
												</Tr>
											</Thead>
											<Tbody>
												{earningsList.length > 0 ?
													earningsList.map((earning, index) => {
														
														if (earning.usuario.toLowerCase().indexOf(userSearched.toLowerCase()) >= 0 || userSearched === ''){														
															return (
																<Tr key={index} className="tr-earning">
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{earning.created_at}
																		</Moment>
																	</Td>
																	<Td>{earning.usuario}</Td>
																	<Td>{earning.earnings}</Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}

							{ /*

							{sectionDetail ? (
								<div className="detail">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">DETALLES DE LA GANANCIA</h1>

										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}

							*/}
							
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default InternalEarnings;
