import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getVaultsMovements } from '../../../store/action-creators/ProfilesActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../../functions/roles';


const AsociadosMovements = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [sectionVaults, setSectionVaults] = useState(true);
	const [sectionDetail, setSectionDetail] = useState(false);		
	const [txList, setTxList] = useState([]);
	const [txSearched, setTxSearched] = useState("");	
	const [totalTx, setTotalTx] = useState(0);	
	const [vaults, setVaults] = useState([]);
	const [gbmBalance, setGbmBalance] = useState("");
	const [cargando, setCargando] = useState(true);
	const [rolesHabilitado, setRolesHabilitado] = useState([4,14]);
		

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getVaultsMovements(data))
			.then((res) => {		
				setCargando(false);
				setVaults(res.payload.vaults.vaults);
			
				let i;
				for(i=0;i<res.payload.vaults.vaults.length;i++){
					if(res.payload.vaults.vaults[i].symbol == "GBM"){
						setGbmBalance(res.payload.vaults.vaults[i].balance);
					}
				}

				setTxList(res.payload.txList);
				setTotalTx(res.payload.totalTx);
				
			})
			.catch((error) => {
				setCargando(false);
				console.log(error);
			});
		
	}, []);


	function SwitchTipo(props) {
		switch (props.tipo) {
			case 1:
				return 'Depósito';
			case 2:
				return 'Retiro';			
		}
	}


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-profiles-all-movements">

						<span className="page-title">Movimientos</span>

						<Card className="main-card">

							{

								sectionVaults ?
								<div className="sectionVaults">
									{
										cargando ?
										<p className="cargando">Cargando...</p>
										: null
									}
									{vaults.length > 0 ?
										<div className="text-center">
											<img src='../../images/cryptos/gbm.png' />
											<p>{gbmBalance} GBM</p>
										</div>
										:null
									}

									<div className="text-center">
										<Row>

											{vaults.length > 0 ?
												vaults.map((vault, index) => {
																			
													if(vault.symbol != "GBM"){											
														return (
															<Col sm={3}>
																<img src={'../../images/cryptos/'+(vault.symbol).toLowerCase()+'.png'} />
																<p>{vault.balance} {vault.symbol}</p>
															</Col>
														);
													}
														
												  })
												: null
											}

										</Row>
									</div>

								</div>
								:null

							}

							<hr />

							{sectionRegistros ? (
								<div className="registros">

									<div className="header">
										<Row>
											<Col sm={5}>
												<p className="title">Movimientos = {totalTx}</p>
											</Col>
											<Col sm={7}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={txSearched}
														placeholder='Buscar Hash'
														onChange={(event) => setTxSearched(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="roles-list">
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>											
													<Th>Operación</Th>
													<Th>Cantidad</Th>
													<Th>Detalle</Th>											
												</Tr>
											</Thead>
											<Tbody>
												{txList.length > 0 ?
													txList.map((tx, index) => {
														
														if (tx.tx_hash == txSearched || txSearched === ''){														
															return (
																<Tr key={index}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{tx.fecha}
																		</Moment>
																	</Td>
																	<Td><SwitchTipo tipo={tx.tipo} /></Td>
																	<Td>{tx.value} {tx.symbol}</Td>
																	<Td><a href={tx.tx_url} target="_blank">detalle</a></Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}

						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default AsociadosMovements;
