import React, { useState, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Deposit from '../../atoms/deposit';
import styles from './corporateVault.module.scss';

import VaultContext from '../../context/vaultContext';
import { DefineVaults } from '../../../../services/defineVaults.js';

const CorporateVault = () => {
	
	const context = useContext(VaultContext);

	const vaults = DefineVaults();
			
	const onDeposit = (selectedVault) => {				
	    context.setData({ ...context.data,selectedVault:selectedVault });
	}
	
	return(

		<div className={styles.section}>				
			
			<h1 className={styles.title}>Bóveda depósitos corporativos</h1>												
			{ 
				vaults.length > 0 ?

				<div>
					<Row>
						{vaults.map((vault, index) => {
								
							if(vault.tipo === "corporate_vault") {
								return (
									<Col sm={6} key={index}>
										<div className={styles.vault}>								            								            
								            <span className={styles.logoFrame}><img src={'../images/cryptos/'+(vault.symbol).toLowerCase()+'.png'} alt="" className={styles.logo} /></span>
								            <div className={styles.content}>
								              <span>{vault.name}</span> <Link to={`/vaults/${context.data.company}/deposit`} onClick={() => onDeposit(vault)}>depositar </Link>
								              <span className={styles.number}>{vault.balance} {vault.symbol}</span>
								              <span>${vault.usd}</span>
								            </div>
								        </div>
									</Col>															
								);
							}																									
						})}	
					</Row>
				</div>
				:null
	
			}
									
		</div>	

	)

}

export default CorporateVault;