import React, { useState, useContext } from 'react';
import { Table } from 'reactstrap';
import ReportsContext from '../../context/reportsContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// styles
import styles from './reportSaleDetail.module.scss';

const ReportSaleDetail = () => {

	const context = useContext(ReportsContext);

	return(

		<div>

			<ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="report_sale"
                sheet="report_sale"
                buttonText="Descargar a Excel"
            />


			<Table responsive className={styles.table} id="table-to-xls">
			  <thead>
			    <tr>
			      <th>Registro</th>
			      <th>Usuario</th>
			      <th>Email</th>
			      <th>Nombre</th>
			      <th>Apellido</th>
			      <th>País</th>
			      <th>Fecha</th>
			      <th>Compró (GBM)</th>
			      <th>Precio</th>
			      <th>Medio</th>
			    </tr>
			  </thead>
			  <tbody>
			   	{context.data.reportSale.length > 0 ?
					context.data.reportSale.map((report, index) => {
						return(

							<tr key={index}>
								<td>{report.habilitado}</td>
								<td>{report.usuario}</td>
								<td>{report.email}</td>
								<td>{report.nombre}</td>
								<td>{report.apellido}</td>
								<td>{report.pais}</td>
								<td>{report.fecha}</td>
								<td>{report.cantidad}</td>
								<td>{report.precio}</td>
								<td>{report.medio}</td>
							</tr>

						);
					})
					:null	
				}
			  </tbody>
			</Table>

		</div>

	);

}

export default ReportSaleDetail;