const getOptions = () =>{

	const options = [
	  { value: '1', label: 'Iniciar' },
	  { value: '2', label: 'Aprobar' },
	  { value: '3', label: 'Modificar' },
	  { value: '4', label: 'Eliminar' }
	];	

	return options;

}

const nameStatus = (status) =>{

	let name;

	switch(status){
		case 0:
			name = "No iniciado";
		break;
		case 1:
			name = "En progreso";
		break;
		case 2:
			name = "Aprobado";
		break;
		case 3:
			name = "Eliminado";
		break;
		case 4:
			name = "Modificado";
		break;
	}

	return name;

}

export { getOptions, nameStatus };