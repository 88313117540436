import React, { useState, useEffect } from 'react';
import styles from './depositsLinksTable.module.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Popover, PopoverHeader } from 'reactstrap';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { getDepositsLinks } from '../../../../store/action-creators/AdminActions';
import { copyToClipboard } from '../../../../services/copyToClipboard';
import { FiCopy } from 'react-icons/fi';
import Copy from './depositLinkCopy';

const DepositsLinksTable = () => {	
 
	const savedToken = sessionStorage.getItem('token');	
	const dispatch = useDispatch();
	const [ links, setLinks ] = useState([]);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const onPopover = () => {
		setPopoverOpen(true);
		setTimeout(function () {
			setPopoverOpen(false);
		}, 1000);
	};

	useEffect(() => {

		const data = Object.assign({}, { token: savedToken });
		dispatch(getDepositsLinks(data))
			.then((res) => {				
				setLinks(res.payload.links);
			})
			.catch((error) => {
				
			});

	},[]);
	
	
	return(
		 
		<Table className="table">
			<Thead>
				<Tr>
					<Th>Fecha</Th>
					<Th>Link</Th>
					<Th>Monto</Th>
					<Th>Enviado a</Th>
					<Th>Creador</Th>					
				</Tr>
			</Thead>
			<Tbody>
				{
					links.length > 0 ?
						links.map((link, index) => {

							return (

								<Tr key={index}>									
									<Td><Moment format="YYYY-MM-DD" utc>{link.created_at}</Moment></Td>									
									<Td>
										<a href={link.link} target="_blank">{link.link}</a>
										<Copy link={link.link} />
									</Td>
									<Td>{link.value} USD</Td>
									<Td>{link.destination}</Td>
									<Td>{link.owner}</Td>
								</Tr>

							)

						})
					:null
				}
			</Tbody>
		</Table>

	)

};

export default DepositsLinksTable;