import React from 'react';
import SubTitle from './subTitle.jsx';

const Index = (props) => {
		
	return(

		<SubTitle text={props.text} />

	)

}

export default Index;