import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,  
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getAdmins, suspendAdmin, getRoles, editRoles } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select'
import { animateScroll as scroll} from 'react-scroll';
import { verifyRoles } from '../../functions/roles';


const Administradores = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');	
	const [admins, setAdmins] = useState([]);
	const [historySymbol, setHistorySymbol] = useState('');
	const [pendingSymbol, setPendingSymbol] = useState('');
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [sectionDetail, setSectionDetail] = useState(false);	
	const [sectionEditRoles, setSectionEditRoles] = useState(false);
	const [showSelectRole, setShowSelectRole] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState('');	
	const [rolesListPerAdmin, setRolesListPerAdmin] = useState([]);
	const [rolesListEditPerAdmin, setRolesListEditPerAdmin] = useState([]);
	const [rolesOptions, setRolesOptions] = useState([]);
	const [options, setOptions] = useState([]);	
	const [usuario, setUsuario] = useState("");
	const [selectedRole, setSelectedRole] = useState("");
	const [modal, setModal] = useState(false);
	const [totals, setTotals] = useState("");
	const [refresh, setRefresh] = useState('');
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	


	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getAdmins(data))
			.then((res) => {				
				setAdmins(res.payload.admins);				
				setTotals(res.payload.totals);				
			})
			.catch((error) => {
				console.log(error);
			});

		dispatch(getRoles(data))
			.then((res) => {	

				let vRolesOptions = new Array();
		        let i,data;
		        for(i=0;i<res.payload.roles.length;i++){
		            
		            data = JSON.stringify({ 
		                value: "",
		                label: ""                      
		            });
		            data = JSON.parse(data);
		            data.value = res.payload.roles[i].id_admin_role;
		            data.label = res.payload.roles[i].role;
		            vRolesOptions.push(data);
		        }

		        setRolesOptions(vRolesOptions);
				
			})
			.catch((error) => {
				console.log(error);
			});
		
	}, []);

	const onSelectRole = selectedRole => {	   	

	    setSelectedRole(selectedRole);

	    let vNewElement = new Array(); 
	    let vList = new Array(); 

	    let data = JSON.stringify({ 
            id_admin_role: "",
            role: ""                      
        });
        data = JSON.parse(data);
        data.id_admin_role = selectedRole.value;
        data.role = selectedRole.label;    
  	
        vNewElement.push(data);

        vList = [...rolesListEditPerAdmin,...vNewElement];

	
		setRolesListEditPerAdmin(vList);
		setShowSelectRole(false);
			
	};

	function SwitchStatus(props) {
		
		switch (props.status) {
			case -1:
				return 'Inhabilitado';
			case 0:
				return 'Suspendido';
			case 1:
				return 'Activo';
		}
	}

	const onDetail = (admin) => {
		setSectionRegistros(false);
		setSectionDetail(true);		
		setSectionEditRoles(false);
		setSelectedAdmin(admin);
		setRolesListPerAdmin(admin.roles);
		setRolesListEditPerAdmin(admin.roles);
		
	};	

	const onBackInit = () => {
		setSectionRegistros(true);
		setSectionDetail(false);
	};

	const onSetUsuario = (usuario) => {
		setUsuario(usuario);
	}

	const onSuspendAdmin = () => {		
		setModal(true);
	}


	const onSuspendAdminConfirm = (id_user) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: id_user });
		dispatch(suspendAdmin(data))
			.then((res) => {				
				let data = Object.assign({}, { token: savedToken });
				dispatch(getAdmins(data))
				.then((res) => {				
					setAdmins(res.payload.admins);				
					setTotals(res.payload.totals);					
					setSectionDetail(false);
					setSectionRegistros(true);
				})
				.catch((error) => { });

			})
			.catch((error) => { });

		setModal(false);


	}

	const onCancelModal = () => {		
		setModal(false);
	}

	const onShowEditRoles = () => {			
		scroll.scrollTo(160,{duration: 100});
		setRolesListEditPerAdmin(rolesListPerAdmin);
		setShowSelectRole(false);
		setSectionDetail(false);
		setSectionEditRoles(true);
	}

	const onDeleteRole = (rol) => {
		
		let i;	
		let vTemp = new Array();

		for(i=0;i<rolesListEditPerAdmin.length;i++){
			if(rolesListEditPerAdmin[i].id_admin_role != rol.id_admin_role){
				vTemp.push(rolesListEditPerAdmin[i]);
			}
		}
			
		setRolesListEditPerAdmin(vTemp);

	}

	const onDeleteSelect = () => {
		setShowSelectRole(false);
	}

	

	const onCancelEditRoles = () => {		
		setSectionEditRoles(false);
		setSectionDetail(true);
	}

	const onGuardarEditRoles = () => {		
		
		let vRevokeRoles = new Array();
		let vNewRoles = new Array();
		
		// Armo el array para revocar roles
		let i,j;
		let encontrado;
		for(i=0;i<rolesListPerAdmin.length;i++){
			encontrado = 0;
			for(j=0;j<rolesListEditPerAdmin.length;j++){
				if(rolesListPerAdmin[i].id_admin_role == rolesListEditPerAdmin[j].id_admin_role){
					
					encontrado = 1;
					break;
				}
			}

			if(!encontrado){
				vRevokeRoles.push(rolesListPerAdmin[i].id_admin_role);
			}

		}

		// Armo el array con los nuevos roles
		for(i=0;i<rolesListEditPerAdmin.length;i++){
			encontrado = 0;
			for(j=0;j<rolesListPerAdmin.length;j++){
				if(rolesListEditPerAdmin[i].id_admin_role == rolesListPerAdmin[j].id_admin_role){
					encontrado = 1;
					break;
				}
			}

			if(!encontrado){
				vNewRoles.push(rolesListEditPerAdmin[i].id_admin_role);
			}

		}

		let data = Object.assign({}, { token: savedToken, id_user: selectedAdmin.id_user, vRevokeRoles: vRevokeRoles, vNewRoles: vNewRoles });
		dispatch(editRoles(data))
		.then((res) => {				
			setRolesListPerAdmin(res.payload.roles);
			setRolesListEditPerAdmin(res.payload.roles);
			setSectionEditRoles(false);
			setSectionDetail(true);			
		})
		.catch((err) => {
			setSectionEditRoles(false);
			setSectionDetail(true);
		});

		
	}

	const onAddRole = () => {
		setSelectedRole("");	
		setShowSelectRole(true);		
	}

	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-administradores">

						<div>
					      <Modal isOpen={modal} className="modalSuspendAdmin">
					        <ModalHeader>Suspender Administrador</ModalHeader>
					        <ModalBody>
					          	<p><b>Usuario: </b>{selectedAdmin.usuario}</p>
					          	<p><b>Nombre: </b>{selectedAdmin.nombre}</p>
					        </ModalBody>
					        <ModalFooter>
					          <Button color="primary" className="btn-confirm" onClick={() => onSuspendAdminConfirm(selectedAdmin.id_user)}>Si, quiero suspender al administrador</Button>
					          <Button color="secondary" className="btn-cancel" onClick={onCancelModal}>Cancelar</Button>
					        </ModalFooter>
					      </Modal>
					    </div>

						<span className="page-title">Administradores</span>

						<Card className="admin-main-card">
							{sectionRegistros ? (
								<div className="registros">

									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Administradores = {totals.total} &nbsp;&nbsp; Habilitados = {totals.enabled} &nbsp;&nbsp; Inhabilitados = {totals.disabled} &nbsp;&nbsp; Suspendidos = {totals.suspended}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={usuario}
														placeholder='Buscar usuario'
														onChange={(event) => onSetUsuario(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="administradores-list">
										<Table className="table">
											<Thead>
												<Tr>
													<Th>Fecha</Th>
													<Th>Usuario</Th>
													<Th>Nombre</Th>
													<Th>Apellido</Th>
													<Th>Estado</Th>											
												</Tr>
											</Thead>
											<Tbody>
												{admins.length > 0 ?
													admins.map((admin, index) => {
														
														if ( admin.usuario.toLowerCase().indexOf(usuario.toLowerCase()) >= 0 || usuario === ''){														
															return (
																<Tr key={index} className="tr-admin" onClick={() => onDetail(admin)}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{admin.created_at}
																		</Moment>
																	</Td>
																	
																	<Td>{admin.usuario}</Td>
																	<Td>{admin.nombre}</Td>
																	<Td>{admin.apellido}</Td>
																	<Td><SwitchStatus status={admin.status} /></Td>														
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}

							{sectionDetail ? (
								<div className="detail">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">DETALLES DEL ADMINISTRADOR</h1>

											<Row className="datos">
												<Col sm={1}></Col>
												<Col sm={5} xs={6} className="campo">
													<p><b>Fecha de creación</b></p>											
													<p><b>Usuario</b></p>
													<p><b>Nombre</b></p>
													<p><b>Apellido</b></p>
													<p><b>Email</b></p>
													<p><b>Teléfono</b></p>
													<p><b>Estado</b></p>
													{	
														selectedAdmin.status == 1 ?
															<p><b>Acción</b></p>
														:null
													}	
												</Col>
												<Col sm={5} xs={6} className="valor">
													<p>
														<Moment format="YYYY-MM-DD HH:mm:ss" utc>
															{selectedAdmin.created_at}
														</Moment>
													</p>
													<p>{selectedAdmin.usuario}</p>
													<p>{selectedAdmin.nombre}</p>
													<p>{selectedAdmin.apellido}</p>
													<p>{selectedAdmin.email}</p>
													<p>{selectedAdmin.telefono ? selectedAdmin.telefono : "No tiene" }</p>											
													<p><SwitchStatus status={selectedAdmin.status} /></p>
													{	
														selectedAdmin.status == 1 ?
															<p><a href="#!" onClick={() => onSuspendAdmin()}>suspender administrador</a></p>
														:null
													}
														
												</Col>
												<Col sm={1}></Col>
											</Row>
											
											<div className="adminRoles">
												<p className="second-title"><b>Roles</b></p>

												<Table className="table">
													<Thead>
														<Tr>
															<Th>Fecha Asig.</Th>
															<Th>Rol</Th>
															<Th>Owner</Th>																					
														</Tr>
													</Thead>
													<Tbody>
														{rolesListPerAdmin.length > 0 ?
															rolesListPerAdmin.map((rol, index) => {
																				
																return (
																	<Tr key={index}>
																		<Td>
																			<Moment format="YYYY-MM-DD HH:mm" utc>
																				{rol.created_at}
																			</Moment>
																		</Td>
																		
																		<Td>{rol.role}</Td>
																		<Td>{rol.owner}</Td>																											
																	</Tr>
																);
																	
															})
															: null
														}
													</Tbody>
												</Table>
												<Button className="btn-edit-roles" onClick={onShowEditRoles}>Editar Role</Button>
											</div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}


							{

								sectionEditRoles ? 

								<div className="editRoles">

									<p className="second-title"><b>Administrador: </b>{selectedAdmin.usuario}</p>

									<Table className="table">
										<Thead>
											<Tr>														
												<Th>Rol</Th>														
												<Th></Th>
											</Tr>
										</Thead>
										<Tbody>
											{rolesListEditPerAdmin.length > 0 ?
												rolesListEditPerAdmin.map((rol, index) => {
																	
													return (
														<Tr key={index}>																
															<Td>{rol.role}</Td>																	
															<Td><a href="#!" onClick={()=>onDeleteRole(rol)}>eliminar</a></Td>																											
														</Tr>
													);
														
												})
												: null
											}
										</Tbody>
									</Table>
									
									{
										showSelectRole ?	
											<Row>
												<Col sm={4}>
													<Select
									                    options={rolesOptions}
									                    placeholder="Seleccionar rol"
									                    className="selectRole"
									                    value={selectedRole}
									                    onChange={onSelectRole}
									                />
									            </Col>
									            <Col sm={8}>
									            	<p><a href="#!" onClick={onDeleteSelect}>eliminar</a></p>
									            </Col>
									        </Row>

							            :
							            	<p className="lnk-add-role"><a href="#!" onClick={onAddRole}>Agregar rol</a></p>
						            }
									
									
									<Button className="btn-guardar-edit-roles" onClick={onGuardarEditRoles}>Guardar cambios</Button>
									<Button className="btn-cancelar-edit-roles" onClick={onCancelEditRoles}>Cancelar</Button>

								</div>
								:null

							}
							
						</Card>
					</div>

				: null
			}
		</MainPrivateLayout>
	);
};

export default Administradores;
