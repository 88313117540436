// Copiar texto con un click

const copyToClipboard = (address) => {
	const ta = document.createElement('textarea');
	ta.innerText = address;
	document.body.appendChild(ta);
	ta.select();
	document.execCommand('copy');
	ta.remove();	
};

export { copyToClipboard };
