import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTE_WALLET_BUY } from '../../constants/routes';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { BsSearch } from 'react-icons/bs';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { getMovimientos } from '../../store/action-creators/UserActions';

const Movimientos = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	let history = useHistory();
	const handleDataChange = ({ dataSize }) => {
		// this.setState({ rowCount: dataSize });
	};
	const savedToken = sessionStorage.getItem('token');
	// const [movimientos, setMovimientos] = useState(useSelector((store) => store.user.movimientos));
	const [movimientos, setMovimientos] = useState([
		{ created_at_movimiento: '2020-12-09 18:29:11', id: 1, usuario: 'judecima', tipo: 'Deposito', cantidad: '100' },
	]);

	const [historySymbol, setHistorySymbol] = useState('');
	const [pendingSymbol, setPendingSymbol] = useState('');
	const [registros, setRegistros] = useState(true);
	const [detail, setDetail] = useState(false);
	const [selectedMovement, setSelectedMovement] = useState('');
	const columns = [
		{
			dataField: 'created_at_movimiento',
			text: 'Fecha',
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					history.push({
						pathname: '/movementDetails',
						state: row,
					});
				},
			},
		},
		{
			dataField: 'id',
			text: 'Id',
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					history.push({
						pathname: '/movementDetails',
						state: row,
					});
				},
			},
		},
		{
			dataField: 'usuario',
			text: 'Usuario',
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					history.push({
						pathname: '/movementDetails',
						state: row,
					});
				},
			},
		},
		{
			dataField: 'tipo',
			text: 'Tipo',
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					history.push({
						pathname: '/movementDetails',
						state: row,
					});
				},
			},
		},
		,
		{
			dataField: 'cantidad',
			text: 'Cantidad',
			events: {
				onClick: (e, column, columnIndex, row, rowIndex) => {
					history.push({
						pathname: '/movementDetails',
						state: row,
					});
				},
			},
		},
	];

	const administradores = [];
	// Terminar el proceso de búsqueda del precio antes de dejar el componente
	var timer;
	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	}, []);

	// useEffect(() => {
	// 	timer = setTimeout(() => {
	// 		let data = Object.assign({}, { token: savedToken });
	// 		dispatch(getMovimientos(data))
	// 			.then((res) => {
	// 				// setMovimientos(res.payload);
	// 				console.log(res.payload);
	// 			})
	// 			.catch((error) => {});
	// 	}, 300000);
	// }, []);

	function SwitchTipo(props) {
		switch (props.tipo) {
			case 1:
				return 'Depósito';
			case 2:
				return 'Retiro';
			case 3:
				return 'Depósito por intercambio';
			case 4:
				return 'Retiro por intercambio';
		}
	}

	function SwitchEstado(props) {
		if (props.confirmations <= 3) {
			return 'Pendiente';
		} else {
			return 'Completo';
		}
	}

	const onDetail = (movimiento) => {
		setRegistros(false);
		setDetail(true);
		setSelectedMovement(movimiento);
	};

	const onBack = () => {
		setRegistros(true);
		setDetail(false);
	};

	return (
		<MainPrivateLayout>
			<div className="page-content-container section-movimientos">
				<span className="page-title">Movimientos</span>

				<Card className="main-card">
					{registros ? (
						<div className="registros">
							<div className="content-table-list">
								<Row>
									<Col sm={8}>
										<p className="title">
											<b>Total</b> = xx <b>Compra</b>=xx <b>Retiro</b>=xx <b>Deposito</b>=xx{' '}
										</p>
									</Col>
									<Col sm={4}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" className="prepend">
												<InputGroupText>
													<BsSearch />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Buscar movimiento"
												onChange={(event) => setHistorySymbol(event.target.value)}
												autoComplete="new-password"
											/>
										</InputGroup>
									</Col>
								</Row>
								<hr />
								<Col xs={12} md={12} xl={12} className="admin-check-group">
									<FormGroup check>
										<Label check>
											<Input type="checkbox" /> Compra
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" /> Venta
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" /> Retiro
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" /> Deposito
										</Label>
									</FormGroup>
								</Col>
								<BootstrapTable
									onDataSizeChange={handleDataChange}
									keyField="id"
									data={movimientos}
									columns={columns}
									filter={filterFactory()}
									pagination={paginationFactory()}
									className="table-admin"
								/>

								{/* <Table className="table">
									<Thead>
										<Tr>
											<Th>Fecha</Th>
											<Th>ID</Th>
											<Th>Tipo</Th>
											<Th>Usuario</Th>
										</Tr>
									</Thead>
									<Tbody>
										{movimientos.length > 0
											? movimientos.map((movimiento, index) => {
													if (
														movimiento.confirmations >= 3 &&
														(movimiento.symbol === historySymbol.toUpperCase() ||
															historySymbol === '')
													) {
														return (
															<Tr key={index}>
																<Td>
																	<Moment format="YYYY-MM-DD HH:mm" utc>
																		{movimiento.fecha}
																	</Moment>
																</Td>
																<Td>																	
																	<SwitchTipo tipo={movimiento.tipo} />
																</Td>
																<Td>{movimiento.name}</Td>
																<Td>
																	{movimiento.value} {movimiento.symbol}
																</Td>
																<Td>
																	<a href="#" onClick={() => onDetail(movimiento)}>
																		detalle
																	</a>
																</Td>
															</Tr>
														);
													}
											  })
											: null}
									</Tbody>
								</Table>
							 */}
							</div>{' '}
							{/* History */}
						</div>
					) : null}

					{detail ? (
						<div className="detail">
							<Row>
								<Col sm={2}></Col>
								<Col sm={8} className="content">
									<h1 className="title">DETALLES DEL MOVIMIENTO</h1>

									<Row className="datos">
										<Col sm={1}></Col>
										<Col sm={5} xs={6} className="campo">
											<p>
												<b>Fecha</b>
											</p>
											<p>
												<b>Operación</b>
											</p>
											<p>
												<b>Nombre</b>
											</p>
											<p>
												<b>Cantidad</b>
											</p>
											<p>
												<b>Confirmaciones</b>
											</p>
											<p>
												<b>Estado</b>
											</p>
										</Col>
										<Col sm={5} xs={6} className="valor">
											<p>
												<Moment format="YYYY-MM-DD HH:mm:ss" utc>
													{selectedMovement.fecha}
												</Moment>
											</p>
											<p>
												<SwitchTipo tipo={selectedMovement.tipo} />
											</p>
											<p>{selectedMovement.name}</p>
											<p>
												{selectedMovement.value} {selectedMovement.symbol}
											</p>
											<p>{selectedMovement.confirmations}</p>
											<p>
												<SwitchEstado confirmations={selectedMovement.confirmations} />
											</p>
										</Col>
										<Col sm={1}></Col>
									</Row>

									<div className="footer">
										<p>
											<b>Transaction ID</b> <br />
											<span>{selectedMovement.tx_hash}</span>
										</p>
										<p>
											<b>Destino</b> <br />
											<span>{selectedMovement.dest_address}</span>
										</p>
										<p>
											<b>Tx URL</b> <br />
											<span>
												<a href={selectedMovement.tx_url} target="_blank">
													{selectedMovement.tx_url.substring(0, 50)}...
												</a>
											</span>
										</p>
									</div>

									<br />
									<div className="text-center">
										<Button className="btn-back" onClick={onBack}>
											Volver
										</Button>
									</div>
								</Col>
								<Col sm={2}></Col>
							</Row>
						</div>
					) : null}
				</Card>
			</div>
		</MainPrivateLayout>
	);
};

export default Movimientos;
