import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Card, Container, Row, Col, InputGroup, Input, InputGroupText, InputGroupAddon  } from 'reactstrap';
import styles from './newHito.module.scss';
import MainPrivateLayout from '../../../../../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../../../../../functions/roles';
import '../../../../../../../styles/styles.scss';
import Back from '../../../../../../../components/back';
import ButtonConfirm from '../../../../../../../components/buttonConfirm';
import Username from '../../../../../../../components/username';
import { addHito } from '../../../../../../../store/action-creators/AdminActions';

const NewHito = () => {

	const history = useHistory();
	const location = useLocation();	
	const dispatch = useDispatch();
	const donated = location.state;
	const savedToken = sessionStorage.getItem('token');	

	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);
	
	const [ description, setDescription ] = useState("");
	const [ result, setResult ] = useState("");				
		
	const handleBack = () => {
		history.push("/donations/hitos", donated);
	}

	const handleAsign = () => {

		const data = Object.assign({}, { token: savedToken, id_user: donated.id_user, usuario: donated.usuario, description: description });
		dispatch(addHito(data))
			.then((res) => {				
				setResult("Se asígno un nuevo hito");
			})
			.catch((error) => {
				
			});

	}
		
	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back path="/donations/hitos" handleClick={handleBack} />
							<Container>
						      <Row>
						        <Col sm={3}></Col>
						        <Col sm={6} className={styles.frame}>
						        	<p className={styles.title}>nuevo hito</p>	
						        	<Username text={donated.usuario} />					        	
						        	<span className={styles.boxTitle}>descripción</span>						        									       
								    <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />								    
								    <br />
								    <p className={styles.result}>{result}</p>
								    <div class={styles.devButton}>
								    	<ButtonConfirm text="asignar" handleClick={handleAsign} />
								    </div>								    
						        </Col>
						        <Col sm={3}></Col>
						      </Row>
						    </Container>
						</Card>						
					</div>
				:null

			}	
		</MainPrivateLayout>

	)

};

export default NewHito;