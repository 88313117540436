import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getCoupon, assignCouponCodeUser, generateCouponCode, getAdmins } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import Select from 'react-select'
import moment from 'moment';
import { verifyRoles } from '../../functions/roles';


const CodigosDeDescuento = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);	
	const [sectionGenerateCode, setSectionGenerateCode] = useState(false);
	const [total, setTotal] = useState(0);
	const [code, setCode] = useState("");
	const [generatedCode, setGeneratedCode] = useState("");
	const [couponList, setCouponList] = useState("");
    const [admins, setAdmins] = useState([]);
    const [options, setOptions] = useState([]);
    const [gbmusd, setGbmusd] = useState("");
    const [selectedAdmin, setSelectedAdmin] = useState("");
    const [errorAssign, setErrorAssign] = useState("");
    const [responseAssign, setResponseAssign] = useState("");
    const [refresh, setRefresh] = useState('');
    const [fechaCaducidad, setFechaCaducidad] = useState('');
    const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });

		dispatch(getCoupon(data))
			.then((res) => {				
				setCouponList(res.payload.coupons);
				setTotal(res.payload.total);
			})
			.catch((error) => {
				console.log(error);
			});

      dispatch(getAdmins(data))
      .then((res) => {        
        
          let vOptions = new Array();
          let i,data;
          for(i=0;i<res.payload.admins.length;i++){
            
            data = JSON.stringify({ 
                value: "",
                label: ""                      
            });
            data = JSON.parse(data);
            data.value = res.payload.admins[i].id_user;
            data.label = res.payload.admins[i].usuario;
            vOptions.push(data);
          }

          setOptions(vOptions);

      })
      .catch((error) => { });
		
	}, []);


	const onShowGenerateCode = () => {	
		setSectionRegistros(false);
		setSectionGenerateCode(true);
	}

	const onBackInit = () => {
		setSectionGenerateCode(false);
		setSectionRegistros(true);		
	};

	const onGenerateCode = () => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(generateCouponCode(data))
		.then((res) => {				
			setGeneratedCode(res.payload.code);
		})
		.catch((error) => { });

	};


  const onSelectAdmin = selectedAdmin => {
    setSelectedAdmin(selectedAdmin);   
  };


  const onAssignAdmin = () => {

    let rexPrice = /^(0|[1-9]\d*)(\.\d+)?$/;

    if(!generatedCode){
      setErrorAssign("Generar código");
      return;
    }

    if(!selectedAdmin){
      setErrorAssign("Seleccionar un administrador");
      return;
    }

    if(!gbmusd){
      setErrorAssign("Ingresar precio");
      return;
    }

    if(!rexPrice.test(gbmusd)){
      setErrorAssign("Ingresar solo números para el precio");
      return;
    }

    if(!fechaCaducidad){
      setErrorAssign("Ingresar fecha de caducidad");
      return;
    }

    setErrorAssign("");
    setResponseAssign("");

    let data = Object.assign({}, { token: savedToken,id_user:selectedAdmin.value,code:generatedCode,gbmusd:gbmusd,fechaCaducidad:fechaCaducidad });
    dispatch(assignCouponCodeUser(data))
    .then((res) => {        
      
       let objeto = JSON.stringify({ 
            created_at: "",
            code: "",
            gbmusd: "",
            usuario: "",
            nombre: "",
            fechaCaducidad: "",
            owner: ""          
        });
        objeto = JSON.parse(objeto);

        objeto.created_at = res.payload.created_at;
        objeto.code = res.payload.code;
        objeto.gbmusd = res.payload.gbmusd;
        objeto.usuario = res.payload.usuario;
        objeto.nombre = res.payload.nombre;
        objeto.fecha_caducidad = res.payload.fecha_caducidad;
        objeto.owner = res.payload.owner;             
        couponList.push(objeto);
          
        // Ordena los códigos por fecha. Mayor a menor
        couponList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); 

        setCouponList(couponList);
        
        setResponseAssign([moment().format('YYYY-MM-DD hh:mm:ss'),<br />,"Código "+res.payload.code+" generado y asignado a "+res.payload.usuario]);

    })
    .catch((error) => { });

   

  }


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-codigosdedescuento">				

						<span className="page-title">Códigos de descuento</span>

						<Card className="admin-main-card">														

							{sectionRegistros ? (
								<div className="registros">
									
									<Button className="btn-generate" onClick={onShowGenerateCode}>Generar código</Button>
									
									<div className="header">
										
										<Row>
											<Col sm={8}>
												<p className="title">Códigos = {total}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={code}
														placeholder='Buscar código'
														onChange={(event) => setCode(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="roles-list">								
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>
													<Th>Código</Th>
													<Th>Precio (GBM)</Th>
													<Th>Usuario</Th>
													<Th>Nombre</Th>
													<Th>Expira</Th>
													<Th>Owner</Th>
												</Tr>
											</Thead>
											<Tbody>
												{couponList.length > 0 ?
													couponList.map((coupon, index) => {
														
														if (coupon.code.toString().indexOf(code) >= 0 || code === ''){														
															return (
																<Tr key={index}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{coupon.created_at}
																		</Moment>
																	</Td>
																	<Td>{coupon.code}</Td>
																	<Td>u$ {coupon.gbmusd}</Td>
																	<Td>{coupon.usuario}</Td>
																	<Td>{coupon.nombre}</Td>
																	<Td>
																		<Moment format="YYYY-MM-DD" utc>
																			{coupon.fecha_caducidad}
																		</Moment>
																	</Td>
																	<Td>{coupon.owner}</Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}


							{sectionGenerateCode ? (
								<div className="generateCode">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">GENERAR CÓDIGO DE DESCUENTO</h1>

											<InputGroup className="inputGroup-code">
										        <InputGroupAddon addonType="prepend">								          
										          <InputGroupText className="btn-generar" onClick={onGenerateCode}>Generar </InputGroupText>
										        </InputGroupAddon>
										        <Input readOnly={true} value={generatedCode} />
										    </InputGroup>		

						                  <br />
										  <Select
						                    options={options}
						                    placeholder="Seleccionar administrador"
						                    className="selectAdmin"
						                    value={selectedAdmin}
						                    onChange={onSelectAdmin}
						                  />

						                  <InputGroup className="inputGroup-price">
						                    <InputGroupAddon addonType="prepend">                         
						                      <InputGroupText>Precio GBM </InputGroupText>
						                    </InputGroupAddon>
						                    <Input value={gbmusd} onChange={(event) => setGbmusd(event.target.value)} />
						                  </InputGroup>				                  

						                  <InputGroup className="inputGroup-date">
						                    <InputGroupAddon addonType="prepend">                         
						                      <InputGroupText>Fecha de caducidad </InputGroupText>
						                    </InputGroupAddon>
						                    <Input 
						                    	value={fechaCaducidad} 
						                    	onChange={(event) => setFechaCaducidad(event.target.value)} 
						                    	type='date'
						                    />
						                  </InputGroup>

						                  <br />

						                  <p className="error">{errorAssign}</p>
						                  <p className="response">{responseAssign}</p>
						                  <div className="text-center"><Button className="btn-assign" onClick={onAssignAdmin}>Asignar administrador</Button></div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}
					
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default CodigosDeDescuento;
