import React from 'react';
import styles from './subTitle.module.scss';

const SubTitle = (props) => {	
	
	return(
		 
		<p className={styles.title}>{props.text}</p>

	)

};

export default SubTitle;