import React from 'react';
import ReportSaleDetail from './reportSaleDetail.jsx';

const Index = () => {

	return(

		<ReportSaleDetail />

	)

}

export default Index;