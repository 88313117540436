import React, { useState, useContext } from 'react';
import { Popover, PopoverHeader } from 'reactstrap';

const ExPopover = (props) => {
		
	const [popoverOpen, setPopoverOpen] = useState(false);	
	
	const onPopover = () => {
		if(popoverOpen){
			setPopoverOpen(false);
		}else{
			setPopoverOpen(true);
		}
      	
    };
    

	return(

		<div>				
			
			<Popover
	            placement="right"
	            isOpen={popoverOpen}
	            target={"Popover"+props.index}
	            toggle={onPopover}
	         >
	            <PopoverHeader>
	               {props.vault.balance} GBM
	            </PopoverHeader>
	         </Popover>

		</div>	

	)

}

export default ExPopover;