import React from 'react';
import DepositLinkCopy from './depositLinkCopy.jsx';

const Index = (props) => {
		
	return(

		<DepositLinkCopy link={props.link} />

	)

}

export default Index;