import React from 'react';
import Vaults from './vaults.jsx';

const Index = () => {

	return(

		<Vaults />

	)

}

export default Index;