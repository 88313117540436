import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { useHistory } from "react-router-dom";
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../functions/roles';
import styles from './donations.module.scss';
import '../../styles/styles.scss';
import SectionTitle from '../../components/sectionTitle';
import Button1 from '../../components/button1';
import Benecifiaries from './beneficiaries';

const Donations = () => {

	const history = useHistory();

	const rolesHabilitado = [1];
	const user = useSelector((store) => store.user.user);

	const handleNew = () => {
		
		const pathname = window.location.pathname;		
		history.push(pathname+'/newDonation');		

	}

	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">
						<SectionTitle text="donaciones" />
						<Card className="back-content-section">							
							<div className={styles.devButton}>
								<Button1 text="nueva donacion" handleClick={handleNew} />
							</div>
							<Benecifiaries />
						</Card>						
					</div>
				:null


			}	
		</MainPrivateLayout>

	)

};

export default Donations;