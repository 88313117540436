import React, { useContext } from 'react';
import styles from './reportWallet.module.scss';

// Atoms
import ReportHeader from '../../atoms/reportHeader';
import FilterBetweenDates from '../../atoms/filterBetweenDates';
import ReportWalletDetail from '../../atoms/reportWalletDetail';
import ReturnStart from '../../atoms/returnStart';

const ReportAdmin = () => {

	return(
		<div>
			<ReturnStart />
			<ReportHeader />
			<FilterBetweenDates />
			<ReportWalletDetail />
		</div>
	);

}

export default ReportAdmin;