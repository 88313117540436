import React from 'react';
import ReportHeader from './reportHeader.jsx';

const Index = () => {

	return(

		<ReportHeader />

	)

}

export default Index;