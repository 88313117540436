import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col, InputGroup, Input, InputGroupText, InputGroupAddon, Popover, PopoverHeader } from 'reactstrap';
import styles from './newDepositLink.module.scss';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import { useSelector, useDispatch } from 'react-redux';
import { verifyRoles } from '../../../functions/roles';
import '../../../styles/styles.scss';
import Back from '../../../components/back';
import ButtonConfirm from '../../../components/buttonConfirm';
import { generateDepositLink } from '../../../store/action-creators/AdminActions';
import { FiCopy } from 'react-icons/fi';
import { copyToClipboard } from '../../../services/copyToClipboard';

const NewDepositLink = () => {

	const history = useHistory();

	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');
	const dispatch = useDispatch();

	const [ destination, setDestination ] = useState("");			
	const [ obs, setObs ] = useState("");
	const [ monto, setMonto ] = useState("");
	const [ errorDestination, setErrorDestination ] = useState("");
	const [ errorObs, setErrorObs ] = useState("");
	const [ errorMonto, setErrorMonto ] = useState("");
	const [ newLink, setNewLink ] = useState("");
	const [ waiting, setWaiting ] = useState("");
	const [ result, setResult ] = useState(false);	
	const [ errorGeneral, setErrorGeneral ] = useState("");
	const [popoverOpen, setPopoverOpen] = useState(false);

	const onPopover = () => {
		setPopoverOpen(true);
		setTimeout(function () {
			setPopoverOpen(false);
		}, 1000);
	};
	
	const onGenerateLink = () => {

		let error = 0;
		const regex = /^[0-9.]+$/;

		if(!destination){
			error = 1;
			setErrorDestination("Completar el campo");
		}else{
			setErrorDestination("");
		}

		if(!obs){
			error = 1;
			setErrorObs("Ingresar una observación");
		}else{
			setErrorObs("");
		}

		if(!monto){
			error = 1;
			setErrorMonto("Ingresar un monto");
		}else{
			setErrorMonto("");
		}


		if(!regex.test(monto)){
			error = 1;
			setErrorMonto("El monto debe ser un número positivo");
		}
		
		if(error){
			return;
		}		

		setWaiting("Generando link...");
				
		const data = Object.assign({}, { token: savedToken, destination: destination, obs: obs, monto: monto });
		dispatch(generateDepositLink(data))
			.then((res) => {	
				setWaiting("");
				setNewLink(res.payload.link);
				setResult(true);
				setDestination("");
				setObs("");
				setMonto("");
			})
			.catch((error) => { });

	}

	const handleBack = () => {
		history.push("/depositsLinks");
	}

		
	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />
							<Container>
						      <Row>
						        <Col sm={3}></Col>
						        <Col sm={6} className={styles.frame}>
						        	<p className={styles.title}>generar link de depósito</p>						        	
								    <Input value={destination} onChange={(e) => setDestination(e.target.value) } placeholder="Enviado a" />
								    <span className={styles.errorField}>{errorDestination}</span>
						          	<br />
						          	<Input type="textarea" rows={4} value={obs} onChange={(e) => setObs(e.target.value) } placeholder="Observaciones" />
								    <span className={styles.errorField}>{errorObs}</span>
						          	<br />
						          	<InputGroup>
								        <Input value={monto} onChange={(e) => setMonto(e.target.value) } placeholder="Monto" />
								        <InputGroupAddon addonType="append">
								          <InputGroupText>USD</InputGroupText>
								        </InputGroupAddon>
								    </InputGroup>
								    <span className={styles.errorField}>{errorMonto}</span>
								    <br />
								    { result ? 
								    	<div className={styles.result}>	
								    		<p>							    		
								    			<span>{newLink} </span>
								    			<FiCopy
													id="Popover1"
													className={styles.icon}
													onClick={() => copyToClipboard(newLink)}
												/>
												<Popover
													placement="top"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={onPopover}
												>
													<PopoverHeader>
														Copiado!
													</PopoverHeader>
												</Popover>
											</p>
								    	</div>
								    	:null
								    }
								    <p className={styles.waiting}>{waiting}</p>
								    <div className={styles.devButton}>
								    	<ButtonConfirm text="generar link" handleClick={onGenerateLink} />
								    </div>								    
						        </Col>
						        <Col sm={3}></Col>
						      </Row>
						    </Container>
						</Card>						
					</div>
				:null

			}	
		</MainPrivateLayout>

	)

};

export default NewDepositLink;