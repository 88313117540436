import React from "react";
import { Button } from "reactstrap";

const NewsButton = (props) => {
  const {
    label,
    handleClick,
    customStyle,
    className,
    color = "primary",
  } = props;

  const onClickButton = () => {
    if (handleClick && typeof handleClick === "function") {
      handleClick();
    }
  };

  return (
    <Button
      color={color}
      className={className}
      size="sm"
      onClick={() => onClickButton()}
      style={customStyle}
    >
      {label}
    </Button>
  );
};

export default NewsButton;
