import React, { useState, useContext } from 'react';	
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import VaultContext from '../../context/vaultContext';
import styles from './movementsList.module.scss';
import { nameOperation, nameVault, nameReason } from './movementsList.js';

import { DefineMovementList } from '../../../../services/defineMovementList.js';

const MovementsList = (props) => {	

	const context = useContext(VaultContext);

	const movementsList = DefineMovementList();
	
	const onDetail = (movement) => {
		context.setData({ ...context.data,movementDetail:movement });
	};
 
	return(

		<div className={styles.section}>
		
			<Table className="table">
				<Thead>
					<Tr>					 						
						<Th className={styles.th}>Fecha</Th>
						<Th className={styles.th}>Relación</Th>
						<Th className={styles.th}>Bóveda</Th>
						<Th className={styles.th}>Operación</Th>
						<Th className={styles.th}>Cantidad</Th>
						<Th className={styles.th}>Motivo</Th>																									
						<Th className={styles.th}>Detalle</Th>											
					</Tr>
				</Thead>
				<Tbody>
					{movementsList.length > 0 ?
						movementsList.map((movement, index) => {
							
							if ( 
								 movement.tx_hash === props.txSearched || 
								 (movement.operation == props.selectedOperation.value && props.txSearched === '') ||
								 (!props.selectedOperation.value && props.txSearched === '') || 
								 movement.relacion.toString().toLowerCase().indexOf(props.txSearched) >= 0
								){
								
								return (
									<Tr key={index} className={styles.tr}>
										<Td>
											<Moment format="YYYY-MM-DD HH:mm:ss" utc>
												{movement.updated_at}
											</Moment>
										</Td>
										<Td>{movement.relacion}</Td>
										<Td>{nameVault(movement.vault)}</Td>
										<Td>{nameOperation(movement.operation)}</Td>																	
						 				<Td>{movement.value} {movement.symbol}</Td>						
										<Td>{nameReason(movement.tipo)}</Td>											
										<Td><Link to={`/vaults/${context.data.company}/movementDetail`} onClick={() => onDetail(movement)}> Detalle</Link></Td>												
									</Tr>
								);
							}
								
						  })
						: null
					}
				</Tbody>
			</Table>
			
		</div>

	)

}

export default MovementsList;