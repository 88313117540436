import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Input, FormFeedback, Button, Row, Col, FormGroup, Label } from 'reactstrap';
import { flags1 } from '../../functions/Flags';
export class SignUpEmailForm extends Component {
	componentDidMount() {
		if (this.txt) {
			this.txt.focus();
		}
	}

	renderMyField = ({ input, meta, type, name, withFocus, placeholder, error }) => {
		return (
			<div className="signup-input-container">
				<Input
					{...input}
					id={name}
					placeholder={placeholder}
					type={type ? type : 'text'}
					innerRef={withFocus && ((txt) => (this.txt = txt))}
					invalid={meta.touched && !!meta.error}
					className="signup-input"
					autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
				/>
				{meta.touched && meta.error ? (
					<FormFeedback className="signup-formfeedback">{meta.error}</FormFeedback>
				) : null}
			</div>
		);
	};

	renderSelectOptions = (pais, index) => (
		<option
			key={index}
			value={pais.label}
			//style={{ backgroundImage: `url(https://www.countryflags.io/${pais.imgCode}/shiny/64.png)` }}
		>
			{pais.label}
		</option>
	);

	renderMySelect = ({ input, meta, type, name, withFocus, placeholder, error }) => {
		let paises = flags1();

		return (
			<div className="signup-input-container">
				<Input
					{...input}
					id={name}
					placeholder={placeholder}
					type="select"
					innerRef={withFocus && ((txt) => (this.txt = txt))}
					invalid={meta.touched && !!meta.error}
					className="signup-input"
					autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
				>
					{paises.map((pais, index) => this.renderSelectOptions(pais, index))}
				</Input>
				{meta.touched && meta.error ? (
					<FormFeedback className="signup-formfeedback">{meta.error}</FormFeedback>
				) : null}
			</div>
		);
	};

	render() {
		const { handleSubmit, submitting, gralError } = this.props;

		return (
			<div className="signup-form">
				<Form encType="multipart/form-data">
					<Row>
						<Col sm={6}>
							<Field
								name="nombre"
								placeholder="&#xf2b9;   Nombre"
								component={this.renderMyField}
								withFocus={true}
							/>
						</Col>
						<Col sm={6}>
							<Field
								name="apellido"
								placeholder="&#xf2b9;   Apellido"
								component={this.renderMyField}
								withFocus={true}
							/>
						</Col>
						<Col sm={6}>
							<Field
								name="pais"
								placeholder="&#xf024;   Country"
								component={this.renderMySelect}
								withFocus={true}
							/>
						</Col>
						<Col sm={6}>
							<Field
								name="usuario"
								placeholder="&#xf007;   Usuario"
								component={this.renderMyField}
								withFocus={true}
							/>
						</Col>
						<Col sm={6}>
							<Field
								type="email"
								name="email"
								placeholder="&#xf0e0;   Email"
								component={this.renderMyField}
							/>
						</Col>
						<Col sm={6}>
							<Field
								type="password"
								name="password"
								placeholder="&#xf023;   Contraseña"
								component={this.renderMyField}
							/>
						</Col>
					</Row>
				</Form>
				<Row className="basic-row">
					<pre className="signup-error-msg">{gralError}</pre>
				</Row>

				<Row className="basic-row documents">
					<FormGroup check>
						<Field type="checkbox" component={this.renderMyField} name="documents" />
						<Label>
							He leído y acepto los <a href="#">terminos y condiciones</a>, las{' '}
							<a href="#">políticas de privacidad</a> y el <a href="#">libro blanco</a>
						</Label>
						{/* <Field type="checkbox" component={this.renderMyField} name="photoMap" />
                      <Label>                        
                        Acepto que se publique mi foto o logotipo en un <a href="#">mapa publicitario ecológico</a> (opcional)
                      </Label> */}
					</FormGroup>
				</Row>

				<Row className="basic-row">
					<Button
						color=""
						style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
						className="signup-button"
						onClick={handleSubmit}
						type="submit"
						disabled={submitting}
					>
						{'CONTINUAR'}
					</Button>
				</Row>
			</div>
		);
	}
}

// El nombre del formulario que se le pasa al HOC reduxForm debe ser unico en toda la app.
export default reduxForm({ form: 'SignUpEmailForm' })(SignUpEmailForm);
