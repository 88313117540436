import React, { useState } from 'react';
import styles from './tableButton.module.scss';
import { Button } from 'reactstrap';

const TableButton = (props) => {	
					
	return(
		 		
		<Button className={styles.button} onClick={props.handleClick}>{props.text}</Button>		

	)

};

export default TableButton;