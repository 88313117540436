export const apiPost = (url, obj) => fetch(
    `${url}`,
    {
        method: 'POST',   
        body: JSON.stringify(obj),
        headers: new Headers({
            'Content-type': 'application/json',
            'Accept': 'application/json'
        })
    })
    .then(async (response) => {
        if (response.ok) {
            return response.json()
        } else {
            let error = await response.json();
            return Promise.reject(error)
        }

    })
    .then(responseJson => {

        if (responseJson.status === "error") {
            return Promise.reject(responseJson)
        }

        return responseJson;
    })
    .catch(error => {
        return Promise.reject(error)
    });

export const apiPostFormData = (url, formData) => fetch(
    url,
    {
        mode: 'no-cors',
        method: "POST",
        headers: new Headers({
            'Accept': 'application/json'
        }),
        body: formData
    })
    .then(response => response.json())
    .then(responseJson => {
        if (responseJson.error) {
            return Promise.reject(responseJson)
        }

        return responseJson;
    })
    .catch(error => {
        console.log(error);
    });



export const apiPut = (url, id, obj) => fetch(
    `${url}/${id}`,
    {
        method: 'PUT',
        body: JSON.stringify(obj),
        headers: new Headers({
            'Content-type': 'application/json',
            'Accept': 'application/json'
        })
    })
    .then(response => response.json())
    .then(responseJson => {
        if (responseJson.error) {
            return Promise.reject(responseJson)
        }
        return responseJson;
    });



export const apiPutFormData = (url, id, formData) => fetch(
    `${url}/${id}`,
    {
        method: "PUT",
        body: formData,
        headers: new Headers({
            'Accept': 'application/json'
        })
    })
    .then(response => response.json())
    .then(responseJson => {
        if (responseJson.error) {
            return Promise.reject(responseJson)
        }
        return responseJson;
    });



export const apiDelete = (url, id) => fetch(
    `${url}/${id}`,
    {
        method: 'DELETE',
        headers: new Headers({
            'Content-type': 'application/json',
            'Accept': 'application/json'
        })
    })
    .then(response => response.json())
    .then(responseJson => {
        if (responseJson.error) {
            return Promise.reject(responseJson)
        }

        return responseJson;
    });