import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styles from './filterBetweenDates.module.scss';
import { Row, Col, Button, Input} from 'reactstrap';
import { getReportSale, getReportAdmin, getReportWallet, getReportSalary, getReportContable } from '../../../../store/action-creators/ReportsActions';

import ReportsContext from '../../context/reportsContext';

// Function
import { validateInputData } from './filterBetweenDates.js';


const FilterBetweenDate = () => {

	const dispatch = useDispatch();
	const savedToken = sessionStorage.getItem('token');		

	const context = useContext(ReportsContext);

	const [ startDate, setStartDate ] = useState("");
	const [ endDate, setEndDate ] = useState("");
	const [ errorStartDate, setErrorStartDate ] = useState("");
	const [ errorEndDate, setErrorEndDate ] = useState("");
	const [ searching, setSearching ] = useState("");

	const onSearchReport = () => {

		setErrorStartDate("");
		setErrorEndDate("");

		if(!startDate){
			setErrorStartDate("Ingresar Fecha");
			return;
		}
		if(!endDate){
			setErrorEndDate("Ingresar Fecha");
			return;
		}

		setSearching("Buscando...");
		
		const data = Object.assign({}, { token: savedToken, startDate: startDate, endDate: endDate });

		switch(context.data.selectedReport.num){

			case 1:				
				dispatch(getReportSale(data))
					.then((res) => {								
						context.setData({ ...context.data,reportSale:res.payload.reportSale });
						setSearching("");
					})
					.catch((error) => {
						setSearching("");
					});	
			break;
			case 2:				
				dispatch(getReportAdmin(data))
					.then((res) => {								
						context.setData({ ...context.data,reportAdmin:res.payload.reportAdmin });
						setSearching("");
					})
					.catch((error) => {
						setSearching("");
					});
			break;
			case 3:				
				dispatch(getReportWallet(data))
					.then((res) => {								
						context.setData({ ...context.data,reportWallet:res.payload.reportWallet });
						setSearching("");
					})
					.catch((error) => {
						setSearching("");
					});
			break;
			case 4:				
				dispatch(getReportSalary(data))
					.then((res) => {								
						context.setData({ ...context.data,reportSalary:res.payload.reportSalary });
						setSearching("");
					})
					.catch((error) => {
						setSearching("");
					});
			break;
			case 5:				
				dispatch(getReportContable(data))
					.then((res) => {								
						context.setData({ ...context.data,reportContable:res.payload.reportContable });
						setSearching("");
					})
					.catch((error) => {
						setSearching("");
					});
			break;

		}			
		

	}

	return(

		<div className={styles.filterBetweenDates}>
			<Row>													
			    <Col sm={3}>
			    	<span>Inicio de compra</span>
			        <Input
			          type="date"
			          value={startDate}
					  onChange={(event) => setStartDate(event.target.value)}
					  autoComplete="new-password"
			        />
			        <span className={styles.error}>{errorStartDate}</span>			    
			    </Col>
			    <Col sm={3}>
			    	<span>Fin de compra</span>
			        <Input
			          type="date"
			          value={endDate}
					  onChange={(event) => setEndDate(event.target.value)}
					  autoComplete="new-password"
			        />
			        <span className={styles.error}>{errorEndDate}</span>
			    </Col>
			    <Col sm={3}>
			    	<Button className={styles.btnSearch} onClick={onSearchReport}>Buscar</Button>
			    </Col>
			    <Col sm={3}>
					<p className={styles.searching}>{searching}</p>    
			    </Col>
			</Row>

		</div>

		

	);

}

export default FilterBetweenDate;