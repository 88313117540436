import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { 
	Label,
	FormGroup,
	Row,
	Card, 
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
//import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getSectionAccounting } from '../../store/action-creators/AdminActions';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../functions/roles';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const Accounting = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [movementsList, setMovementsList] = useState([]);	
	const [options, setOptions] = useState([{"label":"USD","value":"USD"},{"label":"EUR","value":"EUR"}]);
	const [totalMovements, setTotalMovements] = useState(0);
	const [curCurrency, setCurCurrency] = useState("USD");
	const [eurUsd, setEurUsd] = useState("");
	const [totalBTCin, setTotalBTCin] = useState(0);
	const [totalETHin, setTotalETHin] = useState(0);
	const [totalUSDTin, setTotalUSDTin] = useState(0);
	const [totalDAIin, setTotalDAIin] = useState(0);
	const [totalLTCin, setTotalLTCin] = useState(0);
	const [totalBTCout, setTotalBTCout] = useState(0);
	const [totalETHout, setTotalETHout] = useState(0);
	const [totalUSDTout, setTotalUSDTout] = useState(0);
	const [totalDAIout, setTotalDAIout] = useState(0);
	const [totalLTCout, setTotalLTCout] = useState(0);
	const [selectedCurrency, setSelectedCurrency] = useState([{"label":"USD","value":"USD"}]);
	const [rolesHabilitado, setRolesHabilitado] = useState([1,9]);		

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getSectionAccounting(data))
			.then((res) => {	
				setMovementsList(res.payload.movements);				
				setTotalMovements(res.payload.totalMovements);
				setEurUsd(res.payload.eurUsd);

				// Total de monedas entrantes/salientes
				let i;
				let btc_in=0,eth_in=0,usdt_in=0,dai_in=0,ltc_in=0;
				let btc_out=0,eth_out=0,usdt_out=0,dai_out=0,ltc_out=0;
				for(i=0;i<res.payload.movements.length;i++){

					if(res.payload.movements[i].tipo == 3){
						if(res.payload.movements[i].symbol2 === "USDT"){
							usdt_in = parseFloat(usdt_in + res.payload.movements[i].value2);
						}
						if(res.payload.movements[i].symbol2 === "LTC"){
							ltc_in = parseFloat(ltc_in + res.payload.movements[i].value2);
						}
					}

					if(res.payload.movements[i].tipo == 4 || res.payload.movements[i].tipo == 100 || res.payload.movements[i].tipo == 101 ){
						switch(res.payload.movements[i].symbol){
							case "BTC":
								btc_in = parseFloat(btc_in + res.payload.movements[i].value);
								break;
							case "ETH":
								eth_in = parseFloat(eth_in + res.payload.movements[i].value);
								break;
							case "USDT":
								usdt_in = parseFloat(usdt_in + res.payload.movements[i].value);
								break;
							case "DAI":
								dai_in = parseFloat(dai_in + res.payload.movements[i].value);
								break;
							case "LTC":
								ltc_in = parseFloat(ltc_in + res.payload.movements[i].value);
								break;
						}
					}	

					if( res.payload.movements[i].tipo == 102 || res.payload.movements[i].tipo == 103 || res.payload.movements[i].tipo == 104 || res.payload.movements[i].tipo == 105){
						switch(res.payload.movements[i].symbol){
							case "BTC":
								btc_in = parseFloat(btc_in - res.payload.movements[i].value);
								break;
							case "ETH":
								eth_in = parseFloat(eth_in - res.payload.movements[i].value);
								break;
							case "USDT":
								usdt_in = parseFloat(usdt_in - res.payload.movements[i].value);
								break;
							case "DAI":
								dai_in = parseFloat(dai_in - res.payload.movements[i].value);
								break;
							case "LTC":
								ltc_in = parseFloat(ltc_in - res.payload.movements[i].value);
								break;
						}
					}				

				}

				btc_in = btc_in.toFixed(9);
				btc_in = btc_in.substring(0,btc_in.length-1)

				eth_in = eth_in.toFixed(9);
				eth_in = eth_in.substring(0,eth_in.length-1)

				usdt_in = usdt_in.toFixed(7);
				usdt_in = usdt_in.substring(0,usdt_in.length-1)

				dai_in = dai_in.toFixed(9);
				dai_in = dai_in.substring(0,dai_in.length-1)

				ltc_in = ltc_in.toFixed(9);
				ltc_in = ltc_in.substring(0,ltc_in.length-1)

				setTotalBTCin(btc_in);
				setTotalETHin(eth_in);
				setTotalUSDTin(usdt_in);
				setTotalDAIin(dai_in);
				setTotalLTCin(ltc_in);

			})
			.catch((err) => {
				console.log(err);
			});
		
	}, []);

	// Solo en ésta sección las operaciones están al revés
	function SwitchTipo(props) {
		switch (props.tipo) {
			case 2:
				return 'Withdrawal';
			case 3:
				return 'Sell-Direct'; 
			case 4:
				return 'Sell-Swap';
			case 100:
				return 'Load';
			case 101:
				return 'Return';
			case 102:
				return 'SendTesting';
			case 103:
				return 'Pay-to-Provider';
			case 104:
				return 'Pay-to-Seller';
			case 105:
				return 'Salary';
			case 106:
				return 'Other';
		}
	}

	function replaceNumber(num){			
		num = num.toString().replace(/\./g,',');
		return num;
	}	

	const onSelectCurrency = selectedCurrency => {

	  if(selectedCurrency.label !== curCurrency){

	  	setCurCurrency(selectedCurrency.label);
	  	setSelectedCurrency(selectedCurrency);   

	  	let i;	  
	  	let movementsListUSD = new Array();	

	  	switch(selectedCurrency.label){

	  		case "USD":	  			  					  				
	  				for(i=0;i<movementsList.length;i++){
		  				movementsList[i].price = movementsList[i].priceInit;		  						  			
		  				movementsList[i].price2 = movementsList[i].price2Init; 
		  				movementsList[i].usd = movementsList[i].usdInit;
		  				movementsList[i].fee_usd = movementsList[i].fee_usdInit;
		  				movementsList[i].fee_usd2 = movementsList[i].fee_usd2Init;
		  				movementsList[i].fee_blockchain_usd2 = movementsList[i].fee_blockchain_usd2Init;
		  				movementsList[i].value_usd2 = movementsList[i].value_usd2Init;
		  			}
	  				break;
	  		case "EUR":	  				  			
	  			for(i=0;i<movementsList.length;i++){
	  				movementsList[i].priceInit = movementsList[i].price;
	  				movementsList[i].price = (movementsList[i].price / eurUsd).toFixed(2);
	  				movementsList[i].price2Init = movementsList[i].price2;
	  				movementsList[i].price2 = (movementsList[i].price2 / eurUsd).toFixed(2);
	  				movementsList[i].usdInit = movementsList[i].usd;
	  				movementsList[i].usd = (movementsList[i].usd / eurUsd).toFixed(3);
	  				movementsList[i].fee_usdInit = movementsList[i].fee_usd;
	  				movementsList[i].fee_usd = (movementsList[i].fee_usd / eurUsd).toFixed(2);
	  				movementsList[i].fee_usd2Init = movementsList[i].fee_usd2;
	  				movementsList[i].fee_usd2 = (movementsList[i].fee_usd2 / eurUsd).toFixed(2);
	  				movementsList[i].fee_blockchain_usd2Init = movementsList[i].fee_blockchain_usd2;
	  				movementsList[i].fee_blockchain_usd2 = (movementsList[i].fee_blockchain_usd2 / eurUsd).toFixed(2);	  				
	  				movementsList[i].value_usd2Init = (movementsList[i].value_usd2 / eurUsd).toFixed(2);
	  				movementsList[i].value_usd2 = (movementsList[i].value_usd2 / eurUsd).toFixed(2);
	  			}
	  			break;

	  	}
	  	

	  }
	  	  
    };


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-accounting">

						<span className="page-title">Accounting</span>						

						<Card className="admin-main-card">							

							{sectionRegistros ? (
								<div className="registros">

									<div className="totals">
										<h1><b>Total incoming coins</b></h1>
										<Table className="table" responsive>
											<thead>
												<tr>											
													<th>Bitcoin</th>
													<th>Ethereum</th>
													<th>Tether</th>
													<th>Dai</th>
													<th>Litecoin</th>													
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{replaceNumber(totalBTCin) } BTC</td>
													<td>{replaceNumber(totalETHin) } ETH</td>
													<td>{replaceNumber(totalUSDTin) } USDT</td>
													<td>{replaceNumber(totalDAIin) } DAI</td>
													<td>{replaceNumber(totalLTCin)} LTC</td>
												</tr>
											</tbody>
										</Table>
									{/*
										<h1><b>Total outcoming coins</b></h1>
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Bitcoin</Th>
													<Th>Ethereum</Th>
													<Th>Tether</Th>
													<Th>Dai</Th>
													<Th>Litecoin</Th>													
												</Tr>
											</Thead>
											<Tbody>
												<Tr>
													<Td>{totalBTCout} BTC</Td>
													<Td>{totalETHout} ETH</Td>
													<Td>{totalUSDTout} USDT</Td>
													<Td>{totalDAIout} DAI</Td>
													<Td>{totalLTCout} LTC</Td>
												</Tr>
											</Tbody>
										</Table>
									*/}

									</div>

									<hr />

									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Total = {totalMovements} movements</p>
											</Col>
											<Col sm={4}>
												<Select
								                    options={options}								                    
								                    className="selectCurrency"
								                    value={selectedCurrency}
								                    onChange={onSelectCurrency}
								                />
											</Col>
										</Row>
										<hr />
									</div>

									<ReactHTMLTableToExcel
					                    id="test-table-xls-button"
					                    className="download-table-xls-button"
					                    table="table-to-xls"
					                    filename="accounting"
					                    sheet="accounting"
					                    buttonText="Download as XLS"
					                />
					               

									<div className="list">
										<Table className="table" responsive id="table-to-xls">
											<thead>
												<tr>											
													<th>Date</th>
													<th>Operation</th>
													<th>Currency/price</th>
													<th>Amount</th>
													<th>Input fee</th>
													<th>Output fee</th>
													<th>Total Price</th>
												</tr>
											</thead>
											<tbody>
												{movementsList.length > 0 ?
													movementsList.map((movement, index) => {
														if(movement.name){												
															return (
																<tr key={index} className="tr">
																	<td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{movement.updated_at}
																		</Moment>
																	</td>
																	<td><SwitchTipo tipo={movement.tipo} /></td>
																	<td>
																		{ movement.tipo == 3 ? <> {movement.name} / {replaceNumber(movement.price)} {curCurrency} </> :null }
																		{ 
																			movement.tipo == 4 ? 
																			<> 
																				{movement.name} / {replaceNumber(movement.price)} {curCurrency} <br />
																				GBM Coin / {replaceNumber(movement.price2)} {curCurrency} <br />
																			</> 
																			:null 
																		}
																		{ movement.tipo == 100 ? <> {movement.name} / {replaceNumber(movement.price)} {curCurrency} </> :null }
																		{ movement.tipo == 101 ? <> {movement.name} / {replaceNumber(movement.price)} {curCurrency} </> :null }
																		{ movement.tipo == 102 ? <> {movement.name} / {replaceNumber(movement.price)} {curCurrency} </> :null }
																		{ movement.tipo == 103 || movement.tipo == 104 || movement.tipo == 105 || movement.tipo == 106 ? 
																			<> {movement.name} / {replaceNumber(movement.price)} {curCurrency} 
																			</> :null 
																		}
																	</td>
																	<td>
																		{ movement.tipo == 3 ? <> {replaceNumber(movement.value)} {movement.symbol} / {replaceNumber(movement.usd)} {curCurrency} </> :null }
																		{ 
																			movement.tipo == 4 ? 
																			<> 
																				{replaceNumber(movement.value2)} GBM / {replaceNumber(movement.usd)} {curCurrency} 
																			</> 
																			:null 
																		}
																		
																		{ movement.tipo == 100 ? <> {replaceNumber(movement.value)} {movement.symbol} / {replaceNumber(movement.usd)} {curCurrency} </> : null }
																		{ movement.tipo == 101 ? <> {replaceNumber(movement.value)} {movement.symbol} </> : null }
																		{ movement.tipo == 102 ? <> {replaceNumber(movement.value)} {movement.symbol} </> : null }
																		{ movement.tipo == 103 || movement.tipo == 104 || movement.tipo == 105 || movement.tipo == 106 ? 
																			<> {replaceNumber(movement.value)} {movement.symbol} / {replaceNumber(movement.usd)} {curCurrency}
																			</> 
																			: null 
																		}
																																		
																	</td>
																	<td>
																		{ movement.tipo == 3 ? <> 0 {movement.symbol} / 0 {curCurrency} </> :null }
																		{ movement.tipo == 4 ? <> {replaceNumber(movement.fee)} {movement.feeSymbol} / {replaceNumber(movement.fee_usd)} {curCurrency} </> :null }
																		{ movement.tipo == 100 ? <> 0 {movement.symbol} / 0 {curCurrency} </> :null }
																		{ movement.tipo == 101 ? <> 0 {movement.symbol} / 0 {curCurrency} </> :null }
																		{ movement.tipo == 102 ? <> 0 {movement.symbol} / 0 {curCurrency} </> :null }
																		{ movement.tipo == 103 || movement.tipo == 104 || movement.tipo == 105 || movement.tipo == 106 ? 
																			<> 0 {movement.symbol} / 0 {curCurrency} </> 
																			:null 
																		}
																	</td>
																	<td>																	
																		{ movement.tipo == 3 ? <> {replaceNumber(movement.fee2)} {movement.feeSymbol2} / {replaceNumber(movement.fee_usd2)} {curCurrency} </> :null }
																		{ 
																			movement.tipo == 4 ? 
																			<> 
																				{ movement.symbol === "USDT" || movement.symbol === "DAI" ?
																					<>
																						{replaceNumber(movement.value1)} {movement.symbol1} / {replaceNumber(movement.fee_usd1)} {curCurrency} <br/>
																						{replaceNumber(movement.fee_blockchain)} ETH / {replaceNumber(movement.fee_blockchain_usd)} {curCurrency} <br/>
																					</>
																					:null
																				}
																				
																				{replaceNumber(movement.fee_blockchain2)} {replaceNumber(movement.feeBlockchainSymbol2)} / {replaceNumber(movement.fee_blockchain_usd2)} {curCurrency}
																			</> :null 
																		}
																		{ movement.tipo == 100 ? <> {movement.symbol} / 0 {curCurrency} </> : null }
																		{ movement.tipo == 101 ? 
																			<> 																				
																				{
																					movement.sent_at2 ?
																					<>
																						{replaceNumber(movement.value2)} ETH / {replaceNumber(movement.value_usd2)} {curCurrency}
																						<br />
																						{replaceNumber(movement.fee2)} ETH / {replaceNumber(movement.fee_usd2)} {curCurrency}
																						<br />
																					</> : null
																				}
																				{replaceNumber(movement.fee)} ETH / {replaceNumber(movement.fee_usd)} {curCurrency}
																			</> : null 
																		}
																		{ movement.tipo == 102 ? <> {replaceNumber(movement.fee)} {movement.feeSymbol} / {replaceNumber(movement.fee_usd)} {curCurrency} </> : null }
																		{ movement.tipo == 103 || movement.tipo == 104 || movement.tipo == 105 || movement.tipo == 106 ? 
																			<> {replaceNumber(movement.fee)} {movement.feeSymbol} / {replaceNumber(movement.fee_usd)} {curCurrency} 
																			</> 
																			: null 
																		}
																	</td>
																	<td>
																		{ movement.tipo == 3 ? <> { replaceNumber( (movement.usd - movement.fee_usd2).toFixed(2) ) } {curCurrency} </> :null }
																		{ movement.tipo == 4 ? <> {replaceNumber(
																			(parseFloat(movement.usd) + 
																			parseFloat(movement.fee) -
																			movement.fee_usd1 -
																			movement.fee_blockchain_usd -
																			movement.fee_blockchain_usd2).toFixed(2) )
																		} {curCurrency} </> :null }
																		{movement.tipo == 100 ? 
																		<> 
																			{replaceNumber(movement.usd)} {curCurrency}
																		</> :null }
																		{movement.tipo == 101 ? 
																			<> 																																	
																				{
																					movement.sent_at2 ?
																					<>
																						{replaceNumber( -(parseFloat(movement.fee_usd + movement.value_usd2 + movement.fee_usd2).toFixed(2))) } {curCurrency}
																					</>
																					:
																					<>
																						{replaceNumber(-movement.fee_usd)} {curCurrency} 
																					</>
																				}
																				
																			</> :null 
																		}
																		{movement.tipo == 102 ? <> 
																			{replaceNumber( -(parseFloat(movement.fee + movement.fee_usd).toFixed(2))) } {curCurrency}
																		</> :null }
																		{movement.tipo == 103 || movement.tipo == 104 || movement.tipo == 105 || movement.tipo == 106 ? 
																			<> 
																			{replaceNumber( -(parseFloat(movement.usd + movement.fee_usd).toFixed(2))) } {curCurrency}
																			</> :null } 																				
																		
																	</td>
																</tr>
																
															);
														}
																										
													  })
													: null
												}
											</tbody>
										</Table>
									</div>
								
								</div>
							) : null}						
							
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default Accounting;
