import React from 'react';
import ReportsList from './reportsList.jsx';

const Index = () => {

	return(

		<ReportsList />

	)

}

export default Index;