import React, { useState, useEffect } from "react";
import { SubmissionError } from "redux-form";
import { Row, Col, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//import { compress, decompress } from 'compress-json';
//import jsonpack from 'jsonpack/main';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
//import MainLayout from "../../config/MainLayout";
import MainPrivateLayout from "../../config/MainPrivateLayout";
import AddnewsForm from "./AddnewsForm";
import { ROUTE_SEARCH_NEWS } from "../../constants/routes";
import { URL_NEWS } from "../../api/urls";
import { getNews, createNews, editNews, setNewsState, deleteNews } from '../../store/action-creators/NewsActions';
//import { now } from "lodash";

const Noticias = (props) => {

  const dispatch = useDispatch();
  let history = useHistory();
  //let newsid = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  const [newsid, setNewsid] = useState(qs.parse(props.location.search, { ignoreQueryPrefix: true }).id);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [tags, setTags] = useState("");
  const [detail, setDetail] = useState("");
  const [picture, setPicture] = useState("");
  const [visibility, setVisibility] = useState("3");
  const [language, setLanguage] = useState("es");
  const [publisheddate, setPublishedDate] = useState(new Date());
  const savedToken = sessionStorage.getItem('token');

  const setInitial = () => {
    setTitle("");
    setSubtitle("");
    setTags("");
    setDetail("");
    setPicture("");
    setVisibility("3");
    setLanguage("en");
    setPublishedDate(new Date());
  };

  const convertDate = (inputFormat) => {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  //if (!newsid) setNewsid(null);
  useEffect(() => {
    if (!newsid) {
      setInitial();
    } else {
      setIsLoading(true);
      dispatch(getNews({token: savedToken, id: newsid, all: 1}))
        .then(res => {
            console.log('getNews res', res);
            const data = res.payload.data[0];
            setTitle(data.title);
            setSubtitle(data.subtitle);
            setTags(data.tags);
            setDetail(data.detail);
            setPicture(data.picture);
            setVisibility(data.visibility);
            setLanguage(data.language);
            setPublishedDate(convertDate(data.published_date));
            setIsLoading(false);
        })
        .catch(error => {
          console.log('error', error);
          setInitial();
          setIsLoading(false);
        });
    }    
  }, [newsid]);

  const handleSubmit = (values) => {
    return new Promise(async function (resolve, reject) {

      setIsLoading(true);
      let data = {
        token: savedToken, 
        title: values.title,
        subtitle: values.subtitle,
        tags: values.tags,
        detail: values.richeditor,
        visibility: Number(values.visibility),
        language: values.language
      };
      let temp = new Date();
      let utcDate = temp.toUTCString(); 
      if (!newsid) data.created_date = utcDate;
      if (values.publisheddate) {
        console.log('values.publisheddate', values.publisheddate);
        temp = new Date(values.publisheddate); 
        console.log('temp', temp);
        utcDate = temp.toUTCString();
        console.log('utcDate', utcDate);
        data.published_date = utcDate;
      }
      
      console.log('imgupload');
      if (values.imgupload) {
        let image = await getBase64(values.imgupload);
        if (image) data.picture = image.base64;
      }

      if (newsid) {
        data.id = newsid;
        console.log('editNews data', data);
        dispatch(editNews(data))
        .then(res => {
            setTitle(data.title);
            setSubtitle(data.subtitle);
            setTags(data.tags);
            setDetail(data.detail);
            if (data.picture) setPicture(data.picture);
            setVisibility(data.visibility);
            setLanguage(data.language);
            setPublishedDate(convertDate(data.published_date));
            setIsLoading(false);
            if (res.payload.status === "success") {
              resolve(data);
            } else {
              reject(
                new SubmissionError({
                  _error: "Error al actualizar la noticia",
                })
              );
            }
        })
        .catch(error => {
          console.log('editNews error1', error);
          setIsLoading(false);
          reject(
            new SubmissionError({
              _error: "Error al actualizar la noticia",
            })
          );
        });
      } else {
        data.published = 0;
        console.log('createNews data', data);
        dispatch(createNews(data))
        .then(res => {
            const result = res.payload.data[0];
            console.log('createNews result', result);
            setTitle(result.title); 
            setSubtitle(result.subtitle);
            setTags(result.tags);
            setDetail(result.detail);
            setPicture(result.picture);
            setVisibility(result.visibility);
            setLanguage(result.language);
            setPublishedDate(convertDate(result.published_date));
            setIsLoading(false);
            setNewsid(result._id);
            resolve(result);
        })
        .catch(error => {
          console.log('editNews error2', error);
          setIsLoading(false);
          reject(
            new SubmissionError({ publisheddate: 'User does not exist'})
          );
        });
      }
    });
  };

  const handlePreview = () => {
    if (newsid) {
      const min = 1;
      const max = 9999;
      const rand = Math.ceil(min + Math.random() * (max - min));
      let url = `${URL_NEWS}/news?preview=${rand}&id=${newsid}`;
      window.open(url, "_blank");
    }
  };

  const handlePublish = () => {
    return new Promise(function (resolve, reject) {
      setIsLoading(true);
      let data = {token: savedToken, id: newsid, published: 1};
      dispatch(setNewsState(data))
        .then(res => {
            setIsLoading(false);
            if (res.payload.status === "success") {
              resolve(data);
              history.push(ROUTE_SEARCH_NEWS);
            } else {
              reject(
                new SubmissionError({
                  _error: "Error al publicar la noticia",
                })
              );
            }
        })
        .catch(error => {
          setIsLoading(false);
          reject(
            new SubmissionError({
              _error: "Error al publicar la noticia",
            })
          );
        });
    });
  };

  const handleSuspend = () => {
    return new Promise(function (resolve, reject) {
      setIsLoading(true);
      let data = {token: savedToken, id: newsid, published: 2};
      dispatch(setNewsState(data))
        .then(res => {
            setIsLoading(false);
            if (res.payload.status === "success") {
              resolve(data);
              history.push(ROUTE_SEARCH_NEWS);
            } else {
              reject(
                new SubmissionError({
                  _error: "Error al suspender la noticia",
                })
              );
            }
        })
        .catch(error => {
          setIsLoading(false);
          reject(
            new SubmissionError({
              _error: "Error al suspender la noticia",
            })
          );
        });
    });
  };

  const handleDelete = () => {
    return new Promise(function (resolve, reject) {
      setIsLoading(true);
      let data = {token: savedToken, id: newsid};
      dispatch(deleteNews(data))
        .then(res => {
            setIsLoading(false);
            if (res.payload.status === "success") {
              resolve(data);
              history.push(ROUTE_SEARCH_NEWS);
            } else {
              reject(
                new SubmissionError({
                  _error: "Error al eliminar la noticia",
                })
              );
            }
        })
        .catch(error => {
          setIsLoading(false);
          reject(
            new SubmissionError({
              _error: "Error al eliminar la noticia",
            })
          );
        });
    });
  };

  const getBase64 = (file) => {
    return new Promise(function (resolve, reject) {
      let imageFile = file;
      if (imageFile) {
        const localImageUrl = URL.createObjectURL(imageFile);
        const imageObject = new window.Image();
        imageObject.onload = () => {
          imageFile.width = imageObject.naturalWidth;
          imageFile.height = imageObject.naturalHeight;
          URL.revokeObjectURL(imageFile);
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          canvas.height = imageObject.naturalHeight;
          canvas.width = imageObject.naturalWidth;
          ctx.drawImage(imageObject, 0, 0);
          let base64 = canvas.toDataURL(imageFile.type);
          resolve({
            base64: base64,
          });
        };
        imageObject.src = localImageUrl;
      } else {
        resolve({
          base64: null,
        });
      }
    });
  };

  const handleOnSubmitSuccess = (data) => {
    setIsLoading(false);
    const mensaje = "Noticia " + (newsid ? "actualizada" : "registrada") + " con éxito";
    toast(mensaje, {type: toast.TYPE.INFO});
  };

  return (
    <MainPrivateLayout>
      <div className="page-content-container">
        <span className="page-title">
          {newsid ? "Edición de noticias" : "Carga de noticias"}
        </span>
        <Row className="dashboard-row">
          <Col xs="12" sm="12" md="12" lg="12">
            <ToastContainer
              position="top-right"
              draggable={false}
            ></ToastContainer>
            {isLoading ? (
              <Spinner color="success" />
            ) : (
              <AddnewsForm
                id={newsid}
                title={title}
                subtitle={subtitle}
                tags={tags}
                language={language}
                publisheddate={publisheddate}
                visibility={visibility}
                richeditor={detail}
                picture={picture}
                onSubmit={handleSubmit}
                handleDelete={handleDelete}
                handleSuspend={handleSuspend}
                handlePublish={handlePublish}
                handlePreview={handlePreview}
                onSubmitSuccess={handleOnSubmitSuccess}
              />
            )}
          </Col>
        </Row>
      </div>
    </MainPrivateLayout>
  );
};

export default Noticias;
