import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	Card,
	CardBody,
	CardFooter,
	Row,
	Col,
	Modal,
	Spinner,
	Input,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	Label,
	Button,
} from 'reactstrap';
import { FaUserAlt, FaEnvelope } from "react-icons/fa";
import { FiPhoneCall } from 'react-icons/fi';

import MainPrivateLayout from '../../config/MainPrivateLayout';
import HexagonPhoto from '../../components/HexagonPhoto';

const Profile = () => {
	const dispatch = useDispatch();

	const savedToken = sessionStorage.getItem('token');
	const cardBackground = {
		background: `url(${process.env.PUBLIC_URL}/images/home/patron-hexagonos.svg)`,
	};

	// Usuario
	const user = useSelector((store) => store.user.user);	

	const [avatar, setAvatar] = useState('');	
		
	useEffect(() => {		
		if(user && user.avatar){
			let url = process.env.REACT_APP_ASGARD+`/api/avatar/${user.avatar}?token=${savedToken}`;			
			setAvatar(url);	
		}					
	}, [user]);

	

	const [userEmail, setUserEmail] = useState(user.email);
	const [userTelefono, setUserTelefono] = useState(user.telefono);

	return (
		<MainPrivateLayout>
			<div className="page-content-container section-profile">
				<span className="page-title">Perfil</span>
				<Card className="main-card">
					<CardBody style={cardBackground}>
						<Row className="basic-row">
							<Col xs={12} sm={12} md={4} lg={4} xl={4} className="profile-data-col">
								<HexagonPhoto id={'profile-page'} width={'50%'} image={avatar} />
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8} className="profile-data-col">
								<Row className="user-data-row">
									<FaUserAlt className="icon" />
									<span>
										<strong>{user.usuario}</strong>
									</span>
								</Row>
								<Row className="user-data-row">
									<FaEnvelope className="icon" />
									<span>
										<strong>{user.email}</strong>
									</span>
								</Row>
								<Row className="user-data-row">
									<FiPhoneCall className="icon" />
									<span>
										<strong>{user.telefono}</strong>
									</span>
								</Row>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</div>
		</MainPrivateLayout>
	);
};

export default Profile;
