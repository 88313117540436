import React from 'react';
import InternalVault from './internalVault.jsx';

const Index = () => {
	
	return(

		<InternalVault />

	)

}

export default Index;