import React from 'react';
import NewDonation from './newDonation.jsx';

const Index = () => {

	return(

		<NewDonation />

	)

}

export default Index;