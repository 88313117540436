import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';

const CustomSelectInput = (props) => {
    const { countries, handleChange, selectedOption, isInvalid } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <div className="custom-select-input-container">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle tag={"div"} caret className={isInvalid ? "custom-select-input-toggle is-invalid" : "custom-select-input-toggle"}>
                    {
                        isEmpty(selectedOption) ?
                            <span className="custom-select-input-placeholder">Código Internacional</span>
                            :
                            <span>{selectedOption.label} &nbsp; {selectedOption.phoneCode}</span>
                    }
                </DropdownToggle>
                <DropdownMenu className="custom-select-input-options" right>
                    {
                        countries.map((country, index) => {
                            return (
                                <DropdownItem key={`custom-select-option-${index}`} tag={Row} className="custom-select-input-option" onClick={() => handleChange(country)}>
                                    <Col xs={2} className="custom-select-input-col">
                                        <img
                                            src={`https://www.countryflags.io/${country.imgCode}/shiny/64.png`}
                                            width="auto"
                                            height="20"
                                            alt=""
                                            loading="lazy"
                                        />
                                    </Col>
                                    <Col xs={7} className="custom-select-input-col">
                                        <span>{country.label}</span>
                                    </Col>
                                    <Col xs={3} className="custom-select-input-col">
                                        <span>{country.phoneCode}</span>
                                    </Col>
                                </DropdownItem>
                            );
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default CustomSelectInput;