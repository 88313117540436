import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form"; // redux form nos provee un reducer
import { user } from "./reducers/UserReducer";
import { news } from "./reducers/NewsReducer";
import global from "./reducers/GlobalReducer";

export default combineReducers({
  form: reduxForm, // es importante usar la key form ya que es la que redux-form espera. Cambiarle el nombre es posible pero lo complica mucho
  user,
  global,
  news,
});
