import React from 'react';
import DepositsLinks from './depositsLinks.jsx';

const Index = () => {

	return(

		<DepositsLinks />

	)

}

export default Index;