import React from 'react';
import Movements from './movements.jsx';

const Index = () => {
	
	return(

		<Movements />

	)

}

export default Index;