import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LogInAccess, LogOut } from '../../store/action-creators/UserActions';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../../constants/routes';
import { Row, Spinner } from 'reactstrap';

function PublicRoute({ component: Component, ...rest }) {

	const dispatch = useDispatch();
	const savedToken = sessionStorage.getItem('token');
	const [isLoading, setIsLoading] = useState(true);
	const [verifiedAccess, setVerifiedAccess] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (savedToken) {
			dispatch(
				LogInAccess({
					token: savedToken,
				})  
			)
				.then((result) => {
					setVerifiedAccess(true);
					setIsLoading(false);
				})
				.catch((error) => {
					dispatch(
						LogOut({
							token: savedToken,
						})
					)
						.then((result) => {
							history.push(ROUTE_LOGIN);
						})
						.catch((error) => {
							history.push(ROUTE_LOGIN);
						});
					setVerifiedAccess(false);
					setIsLoading(false);
				});
		} else {
			setVerifiedAccess(false);
			setIsLoading(false);
		}
	}, []);

	return (
		<Route
			// Al no tener un path definido siempre se ejecuta la funcion de la propiedad render
			{...rest}
			render={(props) =>
				isLoading ? (
					<></>					
				) : verifiedAccess ? (
					<Redirect to={{ pathname: ROUTE_DASHBOARD }} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}

export default PublicRoute;
