const getOptions = () =>{

	const options = [
	  { value: '1', label: 'Iniciar' },
	  { value: '2', label: 'Aprobar' },
	  { value: '3', label: 'Modificar' },
	  { value: '4', label: 'Eliminar' }
	];	

	return options;

}

export { getOptions };