import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSectionVaults } from '../../../store/action-creators/VaultsActions'
import { structContext } from '../context/vaultContext';
  
const Init = () => {

	const dispatch = useDispatch();
	const savedToken = sessionStorage.getItem('token');
	
	const [dataContext,setDataContext] = useState(structContext);

	useEffect(() => {
    
	    const data = Object.assign({}, { token: savedToken });

	    dispatch(getSectionVaults(data))
	    .then((res) => {	      	    		     

	      setDataContext({ 
	      	...dataContext,
	      	GBMSold: res.payload.GBMSold,
	      	heimdall: { 
	      		vaults: res.payload.heimdall.getVaults.vaults,
				gbmcoinEthBalance: res.payload.heimdall.getVaults.gbmcoinEthBalance,
				gbmcoinEthUsd: res.payload.heimdall.getVaults.gbmcoinEthUsd,
				gbmcoinTrxBalance: res.payload.heimdall.getVaults.gbmcoinTrxBalance,				
				gbmcoinTrxUsd: res.payload.heimdall.getVaults.gbmcoinTrxUsd,
				gbmcoinAvaxBalance: res.payload.heimdall.getVaults.gbmcoinAvaxBalance,				
				gbmcoinAvaxUsd: res.payload.heimdall.getVaults.gbmcoinAvaxUsd,
				movementsList: res.payload.heimdall.getMovements,
				lagarto: res.payload.heimdall.getVaults.lagarto
	      	},
	      	nideport: {
	      		vaults: res.payload.nideport.getVaults.vaults,				
				movementsList: res.payload.nideport.getMovements
	      	}	        
	      });
	                  
	    })
	    .catch((err) => { });   

    }, []);   

    return { dataContext, setDataContext };

}

export { Init }

	

