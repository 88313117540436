import React, { useContext } from 'react';
import ReportsContext from '../../context/reportsContext';
import styles from './reportHeader.module.scss';

const ReportHeader = () => {

	const context = useContext(ReportsContext);	

	return(

		<div>
			<h1 className={styles.title}>{context.data.selectedReport.title}</h1>
		</div>

	);

}

export default ReportHeader;