import React, { useContext, useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import VaultContext from '../../context/vaultContext';
import styles from './header.module.scss';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

const Header = () => {
					
	const context = useContext(VaultContext);	
	
	const [ vaultsHeimdall, setVaultsHeimdall ] = useState("");
	const [ movementListHeimdall, setMovementListHeimdall ] = useState("");	

	let condHeimdall, condNideport;


	if(context.data.company == "heimdall"){
		condHeimdall = styles.active;
		condNideport = styles.inactive;
	}else{
		if(context.data.company == "nideport"){
			condNideport = styles.active;
			condHeimdall = styles.inactive;			
		}
	}
	

	const onNideport = () => {		 			 		
 		condNideport = styles.active;
		condHeimdall = styles.inactive;		
		context.setData({ ...context.data,company:"nideport" });				
	}

	const onHeimdall = () => {
		condHeimdall = styles.active;
		condNideport = styles.inactive;
		context.setData({ ...context.data,company:"heimdall" });				
	}

	return(

		<div className={styles.section}>
			{	
				context.data.heimdall.vaults.length > 0 || vaultsHeimdall ?
					<div>
						<Alert color="primary" className={styles.banner} >
						   {context.data.GBMSold} GBM vendidos (ERC20-Ethereum + TRC20 + ERC20-Avalanche)
						</Alert>			
						<div className={styles.options}>
							<Link to="/vaults/heimdall" className={styles.link}><p className={ `${styles.one} ${condHeimdall}` } onClick={onHeimdall}>Heimdall</p></Link>
							<Link to="/vaults/nideport" className={styles.link}><p className={ `${styles.two} ${condNideport}` } onClick={onNideport}>Nideport</p></Link>
						</div>			
					</div>
					:null
			}
		</div>

	)
}

export default Header;