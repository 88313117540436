import React from 'react';
import CorporateVault from './corporateVault.jsx';

const Index = () => {
	
	return(

		<CorporateVault />

	)

}

export default Index;