import React, { useContext } from 'react';

// Atoms
import ReportHeader from '../../atoms/reportHeader';
import FilterBetweenDates from '../../atoms/filterBetweenDates';
import ReportSaleDetail from '../../atoms/reportSaleDetail';
import ReturnStart from '../../atoms/returnStart';

const ReportSale = () => {

	return(
		<div>
			<ReturnStart />
			<ReportHeader />
			<FilterBetweenDates />
			<ReportSaleDetail />
		</div>
	);

}

export default ReportSale;