import React from 'react';
import MovementsList from './movementsList.jsx';

const Index = (props) => {
	
	return(

		<MovementsList txSearched = {props.txSearched} selectedOperation = {props.selectedOperation} />

	)

}

export default Index;