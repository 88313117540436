import React from 'react';
import SendMoney from './sendMoney.jsx';

const Index = () => {

	return(

		<SendMoney />

	)

}

export default Index;