import React from 'react';
import ExternalVault from './externalVault.jsx';

const Index = () => {
	
	return(

		<ExternalVault />

	)

}

export default Index;