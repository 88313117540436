import React, { useEffect, useState, useContext } from 'react';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../functions/roles';
import { animateScroll as scroll } from 'react-scroll';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getSectionVaults } from '../../../store/action-creators/VaultsActions'

import Header from '../molecules/header';
import ExternalVault from '../molecules/externalVault';
import CorporateVault from '../molecules/corporateVault';
import InternalVault from '../molecules/internalVault';
import Movements from '../molecules/movements';

import Deposit from '../atoms/deposit';
import Declare from '../atoms/declare';
import MovementDetail from '../atoms/movementDetail';

import { Init } from './vaults.js';

import VaultContext from '../context/vaultContext';

  
const Vaults = () => {
		
	const user = useSelector((store) => store.user.user);		
	const [rolesHabilitado, setRolesHabilitado] = useState([1,4,14,17]);	
		
	const { dataContext, setDataContext } = Init();	
	
	return (
		<MainPrivateLayout>

			{ 
				verifyRoles(user.roles,rolesHabilitado) ?

						<div className="page-content-container section-vaults">
							<span className="page-title">Bóvedas</span>
							<Card className="main-card">
								<VaultContext.Provider value={{data:dataContext,setData:setDataContext}}>
									<Router>
										<Switch>

											{/* MOLECULES */}
																						
											<Route path="/vaults/heimdall" exact >  			 					  			
									  			<Header />
											    <ExternalVault />
											    <InternalVault />
											    <CorporateVault />
											    <Movements />
											</Route>

											<Route path="/vaults/nideport" exact >  			 					  			
									  			<Header />
											    <ExternalVault />
											    <InternalVault />											    
											    <Movements />
											</Route>

											{/* ATOMS */}

											<Route path="/vaults/:company/deposit" exact>
												<Deposit />
											</Route>

											<Route path="/vaults/heimdall/declare" exact>
												<Declare />
											</Route>											

											<Route path="/vaults/:company/movementDetail" exact>
												<MovementDetail />
											</Route>

										</Switch>
									</Router>
								</VaultContext.Provider>
																	          
							</Card>
						</div>

				:null
			}
		</MainPrivateLayout>
	);
};

export default Vaults;
