import React from 'react';
import NewDepositLink from './newDepositLink.jsx';

const Index = () => {

	return(

		<NewDepositLink />

	)

}

export default Index;