import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import { useHistory } from "react-router-dom";
import styles from './manageDonated.module.scss';
import MainPrivateLayout from '../../../../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../../../../functions/roles';
import '../../../../../../styles/styles.scss';
import Back from '../../../../../../components/back';

const ManageDonated = () => {
	
	const history = useHistory();
	
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);
	
	const handleBack = () => {
		history.push("/donations");
	}

	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />							
						</Card>						
					</div>
				:null


			}	
		</MainPrivateLayout>

	)

};

export default ManageDonated;