import React, { useState } from 'react';
import { Button, Row, Modal } from 'reactstrap';
import { Form, Input, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { passwordReset } from '../../store/action-creators/UserActions';
import generateSha256 from '../../services/generateSha256';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN_EMAIL } from '../../constants/routes';

const initialFormData = Object.freeze({
	user: '',
	password: '',
	confirmPassword: '',
	google2fa: '',
});

const LoginPasswordReset = (props) => {
	const reset_token = props.match.params.reset_token;

	const dispatch = useDispatch();
	let history = useHistory();

	const [formData, setFormData] = useState(initialFormData);
	const [errorUser, setErrorUser] = useState('');
	const [errorPassword, setErrorPassword] = useState('');
	const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
	const [errorGoogle2fa, setErrorGoogle2fa] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [reset, setReset] = useState(false);

	// Modal
	const [modal, setModal] = useState(true);
	const [backdrop, setBackdrop] = useState(true);
	const [keyboard, setKeyboard] = useState(true);

	const handleChange = (e) => {
		setFormData({
			...formData,
			// Trimming any whitespace
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		setErrorUser('');
		setErrorGoogle2fa('');
		setErrorPassword('');
		setErrorConfirmPassword('');

		e.preventDefault();
		let error = 0;

		let reqPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)\S{8,}$/;

		if (!formData.user) {
			setErrorUser('Ingresar usuario');
			error = 1;
		}
		if (!formData.password) {
			setErrorPassword('Ingresar contraseña');
			error = 1;
		}
		if (formData.password.length < 8) {
			setErrorPassword('Ingresar por lo menos 8 dítigos');
			error = 1;
		}
		if (!formData.confirmPassword) {
			setErrorConfirmPassword('Confirmar contraseña');
			error = 1;
		}
		if (formData.confirmPassword.length < 8) {
			setErrorConfirmPassword('Ingresar por lo menos 8 dítigos');
			error = 1;
		}

		if (!reqPassword.test(formData.password)) {
			console.log('no valido');
			setErrorPassword('Mínimo 1 mayúscula, 1 minúscula y 1 número');
		}
		if (!reqPassword.test(formData.confirmPassword)) {
			setErrorPassword('Mínimo 1 mayúscula, 1 minúscula y 1 número');
		}

		if (formData.password != formData.confirmPassword) {
			setErrorPassword('No coinciden las contraseñas');
			setErrorConfirmPassword('No coinciden las contraseñas');
			error = 1;
		}
		if (!formData.google2fa) {
			setErrorGoogle2fa('Ingresar código');
			error = 1;
		}

		if (error) {
			return;
		}

		setIsLoading(true);

		let data = Object.assign(
			{},
			{
				reset_token: reset_token,
				user: formData.user,
				password: generateSha256(formData.password),
				one_time_password: formData.google2fa,
			}
		);

		dispatch(passwordReset(data))
			.then((res) => {
				setIsLoading(false);
				setReset(true);
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.code == 400) {
					setErrorUser('usuario incorrecto');
				}
				if (error.code == 401) {
					setErrorGoogle2fa('Google 2FA incorrecto');
				}
			});
	};

	// Modal

	const toggle = () => setModal(!modal);

	const changeBackdrop = (e) => {
		let value = e.target.value;
		if (value !== 'static') {
			value = JSON.parse(value);
		}
		setBackdrop(value);
	};

	const changeKeyboard = (e) => {
		setKeyboard(e.currentTarget.checked);
	};

	const handleLogin = () => {
		history.push(ROUTE_LOGIN_EMAIL);
	};

	return (
		<div
			className="login passwordReset"
			style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}
		>
			<img src={process.env.PUBLIC_URL + '/images/gbm-logo.png'} alt="GBM Logo" className="gbm-logo" />

			{reset ? (
				<div>
					<Modal
						isOpen={modal}
						toggle={toggle}
						backdrop={backdrop}
						keyboard={keyboard}
						centered
						className="login-modal modal-password-reset"
					>
						<p>La contraseña se actualizó con éxito</p>
						<Row className="basic-row">
							<Button
								color=""
								style={{
									backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_dorado_inicio.png)`,
								}}
								className="login-button"
								onClick={handleLogin}
								type="submit"
							>
								{'ACCEDER'}
							</Button>
						</Row>
					</Modal>
				</div>
			) : null}

			{isLoading ? (
				<Spinner type="grow" color="success" />
			) : (
				<div>
					<h1 class="login-title">Reestrablecer contraseña</h1>

					<div className="login-form">
						<Form encType="multipart/form-data" autoComplete="off">
							<Input
								placeholder="Usuario"
								type="text"
								name="user"
								onChange={handleChange}
								className="login-input"
								autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
							/>
							<p className="login-error-msg">{errorUser}</p>

							<Input
								placeholder="Contraseña"
								type="password"
								name="password"
								onChange={handleChange}
								className="login-input"
								autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
							/>
							<p className="login-error-msg">{errorPassword}</p>

							<Input
								placeholder="Confirmar contraseña"
								type="password"
								name="confirmPassword"
								onChange={handleChange}
								className="login-input"
								autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
							/>
							<p className="login-error-msg">{errorConfirmPassword}</p>

							<Input
								placeholder="Google Authenticator"
								type="text"
								name="google2fa"
								onChange={handleChange}
								className="login-input"
								autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
							/>
							<p className="login-error-msg">{errorGoogle2fa}</p>
						</Form>

						<Row className="basic-row">
							<Button
								color=""
								style={{
									backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_dorado_inicio.png)`,
								}}
								className="login-button"
								onClick={handleSubmit}
								type="submit"
							>
								{'REESTABLECER'}
							</Button>
						</Row>
					</div>
				</div>
			)}
		</div>
	);
};

export default LoginPasswordReset;
