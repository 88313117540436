import React from "react";
import { Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { ROUTE_ADD_NEWS } from "../constants/routes";

const NewsTable = (props) => {
  const { tableData, search } = props;

  const alignCenter = { textAlign: "center", width: "9rem" };

  const convertDate = (date) => {
    let d = new Date(date);
    return moment(d).format("DD/MM/YYYY HH:mm:ss");
  };

  const date_time_sort = (a, b) => {
    return (
      new Date(b.published_date).getTime() -
      new Date(a.published_date).getTime()
    );
  };

  const getState = (state) => {
    let description = "";
    switch (state) {
      case 0:
        description = "En edición";
        break;
      case 1:
        description = "Publicada";
        break;
      case 2:
        description = "Suspendida";
        break;
      case 3:
        description = "Eliminada";
        break;
      default:
        description = "";
    }
    return description;
  };

  const getVisibility = (visibility) => {
    let description = "";
    switch (visibility) {
      case 1:
        description = "Portal";
        break;
      case 2:
        description = "App";
        break;
      case 3:
        description = "Ambas";
        break;
      default:
        description = "";
    }
    return description;
  };

  const getLanguage = (language) => {
    let description = "";
    switch (language) {
      case "en":
        description = "Inglés";
        break;
      case "es":
        description = "Español";
        break;
      default:
        description = "";
    }
    return description;
  };

  const renderTableData = () => {
    if (!tableData) return (<tr key={1}>
      <td colSpan="4" style={alignCenter}>
        {"No hay registros con el filtro de búsqueda ingresado " +
          String.fromCharCode(34) +
          search +
          String.fromCharCode(34)}
      </td>
    </tr>);
    if (tableData.map) {
      return tableData.sort(date_time_sort).map((news, index) => {
        const { _id, title, published, published_date, visibility, language } = news;
        return (
          <tr key={_id}>
            <td>
              <Button
                size="sm"
                color="link"
                style={{ textAlign: "left" }}
                tag={Link}
                to={ROUTE_ADD_NEWS + "?id=" + _id}
              >
                {title}
              </Button>
            </td>
            <td style={alignCenter}>{getLanguage(language)}</td>
            <td style={alignCenter}>{getVisibility(visibility)}</td>
            <td style={alignCenter}>{getState(published)}</td>
            <td style={alignCenter}>{convertDate(published_date)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr key={1}>
          <td colSpan="4" style={alignCenter}>
            {"No hay registros con el filtro de búsqueda ingresado " +
              String.fromCharCode(34) +
              search +
              String.fromCharCode(34)}
          </td>
        </tr>
      );
    }
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Título</th>
          <th style={alignCenter}>Idioma</th>
          <th style={alignCenter}>Visilidad</th>
          <th style={alignCenter}>Estado</th>
          <th style={alignCenter}>Fecha</th>
        </tr>
      </thead>
      <tbody>{renderTableData()}</tbody>
    </Table>
  );
};

export default NewsTable;
