import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getRoles,getAdminsPerRole } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../functions/roles';


const Roles = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [sectionDetail, setSectionDetail] = useState(false);	
	const [selectedRoleAdmin, setSelectedRoleAdmin] = useState("");
	const [rolesList, setRolesList] = useState([]);
	const [usuario, setUsuario] = useState("");	
	const [totalRoles, setTotalRoles] = useState(0);
	const [inputRole, setInputRole] = useState("");
	const [adminsPerRole, setAdminsPerRole] = useState("");
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
		

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getRoles(data))
			.then((res) => {				
				setRolesList(res.payload.roles);
				setTotalRoles(res.payload.totalRoles);
			})
			.catch((error) => {
				console.log(error);
			});
		
	}, []);

	

	const onDetail = (role) => {		
		
		let data = Object.assign({}, { token: savedToken, id_admin_role: role.id_admin_role });
		dispatch(getAdminsPerRole(data))
			.then((res) => {				
				setSelectedRoleAdmin(role);				
				setAdminsPerRole(res.payload.admins);
				scroll.scrollTo(200, { duration: 0 });				
				setSectionRegistros(false);
				setSectionDetail(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};	

	const onBackInit = () => {
		setSectionRegistros(true);
		setSectionDetail(false);
	};


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-roles">

						<span className="page-title">Roles</span>

						<Card className="admin-main-card">
							{sectionRegistros ? (
								<div className="registros">

									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Roles = {totalRoles}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={inputRole}
														placeholder='Buscar role'
														onChange={(event) => setInputRole(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="roles-list">
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Role</Th>
													<Th>Descripción</Th>																				
												</Tr>
											</Thead>
											<Tbody>
												{rolesList.length > 0 ?
													rolesList.map((role, index) => {
														
														if (role.role.toLowerCase().indexOf(inputRole.toLowerCase()) >= 0 || inputRole === ''){														
															return (
																<Tr key={index} className="tr-role" onClick={() => onDetail(role)}>
																	<Td>{role.role}</Td>
																	<Td>{role.description}</Td>															
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}

							{sectionDetail ? (
								<div className="detail">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">DETALLES DEL ROLE</h1>

											<Row className="datos">
												<Col sm={12}>
													<p><b>Role: </b> {selectedRoleAdmin.role}</p>
													<span>{selectedRoleAdmin.description}</span>
												</Col>
											</Row>

											<div className="roleAdmins">
												<p className="second-title"><b>Administradores que tienen éste rol</b></p>

												<Table className="table">
													<Thead>
														<Tr>
															<Th>Fecha Asig.</Th>
															<Th>Usuario</Th>
															<Th>nombre</Th>
															<Th>Apellido</Th>
														</Tr>
													</Thead>
													<Tbody>
														{adminsPerRole.length > 0 ?
															adminsPerRole.map((admin, index) => {
																				
																return (
																	<Tr key={index}>
																		<Td>
																			<Moment format="YYYY-MM-DD HH:mm" utc>
																				{admin.created_at}
																			</Moment>
																		</Td>
																		
																		<Td>{admin.usuario}</Td>
																		<Td>{admin.nombre}</Td>
																		<Td>{admin.apellido}</Td>
																	</Tr>
																);
																	
															})
															: null
														}
													</Tbody>
												</Table>										
											</div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}
							
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default Roles;
