import React from 'react';
import ReportAdmin from './reportAdmin.jsx';

const Index = () => {

	return(

		<ReportAdmin />

	)

}

export default Index;