import React, { useState, useEffect } from 'react';
import styles from './beneficiariesTable.module.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { getDonations } from '../../../../store/action-creators/AdminActions';
import AccessSection from './accessSection';

const BeneficiariesTable = () => {	
 
	const savedToken = sessionStorage.getItem('token');	
	const dispatch = useDispatch();
	const [ donations, setDonations ] = useState([]);	

	useEffect(() => {

		const data = Object.assign({}, { token: savedToken });
		dispatch(getDonations(data))
			.then((res) => {				
				setDonations(res.payload.donations);
			})
			.catch((error) => {
				
			});

	},[]);
	
	
	return(
		 
		<Table className="table">
			<Thead>
				<Tr>
					<Th>Suscripto</Th>
					<Th>Usuario</Th>
					<Th>Nombre</Th>
					<Th>Ben(%)</Th>
					<Th>Secciones</Th>
				</Tr>
			</Thead>
			<Tbody>
				{
					donations.length > 0 ?
						donations.map((donated, index) => {

							return (

								<Tr key={index}>
									<Td><Moment format="YYYY-MM-DD" utc>{donated.updated_at}</Moment></Td>									
									<Td>{donated.usuario}</Td>
									<Td>{donated.nombre} {donated.apellido}</Td>
									<Td>{donated.ben}</Td>
									<AccessSection donated={donated} />										
								</Tr>

							)

						})
					:null
				}
			</Tbody>
		</Table>

	)

};

export default BeneficiariesTable;