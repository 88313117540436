import React from 'react';
import ReturnStart from './returnStart.jsx';

const Index = () => {

	return(

		<ReturnStart />

	)

}

export default Index;