import { useContext } from 'react';
import VaultContext from '../pages/vaults/context/vaultContext';

const DefineMovementList = () => {

	const context = useContext(VaultContext);

	let movementsList;
	if(context.data.company == "heimdall"){
		movementsList = context.data.heimdall.movementsList;		
	}else{
		if(context.data.company == "nideport"){
			movementsList = context.data.nideport.movementsList;	
		}
	}
	
	return movementsList;

};

export { DefineMovementList };