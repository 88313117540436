import { 
	PROFILE_ASOCIADOS_GET_VAULTS_MOVEMENTS,
	PROFILE_VENDEDORES_GET_VENTAS,

} from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';

export const getVaultsMovements = createAction(	
	PROFILE_ASOCIADOS_GET_VAULTS_MOVEMENTS,
	(data) => apiPost(`${URL_ASGARD}/api/admin/asociados/getVaultsMovements`, data)
);

export const getVentas = createAction(	
	PROFILE_VENDEDORES_GET_VENTAS,
	(data) => apiPost(`${URL_ASGARD}/api/admin/vendedores/getVentas`, data)
);


