import React from 'react';
import BeneficiariesTable from './beneficiariesTable.jsx';

const Index = (props) => {
		
	return(

		<BeneficiariesTable />

	)

}

export default Index;