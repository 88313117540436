import React from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {   
    const { t } = useTranslation();
    return (
        <MainPrivateLayout>
            <div className="page-content-container">
                <span className="page-title">{t('private-home-title')}</span>
                <Row className="dashboard-row">

               
                    
                    
                </Row>
            </div>
        </MainPrivateLayout>
    );
};

export default Dashboard;