import React from 'react';
import ReportContable from './reportContable.jsx';

const Index = () => {

	return(

		<ReportContable />

	)

}

export default Index;