import React from 'react';
import MovementsTable from './movementsTable.jsx';

const Index = (props) => {
		
	return(

		<MovementsTable donated={props.donated} />

	)

}

export default Index;