import React from 'react';
import Back from './back.jsx';

const Index = (props) => {
		
	return(

		<Back path={props.path} handleClick={props.handleClick} />

	)

}

export default Index;