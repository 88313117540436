import React, { useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { Row, Card, Col, Button, Input} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getLogs } from '../../store/action-creators/AdminActions';
import { verifyRoles } from '../../functions/roles';


const Logs = () => {

	const dispatch = useDispatch();		
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);	
	const [logsList, setLogsList] = useState([]);	
	const [rolesHabilitado, setRolesHabilitado] = useState([1,17]);		
	const [searchedUser, setSearchedUser] = useState("");
	const [searchedStartDate, setSearchedStartDate] = useState("");
	const [searchedEndDate, setSearchedEndDate] = useState("");
	const [userError, setUserError] = useState("");	
	const [startDateError, setStartDateError] = useState("");	
	const [endDateError, setEndDateError] = useState("");	
	const [userFound, setUserFound] = useState("");
	
		
	const onSearch = () => {

		setUserError("");
		setStartDateError("");
		setEndDateError("");
		setUserFound("");

		let error = 0;

		if(!searchedUser){
			setUserError("Ingresar un usuario");
			error = 1;
		}

		if(!searchedStartDate){
			setStartDateError("Ingresar fecha");
			error = 1;
		}

		if(!searchedEndDate){
			setEndDateError("Ingresar fecha");
			error = 1;
		}
		
		if(error){
			return;
		}

		let data = Object.assign({}, { token: savedToken, usuario: searchedUser,startDate:searchedStartDate,endDate:searchedEndDate });
		dispatch(getLogs(data))
			.then((res) => {				
				setLogsList(res.payload.logs);
				setUserFound(searchedUser);			
			})
			.catch((err) => {
				if(err.code == 400){
					setUserError("El usuario no existe");	
					return;
				}
				if(err.code == 500){
					setUserError("El usuario no tiene logs");	
					return;
				}
			});

	};



	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-logs">

						<span className="page-title">Logs</span>

						<Card className="admin-main-card">

							<div className="filters">
								<Row>									
									<Col sm={3}>
								        <span>Usuario</span>
								        <Input
								        	value={searchedUser}										
											onChange={(event) => setSearchedUser(event.target.value)}
											autoComplete="new-password"
								        />
								        <span className="error">{userError}</span>
								    </Col>
								    <Col sm={3}>
								    	<span>Inicio</span>
								        <Input
								          type="date"
								          value={searchedStartDate}
										  onChange={(event) => setSearchedStartDate(event.target.value)}
										  autoComplete="new-password"
								        />
								        <span className="error">{startDateError}</span>
								    </Col>
								    <Col sm={3}>
								    	<span>Fin</span>
								        <Input
								          type="date"
								          value={searchedEndDate}
										  onChange={(event) => setSearchedEndDate(event.target.value)}
										  autoComplete="new-password"
								        />
								        <span className="error">{endDateError}</span>
								    </Col>
								    <Col sm={3}>
								    	<Button className="btn-buscar" onClick={onSearch}>Buscar</Button>
								    </Col>
								</Row>

							</div>

							<hr />

							<p className="userFound">Usuario: <span>{userFound}</span></p>

							{sectionRegistros ? (
								<div className="registros">
									<div className="list">										
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Created_at</Th>																									
													<Th>Type</Th>
													<Th>Message</Th>
												</Tr>
											</Thead>
											<Tbody>
												{logsList.length > 0 ?
													logsList.map((log, index) => {
														
														return (
															<Tr key={index}>
																<Td>
																	<Moment format="YYYY-MM-DD HH:mm" utc>
																		{log.created_at}
																	</Moment>
																</Td>																													
																<Td className={log.tipo}>{log.tipo}</Td>
																<Td>{log.msg}</Td>
															</Tr>
														);
														
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}						
							
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default Logs;
