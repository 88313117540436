import React, { createContext } from 'react';

const structContext = {
	GBMSold: "",	
	selectedVault: {},
	movementDetail: {},	
	heimdall: {
		vaults: {},
		gbmcoinEthBalance: "",
		gbmcoinEthUsd: "",
		gbmcoinTrxBalance: "",
		gbmcoinTrxUsd: "",
		movementsList: [],
	},
	nideport: {
		vaults: {},		
		movementsList: [],
	},
	company: "heimdall"
}  

const VaultContext = createContext();

export { structContext };
export default VaultContext;