import React from 'react';
import TableButton from './tableButton.jsx';

const Index = (props) => {
		
	return(

		<TableButton text={props.text} handleClick={props.handleClick} />

	)

}

export default Index;