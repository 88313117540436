import React from 'react';
import AccessSection from './accessSection.jsx';

const Index = (props) => {

	return(

		<AccessSection donated={props.donated} />

	)

}

export default Index;