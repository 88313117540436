import React from 'react';

// atoms
import ReportsList from '../../atoms/reportsList';


const ReportsStorage = () => {

	return(

		<ReportsList />

	)

}

export default ReportsStorage;