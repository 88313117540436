import React from 'react';
import styles from './buttonConfirm.module.scss';
import { Button } from 'reactstrap';

const ButtonConfirm = (props) => {	
	
	return(
		 
		<Button className={styles.button} onClick={props.handleClick}>{props.text}</Button>

	)

};

export default ButtonConfirm;