import React from 'react';
import FilterBetweenDates from './filterBetweenDates.jsx';

const Index = () => {

	return(

		<FilterBetweenDates />

	)

}

export default Index;