import React from 'react';
import ReportSalaryDetail from './reportSalaryDetail.jsx';

const Index = () => {

	return(

		<ReportSalaryDetail />

	)

}

export default Index;