import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Card, Container, Row, Col, InputGroup, Input, InputGroupText, InputGroupAddon  } from 'reactstrap';
import styles from './sendMoney.module.scss';
import MainPrivateLayout from '../../../../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../../../../functions/roles';
import '../../../../../../styles/styles.scss';
import Username from '../../../../../../components/username';
import Back from '../../../../../../components/back';
import ButtonConfirm from '../../../../../../components/buttonConfirm';
import { getOptions } from './sendMoney.js';
import Select from 'react-select'


const SendMoney = () => {

	const history = useHistory();
	const location = useLocation();
	const donated = location.state;

	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);

	const options = getOptions();

	const [ symbol, setSymbol ] = useState(options[0].symbol+' ('+options[0].protocol+')');		
	const [ option, setOption ] = useState(options[0]);	
	const [ monto, setMonto ] = useState("");	

	const onOption = option => {		
	    setOption(option);
	    setSymbol(option.symbol+' ('+option.protocol+')');
	};

	const handleBack = () => {
		history.push("/donations");
	}

	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />														
							<Container>
						      <Row>
						        <Col sm={3}></Col>
						        <Col sm={6} className={styles.frame}>
						        	<p className={styles.title}>enviar dinero</p>
						        	<Username text={donated.usuario} />
						        	<span className={styles.boxTitle}>Moneda</span>
						        	<Select
						              options={options}
						              placeholder="Seleccionar"			                    
						              value={option}
						              className={styles.select}
						              onChange={onOption}
						          	/>						          	
						          	<span className={styles.boxTitle}>Monto</span>
						          	<InputGroup>
								        <Input value={monto} onChange={(e) => setMonto(e.target.value) } />
								        <InputGroupAddon addonType="append">
								          <InputGroupText>{symbol}</InputGroupText>
								        </InputGroupAddon>
								    </InputGroup>
								    <br />
								    <div class={styles.devButton}>
								    	<ButtonConfirm text="enviar" />
								    </div>								    
						        </Col>
						        <Col sm={3}></Col>
						      </Row>
						    </Container>
						</Card>						
					</div>
				:null

			}	
		</MainPrivateLayout>

	)

};

export default SendMoney;