import React from 'react';
import ReportSalary from './reportSalary.jsx';

const Index = () => {

	return(

		<ReportSalary />

	)

}

export default Index;