import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import VaultContext from '../../context/vaultContext';
import QRCode from 'qrcode.react';
import styles from './qr.module.scss';

const Qr = () => {

	const context = useContext(VaultContext);

	return(

		<QRCode
			id={context.data.selectedVault.address}
			value={context.data.selectedVault.address}
			renderAs={'svg'}
			level={'H'}
			includeMargin={false}
			className={styles.qr}
		/>

	)

}

export default Qr;