import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getSalesByCode } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../functions/roles';


const SalesByCode = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);	
	const [usuarioTopSeller, setUsuarioTopSeller] = useState("");
	const [usuarioSeller, setUsuarioSeller] = useState("");
	const [topSalesList, setTopSalesList] = useState([]);
	const [salesList, setSalesList] = useState([]);
	const [totalSalesList, setTotalSalesList] = useState(0);
	const [totalTopSalesList, setTotalTopSalesList] = useState(0);
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
		

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getSalesByCode(data))
			.then((res) => {				

				setTopSalesList(res.payload.topSalesList);
				setTotalTopSalesList(res.payload.totalTopSalesList);

				setSalesList(res.payload.salesList);
				setTotalSalesList(res.payload.totalSalesList);

			})
			.catch((error) => {
				console.log(error);
			});
		
	}, []);


	return (
		<MainPrivateLayout>
			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-saleByCode">

						<span className="page-title">Ventas por código</span>

						<Card className="admin-main-card">

							{sectionRegistros ? (
								<div className="registros">
									<div className="topSales">
										<h1 className="title-section"><b>TOP VENDEDORES</b></h1>
										<div className="header">
											<Row>
												<Col sm={8}>
													<p className="title">Total cantidad vendida = {totalTopSalesList} GBM</p>
												</Col>
												<Col sm={4}>
													<InputGroup>
														<InputGroupAddon addonType="prepend" className="prepend">
															<InputGroupText>
																<BsSearch />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															value={usuarioTopSeller}
															placeholder='Buscar usuario'
															onChange={(event) => setUsuarioTopSeller(event.target.value)}
															autoComplete="new-password"
														/>
													</InputGroup>
												</Col>
											</Row>
											<hr />
										</div>

										<div className="topSales-list list">
											<Table className="table">
												<Thead>
													<Tr>											
														<Th>Vendedor</Th>
														<Th>Código</Th>
														<Th>Nombre</Th>
														<Th>Apellido</Th>
														<Th>Ventas totales</Th>
													</Tr>
												</Thead>
												<Tbody>
													{topSalesList.length > 0 ?
														topSalesList.map((sale, index) => {
															
															if (sale.vendedor.toLowerCase().indexOf(usuarioTopSeller.toLowerCase()) >= 0 || usuarioTopSeller === ''){														
																return (	
																	<Tr>														
																		<Td>{sale.vendedor}</Td>
																		<Td>{sale.code}</Td>
																		<Td>{sale.nombre}</Td>
																		<Td>{sale.apellido}</Td>
																		<Td>{sale.value} GBM</Td>
																	</Tr>
																);
															}
																
														  })
														: null
													}
												</Tbody>
											</Table>
										</div>						
									</div>

									<br />
									<br />
									<br />

									<div className="sales">
										<h1 className="title-section"><b>VENTAS</b></h1>
										<div className="header">
											<Row>
												<Col sm={8}>
													<p className="title">Total = {totalSalesList}</p>
												</Col>
												<Col sm={4}>
													<InputGroup>
														<InputGroupAddon addonType="prepend" className="prepend">
															<InputGroupText>
																<BsSearch />
															</InputGroupText>
														</InputGroupAddon>
														<Input
															value={usuarioSeller}
															placeholder='Buscar usuario'
															onChange={(event) => setUsuarioSeller(event.target.value)}
															autoComplete="new-password"
														/>
													</InputGroup>
												</Col>
											</Row>
											<hr />
										</div>

										<div className="sales-list list">
											<Table className="table">
												<Thead>
													<Tr>											
														<Th>Fecha</Th>
														<Th>Vendedor</Th>												
														<Th>Código</Th>
														<Th>Cantidad</Th>
														<Th>Precio (GBM)</Th>
														<Th>Comprador</Th>
														<Th>Detalle</Th>
													</Tr>
												</Thead>
												<Tbody>
													{salesList.length > 0 ?
														salesList.map((sale, index) => {
															
															if (sale.vendedor.toLowerCase().indexOf(usuarioSeller.toLowerCase()) >= 0 || usuarioSeller === ''){														
																return (	
																	<Tr>														
																		<Td>
																			<Moment format="YYYY-MM-DD" utc>
																				{sale.created_at}
																			</Moment>
																		</Td>
																		<Td>{sale.vendedor}</Td>
																		<Td>{sale.code}</Td>
																		<Td>{sale.value} GBM</Td>
																		<Td>u$ {sale.gbmusd}</Td>
																		<Td>{sale.comprador}</Td>
																		<Td>
																			<a href={sale.tx_url} target="_blank">
																				detalle
																			</a>
																		</Td>
																	</Tr>
																);
															}
																
														  })
														: null
													}
												</Tbody>
											</Table>
										</div>						
									</div>

									
								</div>
							) : null}

						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default SalesByCode;
