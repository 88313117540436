import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getHitosPerUser } from '../../../../../../../store/action-creators/AdminActions';
import styles from './hitosTable.module.scss';
import { getOptions, nameStatus } from './hitosTable.js';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import Select from 'react-select'
import ActionsHito from './actionsHito';
import TableButton from '../../../../../../../components/tableButton';

const HitosTable = (props) => {	

	const history = useHistory();
	const options = getOptions();
	const dispatch = useDispatch();		
	const savedToken = sessionStorage.getItem('token');	
	const [ option, setOption ] = useState("");
	const [ hitos, setHitos ] = useState([]);

	  
	useEffect(() => {
		
		const data = Object.assign({}, { token: savedToken, id_user: props.donated.id_user });
		dispatch(getHitosPerUser(data))
			.then((res) => {				
				setHitos(res.payload.hitos);
			})
			.catch((error) => {
				
			});
		
	},[]);	

	

	const onOption = option => {		
	    setOption(option);   
	};

	
	const handleAccess = (option) => {
						
		if(option){			
			const pathname = window.location.pathname;
			history.push(pathname+'/actions');
		}							

	}
	
	return(
		 
		<Table className="table">
			<Thead>
				<Tr>
					<Th>Creado</Th>
					<Th>Num.</Th>
					<Th>Descripción</Th>
					<Th>Estado</Th>
					<Th>Acciones</Th>
				</Tr>
			</Thead>
			<Tbody>
				{
					hitos.length > 0 ?
						hitos.map((hito, index) => {

							return (
								<Tr key={index}>									
									<Td><Moment format="YYYY-MM-DD" utc>{hito.updated_at}</Moment></Td>									
									<Td>{hito.num}</Td>
									<Td>{hito.description}</Td>
									<Td>{nameStatus(hito.status)}</Td>
									<ActionsHito hito={hito} />																						
								</Tr>

							)
						})
					:null
				}
			</Tbody>
		</Table>

	)

};

export default HitosTable;