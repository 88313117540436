import React, { Component } from 'react';
import './styles/app.scss';
import MainRouter from './config/router/MainRouter';

class App extends Component {

  render() {

    return (
      <MainRouter />
    );
  }

}

export default App;