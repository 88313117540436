import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Qr from './qr.jsx';

const Index = () => {

	return(

		<Qr />

	)

}

export default Index;