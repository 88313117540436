import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col, InputGroup, Input, InputGroupText, InputGroupAddon  } from 'reactstrap';
import styles from './newDonation.module.scss';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import { useSelector, useDispatch } from 'react-redux';
import { verifyRoles } from '../../../functions/roles';
import '../../../styles/styles.scss';
import Back from '../../../components/back';
import ButtonConfirm from '../../../components/buttonConfirm';
import { searchUser, asignDonation } from '../../../store/action-creators/AdminActions';

const NewDonation = () => {

	const history = useHistory();

	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');
	const dispatch = useDispatch();

	const [ usuario, setUsuario ] = useState("");			
	const [ ben, setBen ] = useState("");	
	const [ errorUser, setErrorUser ] = useState("");
	const [ errorBen, setErrorBen ] = useState("");	
	const [ result, setResult ] = useState("");
	const [ successUser, setSuccessUser ] = useState("");
	const [ errorGeneral, setErrorGeneral ] = useState("");


	const onSearchUser = (usuario) => {

		let error = 0;

		if(!usuario){
			error = 1;
			setSuccessUser("");
		}

		if(error){
			return;
		}

		setErrorUser("");		
		
		const data = Object.assign({}, { token: savedToken, usuario: usuario });
		dispatch(searchUser(data))
			.then((res) => {				
				setSuccessUser("El usuario "+res.payload.usuario+" existe");				
			})
			.catch((error) => {
				setErrorUser("El usuario "+usuario+" NO existe");
			});
		
	}	

	const onAsign = () => {

		let error = 0;
		const regex = /^[0-9.]+$/;

		if(!usuario){
			error = 1;
			setErrorUser("Ingresar usuario");
		}else{
			setErrorUser("");
		}

		if(!ben){
			error = 1;
			setErrorBen("Ingresar beneficio");
		}else{
			setErrorBen("");
		}

		if(!regex.test(ben)){
			error = 1;
			setErrorBen("El beneficio debe ser un número positivo");
		}

		if(!successUser){
			error = 1;
			setErrorUser("Debe buscar un usuario válido");
		}

		if(error){
			return;
		}		
		
		
		const data = Object.assign({}, { token: savedToken, usuario: usuario, ben: ben });
		dispatch(asignDonation(data))
			.then((res) => {				
				setResult("El sistema asignó al usuario "+usuario+" "+ben+"%");
				setSuccessUser("");
				setUsuario("");
				setBen("");
			})
			.catch((error) => {
				if(error.code === 1){
					setErrorGeneral("El usuario "+usuario+" no existe");
					return;
				}
				if(error.code === 2){
					setErrorGeneral("Beneficio invalido");
					return;
				}
				if(error.code === 3){
					setErrorGeneral("El usuario "+usuario+" ya tiene una donación activa");
					return;
				}
			});
		

	}

	const handleBack = () => {
		history.push("/donations");
	}

		
	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />
							<Container>
						      <Row>
						        <Col sm={3}></Col>
						        <Col sm={6} className={styles.frame}>
						        	<p className={styles.title}>nueva donación</p>						        	
						        	<span className={styles.boxTitle}>Usuario</span>
						        	<InputGroup>
								        <Input value={usuario} onChange={(e) => setUsuario(e.target.value) } />
								        <InputGroupAddon addonType="append">
								          <InputGroupText className={styles.search} onClick={() => onSearchUser(usuario)}>buscar</InputGroupText>
								        </InputGroupAddon>
								    </InputGroup>
								    <span className={styles.errorField}>{errorUser}</span>
								    <span className={styles.successUser}>{successUser}</span>
						          	<span className={styles.boxTitle}>Beneficio(%)</span>
						          	<InputGroup>
								        <Input value={ben} onChange={(e) => setBen(e.target.value) } />
								        <InputGroupAddon addonType="append">
								          <InputGroupText>%</InputGroupText>
								        </InputGroupAddon>
								    </InputGroup>
								    <span className={styles.errorField}>{errorBen}</span>
								    <br />
								    <p className={styles.result}>{result}</p>
								    <p className={styles.errorGeneral}>{errorGeneral}</p>
								    <div className={styles.devButton}>
								    	<ButtonConfirm text="asignar" handleClick={onAsign} />
								    </div>								    
						        </Col>
						        <Col sm={3}></Col>
						      </Row>
						    </Container>
						</Card>						
					</div>
				:null

			}	
		</MainPrivateLayout>

	)

};

export default NewDonation;