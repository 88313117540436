import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';

const MobileSidebarItem = (props) => {
    let history = useHistory();
    const { iconPath, label, route, handleClick } = props;

    const goTo = () => {
        if (route) {
            history.push(route);
        } else {
            handleClick()
        }
    }

    return (
        <Row className="mobile-sidebar-item" onClick={() => goTo()}>
            <Col xs={3} className="mobile-sidebar-item-icon">
                <img
                    src={process.env.PUBLIC_URL + iconPath}
                    width="auto"
                    height="20"
                    alt=""
                    loading="lazy"
                />
            </Col>
            <Col xs={9} className="mobile-sidebar-item-label">
                <span>{label}</span>
            </Col>
        </Row>
    );

}

export default MobileSidebarItem;