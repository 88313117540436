import React, { useState } from 'react';
import styles from './beneficiaries.module.scss';
import SubTitle from '../../../components/subTitle';
import BeneficiariesTable from './beneficiariesTable';

const Beneficiaries = () => {
					
	return(
		 
		<div>
			<SubTitle text="lista de beneficiarios" />
			<BeneficiariesTable />			
		</div>

	)

};

export default Beneficiaries;