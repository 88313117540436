import React from 'react';
import MovementDetail from './movementDetail.jsx';

const Index = () => {

	return(

		<MovementDetail />

	)

}

export default Index;