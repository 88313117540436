import React from 'react';
import Hitos from './hitos.jsx';

const Index = (props) => {

	return(

		<Hitos donated={props.donated} />

	)

}

export default Index;