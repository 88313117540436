import React, { useEffect } from "react";

function HexagonPhoto(props) {

    const { width, imagePath, id ,image } = props;

    let height;
    
    if (typeof width === "number") {
        height = width * 1.095;
    } else {
        let widthPercent = parseInt(width, 10);
        height = `${widthPercent * 1.095}%`;
    }

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${200} ${219}`}            
        >
            <defs>
                <linearGradient
                    id={`prefix__${id}`}
                    gradientUnits="userSpaceOnUse"
                    x1={206.058}
                    y1={91.64}
                    x2={-1.844}
                    y2={132.05}
                >
                    <stop offset={0} stopColor="#f9f3ad" />
                    <stop offset={0.271} stopColor="#f0c628" />
                    <stop offset={0.341} stopColor="#f9f3ad" />
                    <stop offset={0.439} stopColor="#e0b517" />
                    <stop offset={0.69} stopColor="#f0c628" />
                    <stop offset={0.769} stopColor="#fbef98" />
                    <stop offset={0.839} stopColor="#f0c628" />
                    <stop offset={1} stopColor="#e0b517" />
                </linearGradient>

                <clipPath id={`clip-${id}`}>
                    <path
                        fill="black"
                        d="M180 67l0 64c0,12 -6,23 -17,29l-56 32 0 0c-10,6 -23,6 -34,0l-56 -32 0 0c-10,-6 -17,-17 -17,-29l0 -64c0,-6 2,-12 5,-17l0 0c3,-5 7,-9 12,-12l56 -32c10,-6 23,-6 34,0l0 0 56 32 0 0c10,6 17,17 17,29z"
                    />
                </clipPath>
            </defs>

            <g transform="translate(12.5, 12.5)" >
                <image
                    x="0"
                    y="0"
                    width="95%"
                    height="100%"
                    opacity="1"
                    preserveAspectRatio="xMidYMid slice"
                    href={image}
                    clipPath={`url(#clip-${id})`}
                />
            </g>

            <path
                d="M102 223c6 0 13-2 19-5l63-36c6-3 11-8 14-14s5-12 5-19V76c0-7-2-13-5-19s-8-10-14-14L121 7c-6-3-12-5-19-5S89 4 83 7L20 43c-6 3-11 8-14 14S1 69 1 76v73c0 7 2 13 5 19s8 11 14 14l63 36c6 3 12 5 19 5zM27 54l63-36c4-2 8-3 12-3s9 1 12 3l63 36c4 2 7 5 9 9s3 8 3 12v73c0 4-1 9-3 12-2 4-5 7-9 9l-63 36c-4 2-8 3-12 3s-9-1-12-3l-63-36c-4-2-7-5-9-9s-3-8-3-12V75c0-4 1-9 3-12 2-4 5-7 9-9z"
                fill={`url(#prefix__${id})`}
            />

            <path
                d="M203 75v73c0 7-2 13-5 19s-8 11-14 14l-63 36h0c-6 3-12 5-19 5s-13-2-19-5l-63-36h0c-6-3-11-8-14-14s-5-12-5-19V75c0-7 2-13 5-19h0c3-6 8-11 14-14L83 6c6-3 12-5 19-5s13 2 19 5h0l63 36h0c6 3 11 8 14 14s5 12 5 19zm-13 73V75c0-4-1-9-3-12-2-4-5-7-9-9h0l-63-36h0c-4-2-8-3-12-3s-9 1-12 3L28 54c-4 2-7 5-9 9h0c-2 4-3 8-3 12v73c0 4 1 9 3 12 2 4 5 7 9 9h0l63 36c4 2 8 3 12 3s9-1 12-3h0l63-36c4-2 7-5 9-9s3-8 3-12z"
                fill="none"
                stroke="#f5f2a9"
                strokeWidth={2.11}
            />
        </svg>
    )
}

export default HexagonPhoto;