const nameOperation = (props) => {

	switch (props) {
		case 1:
			return 'Depósito';
		case 2:
			return 'Retiro';
	}	
	
}


const nameVault = (props) => {
		
	switch (props) {
		case 1:
			return 'Interna';
		case 2:
			return 'Externa';
		case 3:
			return 'Corporativa';
	}
		
}

const nameReason = (props) => {

	switch (props) {
		case 2:
			return 'Retiro';
		case 3:
			return 'Compra';
		case 4:
			return 'Intercambio';			
		case 100:
			return 'Carga';
		case 101:
			return 'Retorno';
		case 102:
			return 'SendTesting';
		case 103:
			return 'Pago a Proveedor';
		case 104:
			return 'Pago por Recompensas';
		case 105:
			return 'Sueldo';
		case 106:
			return 'Otro';
	}
}


export { nameOperation, nameVault, nameReason };