import React from 'react';
import Reports from './reports.jsx';

const Index = () => {

	return(

		<Reports />

	)

}

export default Index;