import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Label, Row, Col, Button } from 'reactstrap';
import VaultContext from '../../context/vaultContext';
import styles from './declare.module.scss';
import { nameVault, idVault, showSymbolFee, getTypeOptions, nameOperation } from './declare.js';
import { sendDeclarationDeposit, sendDeclarationWithdrawal } from '../../../../store/action-creators/VaultsActions';
import moment from 'moment';
import Select from 'react-select'


const Declare = () => {

  useEffect(() => {
    scroll.scrollTo(170);
  }, []);
     		  
  const context = useContext(VaultContext);
  const dispatch = useDispatch();
  const savedToken = sessionStorage.getItem('token');
  const [ sectionForm, setSectionForm ] = useState(true);   
  const [ comment, setComment ] = useState("");      
  const [ response, setResponse ] = useState("");
  const [ error, setError ] = useState("");
  const [ message, setMessage ] = useState("");
  const [ selectedtype, setSelectedType ] = useState("");  

  const typeOptions = getTypeOptions(context.data.movementDetail.operation); 

  const onSetSelectedType = selectedtype => { 
    setSelectedType(selectedtype);
  }


  const onSendDeclaration = () => {

    if(!selectedtype){
      setError("Seleccionar Tipo");
      return;
    }

    if(!comment){
      setError("Ingresar Comentario");
      return;
    }

    setError("");
    setMessage("Procesando...");

    // Se declara un depósito

    if(context.data.movementDetail.operation == 1){

      const data = Object.assign({}, { 
      token: savedToken, 
      sent_at: context.data.movementDetail.updated_at,
      vault: context.data.movementDetail.vault,
      value: context.data.movementDetail.value,
      symbol: context.data.movementDetail.symbol,
      protocol: context.data.movementDetail.protocol,
      name: context.data.movementDetail.name,
      txHash: context.data.movementDetail.tx_hash,  
      txUrl: context.data.movementDetail.tx_url,    
      destAddress: context.data.movementDetail.dest_address,
      comment: comment
    });

    dispatch(sendDeclarationDeposit(data))
      .then((res) => {                       

          setResponse("El movimiento se declaró correctamente");          
          setMessage("");
          setComment("");

          setSectionForm(false);          
          scroll.scrollTo(170);

          context.setData({ ...context.data,movementsList:res.payload.getMovements, movementDetail: res.payload.movementDetail });

      })
      .catch((err) => { });   

    }else{
      if(context.data.movementDetail.operation == 2){
          
        const data = Object.assign({}, { 
          token: savedToken, 
          sent_at: context.data.movementDetail.updated_at,
          tipo: selectedtype.value,
          vault: context.data.movementDetail.vault,
          value: context.data.movementDetail.value,
          symbol: context.data.movementDetail.symbol,
          protocol: context.data.movementDetail.protocol,
          name: context.data.movementDetail.name,
          txHash: context.data.movementDetail.tx_hash,
          txUrl: context.data.movementDetail.tx_url,
          destAddress: context.data.movementDetail.dest_address,
          fee: context.data.movementDetail.fee, 
          feeSymbol: showSymbolFee(context.data.movementDetail.symbol),          
          comment: comment
        });

        dispatch(sendDeclarationWithdrawal(data))
          .then((res) => {                       

              setResponse("El movimiento se declaró correctamente");
              setMessage("");
              setSelectedType("");             
              setComment("");     

              setSectionForm(false);
              scroll.scrollTo(170);         

              context.setData({ ...context.data,movementsList:res.payload.getMovements, movementDetail: res.payload.movementDetail });

          })
          .catch((err) => { });

      }
    }

    

  }

  return (
  		
		<div className={styles.section}>
			<p className={styles.back}><Link to="/vaults/heimdall/movementDetail">Volver </Link></p>
  		
      <div className={styles.form}>

        <h1 className={styles.title}>Declarar movimiento</h1>
        <hr />

        { 
          sectionForm ?
          <div>
            <Row>
              <Col>
                <Label>Bóveda</Label>
                <Input type="text" value={nameVault(context.data.movementDetail.vault)} readOnly />
              </Col>
              <Col>
                <Label>Moneda</Label>
                <Input type="text" value={context.data.movementDetail.name} readOnly />
              </Col>
            </Row>

            <br />

            <Row>
              <Col>
                <Label>Tipo</Label>
                <Select
                    options={typeOptions}
                    value={selectedtype}            
                    onChange={onSetSelectedType}
                    className={styles.select}
                />
              </Col>
              <Col>
                <Label>Operación</Label>
                <Input type="text" value={nameOperation(context.data.movementDetail.operation)} readOnly />
              </Col>
            </Row>       

            <Row>
              <Col>
                <Label>Cantidad</Label>
                <InputGroup className={styles.amount}>          
                  <Input value = {context.data.movementDetail.value} readOnly />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{context.data.movementDetail.symbol}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>          
              <Col>           
                <Label>Tx Date</Label>
                <Input type="text" value={moment(context.data.movementDetail.updated_at).format('YYYY-MM-DD hh:mm:ss')} readOnly />
              </Col>
            </Row>            

            <br />        
            <Label>Tx Hash</Label>
            <Input value = {context.data.movementDetail.tx_hash} readOnly />

            <br />
            <Label>Comentario</Label>
            <Input type="textarea" row={5} value = {comment} onChange = { (e) => setComment(e.target.value) } />

            <br />            
            <p className={styles.error}>{error}</p>
            <p className={styles.message}>{message}</p>

            <div className="text-center">
              <Button className={styles.btnDeclare} onClick={onSendDeclaration}>Declarar movimiento</Button>
            </div>
          </div>
          :null
        }
        <p className={styles.response}>{response}</p>
      </div>

  	</div>  		
	  
   )

}

export default Declare;
