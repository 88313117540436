import React from 'react';
import Username from './username.jsx';

const Index = (props) => {
		
	return(

		<Username text={props.text} />

	)

}

export default Index;