const getVaultOptions = () => {

	const options = [
	    { value: '', label: 'Ambos' },
	    { value: '1', label: 'Depósito' },
	    { value: '2', label: 'Retiro' }
	]

	return options;

}

export { getVaultOptions };

