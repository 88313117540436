import { 
	EDIT_USERS_ROLE, 
	GET_ADMIN, 
	GET_ROLES, 
	GET_USERS, 
	SUSPEND_USER,
	SUSPEND_ADMIN,
	GET_SECTION_VAULTS,
	GET_ADMINS_PER_ROLE,
	GET_COUPON,
	ASSIGN_COUPON_CODE_USER,	
	GENERATE_COUPON_CODE,	
	GET_SALE_BY_CODE,
	GET_ADMINS_ENABLED,
	ENABLE_EMAIL,
	DEL_ADMINS_ENABLED,
	GET_USER_MOVEMENTS,	
	GET_EARNINGS_ASSIGNED,
	ASSIGN_EARNINGS,
	DEL_EARNINGS,
	EDIT_ROLES,	
	SET_ACTION_USER,
	DISABLE_GOOGLE_2FA_USER,
	DELETE_KYC_USER,	
	UNLOCK_USER,
	USERS_MOVEMENTS,
	USERS_ADDRESSES,
	RESEND_EMAIL,
	GET_LOGS,
	GET_SECTION_ACCOUNTING,
	ASIGN_PASSWORD_USER,
	SEARCH_USER,
	ASIGN_DONATION,
	GET_DONATIONS,
	GET_HITOS_PER_USER,
	ADD_HITO,	
	GET_DONATED_MOVEMENTS,
	GET_DEPOSITS_LINKS,
	GENERATE_DEPOSIT_LINK
} from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';

export const getAdmins = createAction(
	GET_ADMIN, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/getAdmins`, data)
);

export const getRoles = createAction(
	GET_ROLES, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/getRoles`, data)
);

export const getUsers = createAction(
	GET_USERS, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/getUsers`, data)
);

export const suspendUser = createAction(
	SUSPEND_USER, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/suspendUser`, data)
);

export const suspendAdmin = createAction(
	SUSPEND_ADMIN, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/suspendAdmin`, data)
);

export const editUsers = createAction(
	EDIT_USERS_ROLE, 
	(data) => apiPost(`${URL_ASGARD}/api/admin/editRoles`, data)
);

export const getAdminsPerRole = createAction(
    GET_ADMINS_PER_ROLE,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getAdminsPerRole`, data)
);

export const getCoupon = createAction(
    GET_COUPON,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getCoupon`, data)
);

export const assignCouponCodeUser = createAction(
    ASSIGN_COUPON_CODE_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/assignCouponCodeUser`, data)
);

export const generateCouponCode = createAction(
    GENERATE_COUPON_CODE,
    (data) => apiPost(`${URL_ASGARD}/api/admin/generateCouponCode`, data)
);

export const getSalesByCode = createAction(
    GET_SALE_BY_CODE,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getSalesByCode`, data)
);

export const getAdminsEnabled = createAction(
    GET_ADMINS_ENABLED,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getAdminsEnabled`, data)
);

export const enableEmail = createAction(
    ENABLE_EMAIL,
    (data) => apiPost(`${URL_ASGARD}/api/admin/enableEmail`, data)
);

export const delAdminsEnabled = createAction(
    DEL_ADMINS_ENABLED,
    (data) => apiPost(`${URL_ASGARD}/api/admin/delAdminsEnabled`, data)
);

export const getUserMovements = createAction(
    GET_USER_MOVEMENTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getUserMovements`, data)
);

export const getEarningsAssigned = createAction(
    GET_USER_MOVEMENTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getEarningsAssigned`, data)
);

export const assignEarnings = createAction(
    GET_USER_MOVEMENTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/assignEarnings`, data)
);

export const delEarnings = createAction(
    DEL_EARNINGS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/delEarnings`, data)
);

export const editRoles = createAction(
    EDIT_ROLES,
    (data) => apiPost(`${URL_ASGARD}/api/admin/editRoles`, data)
);

export const disableGoogle2faUser = createAction(
    DISABLE_GOOGLE_2FA_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/disableGoogle2faUser`, data)
);

export const deleteKycUser = createAction(
    DELETE_KYC_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/deleteKycUser`, data)
);

export const unlockUser = createAction(	
	UNLOCK_USER,
	(data) => apiPost(`${URL_ASGARD}/api/admin/unlockUser`, data)
);

export const getUsersMovements = createAction(
    USERS_MOVEMENTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/users/getUsersMovements`, data)
);

export const getUsersAddresses = createAction(
    USERS_ADDRESSES,
    (data) => apiPost(`${URL_ASGARD}/api/admin/users/getUsersAddresses`, data)
);

export const resendEmail = createAction(
    RESEND_EMAIL,
    (data) => apiPost(`${URL_ASGARD}/api/admin/resendEmail`, data)
);

export const getLogs = createAction(
    GET_LOGS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getLogs`, data)
);

export const getSectionAccounting = createAction(
    GET_SECTION_ACCOUNTING,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getSectionAccounting`, data)
);

export const asignPasswordUser = createAction(
    ASIGN_PASSWORD_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/asignPasswordUser`, data)
);

export const searchUser = createAction(
    SEARCH_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/searchUser`, data)
);

export const asignDonation = createAction(
    ASIGN_DONATION,
    (data) => apiPost(`${URL_ASGARD}/api/admin/asignDonation`, data)
);

export const getDonations = createAction(
    GET_DONATIONS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getDonations`, data)
);

export const getHitosPerUser = createAction(
    GET_HITOS_PER_USER,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getHitosPerUser`, data)
);

export const addHito = createAction(
    ADD_HITO,
    (data) => apiPost(`${URL_ASGARD}/api/admin/addHito`, data)
);

export const getDonatedMovements = createAction(
    GET_DONATED_MOVEMENTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getDonatedMovements`, data)
);

export const getDepositsLinks = createAction(
    GET_DEPOSITS_LINKS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getDepositsLinks`, data)
);

export const generateDepositLink = createAction(
    GENERATE_DEPOSIT_LINK,
    (data) => apiPost(`${URL_ASGARD}/api/admin/generateDepositLink`, data)
);