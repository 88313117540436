const baseUrl = process.env.PUBLIC_URL;

export const ROUTE_HOME = `${baseUrl}/`;

export const ROUTE_DASHBOARD = `${baseUrl}/dashboard`;
export const ROUTE_NEWS = `${baseUrl}/news`;
export const ROUTE_ADD_NEWS = `${baseUrl}/addnews`;
export const ROUTE_SEARCH_NEWS = `${baseUrl}/searchnews`;

export const ROUTE_CODIGOSDEDESCUENTO = `${baseUrl}/codigosdedescuento`;

// Login
export const ROUTE_LOGIN = `${baseUrl}/login`;
export const ROUTE_LOGIN_EMAIL = ROUTE_LOGIN + '/email';
export const ROUTE_LOGIN_EMAIL_AUTH = `${baseUrl}/authorize-access/:access_token`;
export const ROUTE_LOGIN_FORGOT_PASSWORD = ROUTE_LOGIN + '/forgot-password';

// password reset
export const ROUTE_LOGIN_PASSWORD_RESET = `${baseUrl}/password-reset/:reset_token`;

// Signup
export const ROUTE_SIGNUP = `${baseUrl}/signup`;
export const ROUTE_SIGNUP_EMAIL = ROUTE_SIGNUP + '/email';
export const ROUTE_SIGNUP_SMS = ROUTE_SIGNUP + '/sms';
export const ROUTE_SIGNUP_EMAIL_CONFIRMATION = ROUTE_SIGNUP + '/email_confirm/:access_token';

export const ROUTE_BALANCE = `${baseUrl}/balance`;
export const ROUTE_BOLSA = `${baseUrl}/bolsa`;
export const ROUTE_BOLSA_BUY = `${baseUrl}/bolsaBuy`;
export const ROUTE_BOLSA_BUY_CREDITCARD = ROUTE_BOLSA + '/buy-credit-card';
export const ROUTE_BOLSA_BUY_CRYPTO = ROUTE_BOLSA + '/Buycrypto';
export const ROUTE_BOLSA_BUY_PURCHASE_DETAIL = ROUTE_BOLSA + '/buy-purchase-detail';
export const ROUTE_BOLSA_TRANSACTION_COMPLETE = ROUTE_BOLSA + '/transaction-complete';
export const ROUTE_TIERRA = `${baseUrl}/tierra`;

// Administradores

export const ROUTE_ADMINISTRADORES = `${baseUrl}/admin`;


// vendedores

export const ROUTE_SELLERS = `${baseUrl}/sellers`;

// movimientos

export const ROUTE_MOVIMIENTOS = `${baseUrl}/movimientos`;
export const ROUTE_USUARIOS = `${baseUrl}/users`;
export const ROUTE_USERS_MOVEMENTS = `${baseUrl}/usersMovements`;
export const ROUTE_USERS_ADDRESSES = `${baseUrl}/usersAddresses`;
export const ROUTE_MOVIMIENTOS_DETALLE = `${baseUrl}/movementDetails`;

export const ROUTE_ROLES = `${baseUrl}/roles`;
export const ROUTE_PROFILE = `${baseUrl}/profile`;

// socios
export const ROUTE_SOCIOS = `${baseUrl}/donations`;


export const ROUTE_SALES_BY_CODE = `${baseUrl}/salesByCode`;
export const ROUTE_VAULTS = `${baseUrl}/vaults/heimdall`;
export const ROUTE_ENABLE_ADMIN = `${baseUrl}/enableAdmin`;
export const ROUTE_ASSIGN_EARNINGS = `${baseUrl}/assignEarnings`;
export const ROUTE_INTERNAL_EARNINGS = `${baseUrl}/internalEarnings`;
export const ROUTE_DONATIONS = `${baseUrl}/donations`;
export const ROUTE_DONATIONS_HITOS = `${baseUrl}/donations/hitos`;
export const ROUTE_DONATIONS_MOVEMENTS = `${baseUrl}/donations/movements`;
export const ROUTE_DONATIONS_SENDMONEY = `${baseUrl}/donations/sendmoney`;
export const ROUTE_DONATIONS_NEW_DONATION = `${baseUrl}/donations/newDonation`;
export const ROUTE_DONATIONS_HITOS_NEW_HITO = `${baseUrl}/donations/hitos/newHito`;
export const ROUTE_DONATIONS_MANAGE_DONATED = `${baseUrl}/donations/management`;
export const ROUTE_DEPOSITS_LINKS = `${baseUrl}/depositsLinks`;
export const ROUTE_DEPOSITS_LINKS_NEW_DEPOSIT_LINK= `${baseUrl}/depositsLinks/newDepositLink`;

export const ROUTE_PROFILES_ASOCIADOS_MOVEMENTS = `${baseUrl}/profiles/asociados/movements`;
export const ROUTE_PROFILES_VENDEDORES_VENTAS = `${baseUrl}/profiles/vendedores/ventas`;

export const ROUTE_LOGS = `${baseUrl}/logs`;
export const ROUTE_ACCOUNTING = `${baseUrl}/accounting`;
export const ROUTE_REPORTS = `${baseUrl}/reports`;
