import React, { useState, useContext } from 'react';
import { Table } from 'reactstrap';
import ReportsContext from '../../context/reportsContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Moment from 'react-moment';

// styles
import styles from './reportSalaryDetail.module.scss';

const ReportSalaryDetail = () => {

	const context = useContext(ReportsContext);

	return(

		<div>

			<ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="report_salary"
                sheet="report_sale"
                buttonText="Descargar a Excel"
            />


			<Table responsive className={styles.table} id="table-to-xls">
			  <thead>
			    <tr>
			      <th>Fecha</th>
			      <th>Usuario</th>
			      <th>Cantidad</th>
			      <th>Moneda</th>
			      <th>Protocolo</th>
			      <th>Dirección pública</th>
			    </tr>
			  </thead>
			  <tbody>
			   	{context.data.reportSalary.length > 0 ?
					context.data.reportSalary.map((report, index) => {
						return(

							<tr key={index}>
								<td><Moment format="YYYY-MM-DD HH:mm" utc>{report.created_at}</Moment></td>
								<td>{report.usuario}</td>
								<td>{report.value}</td>
								<td>{report.symbol}</td>
								<td>{report.protocol}</td>
								<td>{report.address}</td>
							</tr>

						);
					})
					:null	
				}
			  </tbody>
			</Table>

		</div>

	);

}

export default ReportSalaryDetail;