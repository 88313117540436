import React from 'react';
import HitosTable from './hitosTable.jsx';

const Index = (props) => {
		
	return(

		<HitosTable donated={props.donated} />

	)

}

export default Index;