import React from 'react';
import ReportWallet from './reportWallet.jsx';

const Index = () => {

	return(

		<ReportWallet />

	)

}

export default Index;