import React from 'react';
import Button1 from './button1.jsx';

const Index = (props) => {
		
	return(

		<Button1 text={props.text} handleClick={props.handleClick} />

	)

}

export default Index;