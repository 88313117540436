import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import { Label, FormGroup, Input, FormFeedback, Col, Row } from "reactstrap";
import NewsButton from "../../components/NewsButton";
import { ROUTE_ADD_NEWS } from "../../constants/routes";
import { setPropsToInitialValues } from "../../HOCs/setPropsToInitialValues";

const validate = (values) => {
  const error = {};
  /*
  if (!values.search) {
    error.search = "Por favor ingrese un criterio de búsqueda.";
  }*/

  return error;
};

export class SearchnewsForm extends Component {
  constructor(props) {
    super(props);
  }

  renderMyField = ({
    input,
    meta,
    label,
    accept,
    type,
    name,
    placeholder,
    value,
  }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input
          {...input}
          id={name}
          type={type ? type : "text"}
          accept={accept ? accept : null}
          placeholder={placeholder}
          invalid={meta.touched && !!meta.error}
          className="contacto-input"
        />
        {meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  render() {
    const {
      handleAddNews,
      handleSubmit,
      submitting,
      pristine,
      submitSucceeded,
      error,
    } = this.props;

    return (
      <div className="contacto-form">
        <Row className="contacto-s1-row">
          <Col xs={12} sm={12} md={6} className="contacto-s1-col">
            <Field
              label="Buscar"
              name="search"
              placeholder="Criterio de búsqueda"
              component={this.renderMyField}
              withFocus={true}
            />
          </Col>
          <Col xs={12} sm={6} md={2}>
            <NewsButton
              label="Buscar"
              className="news-button"
              customStyle={{
                width: "auto",
                height: "0.5rem",
                marginTop: "2rem",
                fontSize: "1rem",
                fontWeight: "500",
              }}
              handleClick={handleSubmit}
              disabled={submitting}
            />
          </Col>
          <Col xs={12} sm={6} md={2}>
            <NewsButton
              label="Nueva noticia"
              color="success"
              className="news-button"
              customStyle={{
                width: "auto",
                height: "0.5rem",
                marginTop: "2rem",
                fontSize: "1rem",
                fontWeight: "500",
              }}
              handleClick={handleAddNews}
              disabled={submitting}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default setPropsToInitialValues(
  reduxForm({ form: "SearchnewsForm", touchOnBlur: false, validate })(
    SearchnewsForm
  )
);
