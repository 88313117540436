import { useContext } from 'react';
import VaultContext from '../pages/vaults/context/vaultContext';

const DefineVaults = () => {

	const context = useContext(VaultContext);	

	let vaults;
	if(context.data.company == "heimdall"){
		vaults = context.data.heimdall.vaults;
	}else{
		if(context.data.company == "nideport"){
			vaults = context.data.nideport.vaults;	
		}
	}	

	return vaults;

};

export { DefineVaults };