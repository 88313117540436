import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './movementsTable.module.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import { getDonatedMovements } from '../../../../../../../store/action-creators/AdminActions';

const MovementsTable = (props) => {	
		console.log(props);
	const savedToken = sessionStorage.getItem('token');	
	const dispatch = useDispatch();
	const [ movements, setMovements ] = useState([]);

	useEffect(() => {

		const data = Object.assign({}, { token: savedToken, id_user: props.donated.id_user });
		dispatch(getDonatedMovements(data))
			.then((res) => {				
				setMovements(res.payload.movements);
			})
			.catch((error) => {
				
			});

	},[]);

	return(
		 
		<Table className="table">
			<Thead>
				<Tr>
					<Th>Fecha</Th>
					<Th>Concepto</Th>
					<Th>Monto</Th>
					<Th>Moneda</Th>				
				</Tr>
			</Thead>
			<Tbody>
				{
					movements.length > 0 ?
						movements.map((movement, index) => {
							return (
								<Tr>									
									<Td><Moment format="YYYY-MM-DD" utc>{movement}</Moment></Td>									
									<Td>Envio de dinero</Td>
									<Td>{movement.value}</Td>
									<Td>GBM ({movement.protocol})</Td>
								</Tr>
							)
						})
					:null
				}				
			</Tbody>
		</Table>

	)

};

export default MovementsTable;