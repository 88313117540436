import React from 'react';
import NewHito from './newHito.jsx';

const Index = (props) => {

	return(

		<NewHito usuario={props.usuario} />

	)

}

export default Index;