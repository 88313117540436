import React from 'react';
import ReportWalletDetail from './reportWalletDetail.jsx';

const Index = () => {

	return(

		<ReportWalletDetail />

	)

}

export default Index;