import { 
    GET_NEWS, 
    NEWS_GET_ALL, 
    CHANGE_NEWS_PROPS, 
    CREATE_NEWS, 
    EDIT_NEWS, 
    SET_NEWS_STATE, 
    DELETE_NEWS,
    GET_NEWS_STATES,
    SEARCH_NEWS
} from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';

export const GetAllNews = createAction(
    NEWS_GET_ALL,
    () => apiPost(`${URL_ASGARD}/api/admin/getNews`)
);

export const getNews = createAction(
    GET_NEWS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/getNews`, data)
);

export const getNewsStates = createAction(
    GET_NEWS_STATES,
    (data) => apiPost(`${URL_ASGARD}/api/admin/newsStates`, data)
);

export const findNews = createAction(
    SEARCH_NEWS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/searchNews`, data)
);

export const createNews = createAction(
    CREATE_NEWS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/createNews`, data)
);

export const editNews = createAction(
    EDIT_NEWS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/editNews`, data)
);

export const deleteNews = createAction(
    DELETE_NEWS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/deleteNews`, data)
);

export const setNewsState = createAction(
    SET_NEWS_STATE,
    (data) => apiPost(`${URL_ASGARD}/api/admin/setNewsState`, data)
);

export const changeNewsProps = createAction(
    CHANGE_NEWS_PROPS,
    (props) => props
);