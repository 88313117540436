import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Card } from 'reactstrap';
import styles from './movements.module.scss';
import MainPrivateLayout from '../../../../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../../../../functions/roles';
import '../../../../../../styles/styles.scss';
import SubTitle from '../../../../../../components/subTitle';
import Username from '../../../../../../components/username';
import Back from '../../../../../../components/back';
import MovementsTable from './movementsTable';

const Movements = (props) => {

	const history = useHistory();
	const location = useLocation();
	const donated = location.state;

	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);

	const handleBack = () => {
		history.push("/donations");
	}

	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />
							<Username text={donated.usuario} />							
							<SubTitle text="movimientos" />
							<MovementsTable donated={donated} />
						</Card>						
					</div>
				:null


			}	
		</MainPrivateLayout>

	)

};

export default Movements;