import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input, 
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getEarningsAssigned, assignEarnings, delEarnings, getAdmins } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import Select from 'react-select'
import moment from 'moment';
import { verifyRoles } from '../../functions/roles';


const AssignEarnings = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);	
	const [sectionAssingEarnings, setSectionAssingEarnings] = useState(false);
	const [total, setTotal] = useState(0);
	const [userSearched, setUserSearched] = useState("");	
	const [earningsList, setEarningsList] = useState([]);
	const [selectedAdmin, setSelectedAdmin] = useState("");
  const [admins, setAdmins] = useState([]);
  const [options, setOptions] = useState([]);
  const [newEarning, setNewEarning] = useState("");
  const [selectedEarning, setSelectedEarning] = useState("");
  const [errorEarning, setErrorEarning] = useState("");
  const [responseEarning, setResponseEarning] = useState("");
  const [refresh, setRefresh] = useState('');
  const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });

		dispatch(getEarningsAssigned(data))
			.then((res) => {				
				setEarningsList(res.payload.earnings);
				setTotal(res.payload.total);
			})
			.catch((error) => {
				console.log(error);
			});

      	dispatch(getAdmins(data))
      	.then((res) => {        
        

          let vOptions = new Array();
          let i,data;
          for(i=0;i<res.payload.admins.length;i++){
            
            data = JSON.stringify({ 
                value: "",
                label: ""                      
            });
            data = JSON.parse(data);
            data.value = res.payload.admins[i].id_user;
            data.label = res.payload.admins[i].usuario;
            vOptions.push(data);
          }

          setOptions(vOptions);

      })
      .catch((error) => { });
		
	}, []);


	const onShowAssignEarnings = () => {

		// Inicializar campos
    	setNewEarning("");    	    	
    	setErrorEarning("");
    	setResponseEarning("");
    	setSelectedAdmin("");

		setSectionRegistros(false);
		setSectionAssingEarnings(true);
	}

	const onBackInit = () => {
		setSectionAssingEarnings(false);
		setSectionRegistros(true);		
	};

	
    const onSelectAdmin = selectedAdmin => {
      setSelectedAdmin(selectedAdmin);   
    };


    const onAssignEarnings = () => {

    	// Inicializar campos    	
    	setErrorEarning("");
    	setResponseEarning("");


	    let rexEarnings = /^(0|[1-9]\d*)(\.\d+)?$/;	    

	    if(!selectedAdmin){
	      setErrorEarning("Seleccionar un administrador");
	      return;
	    }
	
	    if(!rexEarnings.test(newEarning)){
	      setErrorEarning("Ingresar solo números para las ganancias");
	      return;
	    }	   

	    let data = Object.assign({}, { token: savedToken,id_user:selectedAdmin.value,earnings:newEarning });
	    dispatch(assignEarnings(data))
	    .then((res) => {        
	      
         	setEarningsList(res.payload.earnings);
			setTotal(res.payload.total);

	        setResponseEarning([moment().format('YYYY-MM-DD hh:mm:ss'),<br />,"Se asignó "+newEarning +"% de ganancias al administrador "+selectedAdmin.label]);

	    })
	    .catch((err) => {

	    	if(err.code == 400){
	    		setErrorEarning("El administrador ya tiene una ganancia asignada");
	    	}

	    });

  	}

  	const onDelete = (earnings) => {  	
  		
		let data = Object.assign({}, { token: savedToken, id_admin_earning_assigned: earnings.id_admin_earning_assigned });

		dispatch(delEarnings(data))
			.then((res) => {				
							
				let i;
				for(i=0;i<earningsList.length;i++){
					if(earningsList[i].id_admin_earning_assigned == earnings.id_admin_earning_assigned){						
						earningsList.splice(i,1);
					}
				}
				
				let newTotal = total - 1;								
				setTotal(newTotal);
	        	setEarningsList(earningsList);

	        	setRefresh(Math.random()*1000);

			})
			.catch((error) => { });	
		
	}


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-earnings">				

						<span className="page-title">Asignar ganancias</span>

						<Card className="admin-main-card">														

							{sectionRegistros ? (
								<div className="registros">
									
									<Button className="btn-show-assign" onClick={onShowAssignEarnings}>Asignar ganancias</Button>
									
									<div className="header">
										
										<Row>
											<Col sm={8}>
												<p className="title">Asignaciones = {total}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={userSearched}
														placeholder='Buscar usuario'
														onChange={(event) => setUserSearched(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="earnings-list">								
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>
													<Th>Usuario</Th>
													<Th>Nombre</Th>
													<Th>Ganancias</Th>													
													<Th></Th>
												</Tr>
											</Thead>
											<Tbody>
												{earningsList.length > 0 ?
													earningsList.map((earning, index) => {
														
														if (earning.usuario.toLowerCase().indexOf(userSearched.toLowerCase()) >= 0 || userSearched === ''){														
															return (
																<Tr key={index}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{earning.created_at}
																		</Moment>
																	</Td>
																	<Td>{earning.usuario}</Td>															
																	<Td>{earning.nombre}</Td>
																	<Td>{earning.earnings}%</Td>																	
																	<Td><a href="#!" onClick={()=>onDelete(earning)}>eliminar</a></Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}


							{sectionAssingEarnings ? (
								<div className="assignEarnings">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">ASIGNAR GANANCIAS</h1>

											<InputGroup className="inputGroup">
										        <InputGroupAddon addonType="prepend">								          
										          <InputGroupText>Ganancias (%) </InputGroupText>
										        </InputGroupAddon>
										        <Input 
										        	value={newEarning}  
										        	onChange={(event) => setNewEarning(event.target.value)}
										        />
										    </InputGroup>		

						                    <br />
											<Select
			                    options={options}
			                    placeholder="Seleccionar administrador"
			                    className="selectAdmin"
			                    value={selectedAdmin}
			                    onChange={onSelectAdmin}
			                />				                  

		                   <br/>
		                   <p className="error">{errorEarning}</p>
		                   <p className="response">{responseEarning}</p>
		                   <div className="text-center"><Button className="btn-assign" onClick={onAssignEarnings}>Asignar ganancias</Button></div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}
					
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default AssignEarnings;
