import React from 'react';
import styles from './username.module.scss';

const Username = (props) => {	
	
	return(
		 
		<p className={styles.username}><b>Usuario:</b> {props.text}</p>

	)

};

export default Username;