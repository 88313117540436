import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import VaultContext from '../../context/vaultContext';
import styles from './internalVault.module.scss';

import { DefineVaults } from '../../../../services/defineVaults.js';

import InPopover from './inPopover';

const InternalVault = () => {
		
	const context = useContext(VaultContext);

	const vaults = DefineVaults();
		
	const onDeposit = (selectedVault) => {		
		
	    context.setData({ ...context.data,selectedVault:selectedVault });

	}

	const onDeclareDeposit = (selectedVault) => {				
	    context.setData({ ...context.data,selectedVault:selectedVault });
	}

	const onDeclareWithdrawal = (selectedVault) => {				
	    context.setData({ ...context.data,selectedVault:selectedVault });
	}
	 
	return(

		<div className={styles.section}>				
			
			<h1 className={styles.title}>Bóveda interna</h1>												
			{
				vaults.length > 0 ?
				
				<div>
					<Row>
						{vaults.map((vault, index) => {
								
							if(vault.tipo === "internal_vault") {

								return (
									
									<Col sm={vault.symbol === "GBM" ? 12 : 6} key={index}>

										<div className={styles.vault}>
								            <span className={styles.logoFrame}><img src={'../images/cryptos/'+(vault.symbol).toLowerCase()+'.png'} alt="" className={styles.logo} /></span>
								            <div className={styles.content}>
								              
								                {
								            		vault.symbol === "GBM" ?
								            		<>
								            			{vault.vGBM.map((list, index) => {
															return(
																<>
																	<span>{list.name}</span> (<span id={"Popover"+list.address} className={styles.popoverBalance}>{list.protocol}</span>) 
																	{  list.protocol === "erc20" && list.network === "ethereum" ?
																		<span> ({context.data.heimdall.gbmcoinEthBalance} ETH ~ ${context.data.heimdall.gbmcoinEthUsd})</span>
																		: null																		
																	}

																	{  list.protocol === "trc20" && list.network === "tron" ?
																		<span> ({context.data.heimdall.gbmcoinTrxBalance} TRX ~ ${context.data.heimdall.gbmcoinTrxUsd})</span>
																		: null																		
																	}

																	{  list.protocol === "erc20" && list.network === "avalanche" ?
																		<span> ({context.data.heimdall.gbmcoinAvaxBalance} AVAX ~ ${context.data.heimdall.gbmcoinAvaxUsd})</span>
																		: null																		
																	}
																																		
																	<Link to={`/vaults/${context.data.company}/deposit`} onClick={() => onDeposit(list)}> depositar </Link> <br/>
																	<InPopover index={list.address} vault={list} />
																	
																</>
															)									            						
								            			})}								            			
								            		</>
								            		:
								           	  		<><span>{vault.name}</span> <Link to={`/vaults/${context.data.company}/deposit`} onClick={() => onDeposit(vault)}>depositar </Link></>
								            	}
								              

								              <br />
								              	{
								              		vault.symbol === "GBM" ?
								              		
								              		<>
										              Bóveda interna:<span className={styles.number}> {vault.unifiedBalance} GBM </span> <br /><br />
										              
										              Total zona lagarto:<span className={styles.number}> {vault.balanceLagarto} GBM </span> <br />
										              Balance total en poder de GBM:<span className={styles.number}> {vault.balanceTotalOwnerGBM} GBM </span> <br />
										              Balance TRC20 en poder de GBM:<span className={styles.number}> {vault.balanceTotalTRC20OwnerGBM} GBM </span> <br />
										              Balance ERC20 en poder de GBM:<span className={styles.number}> {vault.balanceTotalERC20OwnerGBM} GBM </span> <br />
										              Balance total en poder de usuarios:<span className={styles.number}> {vault.balanceTotalOwnerUsers} GBM </span> <br />
										              Balance TRC20 en poder de usuarios:<span className={styles.number}> {vault.balanceTotalERC20OwnerUsers} GBM </span> <br />
										              Balance ERC20 en poder de usuarios:<span className={styles.number}> {vault.balanceTotalTRC20OwnerUsers} GBM </span>

										            </>
										            :
										            <>
											            <span className={styles.number}> {vault.balance} {vault.symbol} </span><br/>
											            <span> ${vault.usd}</span>
											        </>
										        }								             								              
								              						             
								            </div>
								        </div>
									</Col>															

								);
							}																									
						})}	
					</Row>
				</div>
				:null
	
			}
						
		</div>	
	)

}

export default InternalVault;