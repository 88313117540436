import React from 'react';
import {
	CardBody,
	CardTitle,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import CardText from 'reactstrap/lib/CardText';
const Details = (props) => {
	const { created_at_movimiento, id, usuario, tipo, cantidad } = props.history.location.state;
	let history = useHistory();

	return (
		<MainPrivateLayout>
			<div className="page-content-container section-admin">
				<span className="page-title">Detalles de movimientos</span>

				<Card className="admin-main-card">
					<Row></Row>
					<Row>
						<Col>
							<div>
								<Card className="table-list-mov">
									<CardBody>
										<CardText>
											<Col>
												<p>
													<span>
														<b>Fecha:</b>
													</span>{' '}
													{created_at_movimiento}
												</p>
												<p>
													<span>
														<b>Id:</b>
														{'  '}
													</span>
													{id}
												</p>
												<p>
													<span>
														<b>Usuario:</b>
														{'  '}
													</span>
													{usuario}
												</p>
												<p>
													<span>
														<b>Tipo:</b>
														{'  '}
													</span>
													{tipo}
												</p>
												<p>
													<span>
														<b>Cantidad:</b>
														{'  '}
													</span>
													{cantidad} GBM
												</p>

												<hr />
											</Col>
											<Col className="movimientos-button-volver">
												<Button
													className="admin-button-volver-role"
													onClick={() =>
														history.push({
															pathname: '/movimientos',
															state: [],
														})
													}
												>
													Volver
												</Button>
											</Col>
										</CardText>
									</CardBody>
								</Card>
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		</MainPrivateLayout>
	);
};

export default Details;
