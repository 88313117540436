import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';

const SidebarItem = (props) => {
    let history = useHistory();
    const { expand, iconPath, label, route, handleClick } = props;

    const goTo = () => {
        if (route) {
            history.push(route);
        } else {
            handleClick()
        }
    }

    if (expand) {
        return (
            <Row className="expand-sidebar-item" onClick={() => goTo()}>
                <Col xs={3} className="expand-sidebar-item-icon">
                    <img
                        src={process.env.PUBLIC_URL + iconPath}
                        width="auto"
                        height="20"
                        alt=""
                        loading="lazy"
                    />
                </Col>
                <Col xs={9} className="expand-sidebar-item-label">
                    <span>{label}</span>
                </Col>
            </Row>
        );
    }
    else {
        return (
            <div className="collapsed-sidebar-item" onClick={() => goTo()}>
                <img
                    src={process.env.PUBLIC_URL + iconPath}
                    width="30"
                    height="auto"
                    alt=""
                    loading="lazy"
                />
                <span className="label">{label}</span>
            </div>
        );
    }
}

export default SidebarItem;