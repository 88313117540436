import React from 'react';
import { Link } from "react-router-dom";

// style
import styles from './returnStart.module.scss';

const ReturnStart = () => {

	return(		
		<Link to="/reports" className={styles.lnkReturn}> Volver</Link>
	);

}

export default ReturnStart;