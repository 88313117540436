import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getUsers, suspendUser, disableGoogle2faUser, deleteKycUser, getUserMovements, unlockUser, resendEmail, asignPasswordUser } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../functions/roles';


const Users = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');	
	const [usersList, setUsersList] = useState([]);	
	const [movimientos, setMovimientos] = useState([]);
	const [sectionRegistros, setSectionRegistros] = useState(true);
	const [sectionDetail, setSectionDetail] = useState(false);
	const [sectionDetailMove, setSectionDetailMove] = useState(false);
	const [inputUser, setInputUser] = useState("");
	const [selectedUser, setSelectedUser] = useState("");
	const [modal, setModal] = useState(false);
	const [modalResponse, setModalResponse] = useState(false);
	const [totals, setTotals] = useState("");
	const [selectedMovement, setSelectedMovement] = useState("");
	const [modalTitle, setModalTitle] = useState("");
	const [modalTitleResponse, setModalTitleResponse] = useState("");
	const [modalDescriptionResponse, setModalDescriptionResponse] = useState("");
	const [modalButton, setModalButton] = useState("");
	const [actionType, setActionType] = useState("");
	const [rolesHabilitado, setRolesHabilitado] = useState([1,17]);
		
	
	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getUsers(data))
			.then((res) => {				
				setUsersList(res.payload.users);				
				setTotals(res.payload.totals);				
			})
			.catch((error) => {
				console.log(error);
			});
		
	}, []);

	function SwitchUserStatus(props) {
		
		switch (props.status) {
			case -1:
				return 'Inhabilitado';
			case 0:
				return 'Suspendido';
			case 1:
				return 'Habilitado';
		}
	}

	function SwitchTipo(props) {

		switch (props.tipo) {
			case 1:
				return 'Depósito';
			case 2:
				return 'Retiro';
			case 3:
				return 'Intercambio entrante';
			case 4:
				return 'Intercambio saliente';			
		}

	}

	function SwitchMovementStatus(props) {
		if (props.confirmations <= 3) {
			return 'Pendiente';
		} else {
			return 'Completo';
		}
	}

	function SwitchKycStatus(props) {
		switch (props.status) {
			case 0:
				return 'No iniciado';
			case 1:
				return 'Pendiente';
			case 2:
				return 'Completo';
			case 3:
				return 'Desaprobado';			
		}
	}

	function SwitchEnableGoogle2fa(props) {
		switch (props.status) {
			case 0:
				return 'Deshabilitado';
			case 1:
				return 'Habilitado';
		}
	}

	function SwitchAccount(props) {
		if(props.attempts < 5 || props.attempts == 200 ){
			return "Activa";
		}else{
			return "Bloqueda";
		}
		
	}

	const onDetail = (user) => {
				
		setSelectedUser(user);
		scroll.scrollTo(200, { duration: 0 });				
		setSectionRegistros(false);
		setSectionDetail(true);
		setModal(false);
		setModalResponse(false);
		setModalDescriptionResponse("");

		let data = Object.assign({}, { token: savedToken, id_user: user.id_user });
		
		dispatch(getUserMovements(data))
			.then((res) => {								
				setMovimientos(res.payload);
			})
			.catch((error) => { });
		
	};	

	const onBackInit = () => {
		setMovimientos("");				
		setSectionRegistros(true);
		setSectionDetail(false);
	};
	
	const onBackDetail = () => {
		setSectionDetailMove(false);
		setSectionDetail(true);		
	};

	const onAction = (type) => {		
			
		setActionType(type);

		switch (type) {
			case 1:				
				setModalTitle("Suspender usuario");
				setModalButton("Si, quiero Suspender al usuario");
				break;
			case 2:
				setModalTitle("Deshabilitar Google 2FA");
				setModalButton("Si, quiero Deshabilitar el Google 2FA");
				break;
			case 3:
				setModalTitle("Eliminar KYC");
				setModalButton("Si, quiero Eliminar el KYC al usuario");
				break;
			case 4:
				setModalTitle("Desboquear usuario");
				setModalButton("Si, quiero desbloquear al usuario");
				break;
			case 5:
				setModalTitle("Reenviar correo de registro");
				setModalButton("Si, quiero reenviar el correo");
				break;	
			case 6:
				setModalTitle("Asignar contraseña a usuario");
				setModalButton("Si, quiero asignar una nueva contraseña");
				break;			
		}
		
		setModal(true);
	}	


	const onActionConfirm = (selectedUser) => {

		switch (actionType) {
			case 1:				
				onSuspendUser(selectedUser);
				break;
			case 2:
				onDisableGoogle2faUser(selectedUser);
				break;
			case 3:
				onDeleteKycUser(selectedUser);
				break;	
			case 4:
				onUnlockUser(selectedUser);
				break;
			case 5:
				onResendEmail(selectedUser);
				break;	
			case 6:
				onAsignPasswordUser(selectedUser);
				break;			
		}

	}


	const onSuspendUser = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user });

		dispatch(suspendUser(data))
			.then((res) => {	
				let data = Object.assign({}, { token: savedToken });
				dispatch(getUsers(data))
				.then((res) => {			
					setUsersList(res.payload.users);				
					setTotals(res.payload.totals);
					setSectionDetail(false);
					setSectionRegistros(true);
					setModal(false);							
				})
				.catch((error) => { });				
			})
			.catch((error) => { });

	}

	const onDisableGoogle2faUser = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user });

		dispatch(disableGoogle2faUser(data))
			.then((res) => {
				
				setModalTitleResponse("Google 2FA deshabilitado");
				setModalDescriptionResponse("El sistema deshabilito el Google 2FA al usuario "+selectedUser.usuario);
				selectedUser.google2fa_enable = 0;		
				setSelectedUser(selectedUser);
				setModal(false);
				setModalResponse(true);							
							
			})
			.catch((error) => { });

	}

	const onDeleteKycUser = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user });

		dispatch(deleteKycUser(data))
			.then((res) => {	
				
				setModalTitleResponse("KYC eliminado");
				setModalDescriptionResponse("El sistema eliminó el KYC al usuario "+selectedUser.usuario);
				selectedUser.kyc = 0;		
				setSelectedUser(selectedUser);
				setModal(false);
				setModalResponse(true);	
							
			})
			.catch((error) => { });

	}

	const onUnlockUser = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user });

		dispatch(unlockUser(data))
			.then((res) => {
				
				setModalTitleResponse("Usuario desbloqueado");
				setModalDescriptionResponse("El sistema desbloqueo al usuario "+selectedUser.usuario);
				selectedUser.login_attempts = 0;		
				setSelectedUser(selectedUser);
				setModal(false);
				setModalResponse(true);							
							
			})
			.catch((error) => { });

	}

	const onResendEmail = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user });

		dispatch(resendEmail(data))
			.then((res) => {
				
				setModalTitleResponse("Correo reenviado");
				setModalDescriptionResponse("El sistema reenvió el correo de registro a "+selectedUser.email);				
				setModal(false);
				setModalResponse(true);							
							
			})
			.catch((error) => { });

	}

	const onAsignPasswordUser = (selectedUser) => {
		
		let data = Object.assign({}, { token: savedToken, id_user: selectedUser.id_user, email:selectedUser.email });

		dispatch(asignPasswordUser(data))
			.then((res) => {
				
				setModalTitleResponse("Contraseña reseteada");
				setModalDescriptionResponse(["El sistema asignó una nueva contraseña.",<br/>,"La contraseña fue enviada a "+selectedUser.email]);				
				setModal(false);
				setModalResponse(true);							
							
			})
			.catch((error) => { });

	}

	const onCancelModal = () => {		
		setModal(false);
	}

	const onExitResponse = () => {		
		setModalResponse(false);
	}

	const onDetailMove = (move) => {

		setSelectedMovement(move);
		setSectionDetail(false);
		setSectionDetailMove(true);

	}


	

	return (
		
		<MainPrivateLayout>
			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-users">

						<div>
					      <Modal isOpen={modal} className="modalActionUser">
					        <ModalHeader>{modalTitle}</ModalHeader>
					        <ModalBody>
					          	<p><b>Usuario: </b>{selectedUser.usuario}</p>
					          	<p><b>Nombre: </b>{selectedUser.nombre}</p>
					        </ModalBody>
					        <ModalFooter>
					          <Button color="primary" className="btn-confirm" onClick={() => onActionConfirm(selectedUser)}>{modalButton}</Button>
					          <Button color="secondary" className="btn-cancel" onClick={onCancelModal}>Cancelar</Button>
					        </ModalFooter>
					      </Modal>
					    </div>

					    <div>
					      <Modal isOpen={modalResponse} className="modalResponseUser">
					        <ModalHeader>{modalTitleResponse}</ModalHeader>
					        <ModalBody>
					          	<p><b>Usuario: </b>{selectedUser.usuario}</p>
					          	<p><b>Nombre: </b>{selectedUser.nombre}</p>
					          	<p>{modalDescriptionResponse}</p>
					        </ModalBody>
					        <ModalFooter>
					          <Button color="primary" className="btn-exit-response" onClick={() => onExitResponse()}>Salir</Button>			          
					        </ModalFooter>
					      </Modal>
					    </div>

						<span className="page-title">Usuarios</span>

						<Card className="admin-main-card">
							{sectionRegistros ? (
								<div className="registros">

									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Usuarios = {totals.total} &nbsp;&nbsp; Habilitados = {totals.enabled} &nbsp;&nbsp; Inhabilitados = {totals.disabled} &nbsp;&nbsp; Suspendidos = {totals.suspended}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={inputUser}
														placeholder='Buscar usuario'
														onChange={(event) => setInputUser(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="users-list">
										<Table className="table">
											<Thead>
												<Tr>
													<Th>Fecha</Th>
													<Th>ID Usuario</Th>
													<Th>Usuario</Th>
													<Th>Nombre</Th>
													<Th>Apellido</Th>
													<Th>Estado</Th>													
												</Tr>
											</Thead>
											<Tbody>
												{usersList.length > 0 ?
													usersList.map((user, index) => {
														
														if (user.usuario.toLowerCase().indexOf(inputUser.toLowerCase()) >= 0 || inputUser === ''){														
															return (
																<Tr key={index} className="tr-user" onClick={() => onDetail(user)}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{user.created_at}
																		</Moment>
																	</Td>
																	<Td>{user.id_user}</Td>
																	<Td>{user.usuario}</Td>
																	<Td>{user.nombre}</Td>
																	<Td>{user.apellido}</Td>
																	<Td><SwitchUserStatus status={user.status} /></Td>															
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}

							{sectionDetail ? (
								<div className="detail">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">DETALLES DEL USUARIO</h1>

											<Row className="datos">
												<Col sm={1}></Col>
												<Col sm={5} xs={6} className="campo">
													<p><b>Fecha de creación</b></p>											
													<p><b>Usuario</b></p>
													<p><b>Nombre</b></p>
													<p><b>Apellido</b></p>
													<p><b>Email</b></p>
													<p><b>Teléfono</b></p>
													<p><b>KYC</b></p>
													<p><b>Google 2FA</b></p>
													<p><b>Cuenta</b></p>
													<p><b>Estado</b></p>
													<p><b>Mapa</b></p>

													{
														selectedUser.status == 1 ?
															<p><b>Acciones</b></p>
														: null
													}
												</Col>
												<Col sm={5} xs={6} className="valor">
													<p>
														<Moment format="YYYY-MM-DD HH:mm:ss" utc>
															{selectedUser.created_at}
														</Moment>
													</p>
													<p>{selectedUser.usuario}</p>
													<p>{selectedUser.nombre}</p>
													<p>{selectedUser.apellido}</p>
													<p>{selectedUser.email}</p>
													<p>{selectedUser.telefono ? selectedUser.telefono : "No tiene" }</p>											
													<p><SwitchKycStatus status={selectedUser.kyc} /></p>
													<p><SwitchEnableGoogle2fa status={selectedUser.google2fa_enable} /></p>
													<p><SwitchAccount attempts={selectedUser.login_attempts} /></p>
													<p><SwitchUserStatus status={selectedUser.status} /></p>
													<p><a href={`https://gis.gpscoin.io/hexagonosAsignados?&token=${savedToken}&id_user=${selectedUser.id_user}`} target="_blank" >Mapa</a></p>											
													{
														selectedUser.status == 1 ?
															<div>
																<p><a href="#!" onClick={() => onAction(1)}>suspender usuario</a></p>
																{   selectedUser.google2fa_enable ?
																		<p><a href="#!" onClick={() => onAction(2)}>Deshabilitar Google 2FA</a></p>
																	:null
																}
																{   (selectedUser.kyc == 1 || selectedUser.kyc == 2) ?
																		<p><a href="#!" onClick={() => onAction(3)}>Eliminar KYC</a></p>
																	:null
																}														
															</div>
														: null
													}
													{
														selectedUser.login_attempts == 5 ?
														<p><a href="#!" onClick={() => onAction(4)}>Desbloquear usuario</a></p>
														:null
													}
													{
														!selectedUser.habilitado ?
														<p><a href="#!" onClick={() => onAction(5)}>Reenviar correo de registro</a></p>
														:null
													}
													<p><a href="#!" onClick={() => onAction(6)}>Asignar Contraseña</a></p>
												</Col>
												<Col sm={1}></Col>
											</Row>

											<div className="movimientos">
												<p className="second-title"><b>Movimientos</b></p>

												<Table className="table">
													<Thead>
														<Tr>
															<Th>Fecha</Th>
															<Th>Operación</Th>													
															<Th>Cantidad</Th>																															
														</Tr>
													</Thead>
													<Tbody>
														{movimientos.length > 0 ?
															movimientos.map((move, index) => {
																				
																return (
																	<Tr key={index} className="tr-move" onClick={() => onDetailMove(move)}>
																		<Td>
																			<Moment format="YYYY-MM-DD HH:mm" utc>
																				{move.fecha}
																			</Moment>
																		</Td>
																		
																		<Td><SwitchTipo tipo={move.tipo} /></Td>
																		<Td>{move.value} {move.symbol}</Td>
																	</Tr>
																);
																	
															})
															: null
														}
													</Tbody>
												</Table>										
											</div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}

							{sectionDetailMove ? (
								<div className="detail">
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackDetail}><a href="#!">volver</a></p>
											<h1 className="title">DETALLES DEL MOVIMIENTO</h1>

											<Row className="datos">
												<Col sm={1}></Col>
												<Col sm={5} xs={6} className="campo">
													<p>
														<b>Fecha</b>
													</p>
													<p>
														<b>Operación</b>
													</p>
													<p>
														<b>Nombre</b>
													</p>
													<p>
														<b>Cantidad</b>
													</p>
													<p>
														<b>Confirmaciones</b>
													</p>
													<p>
														<b>Estado</b>
													</p>
												</Col>
												<Col sm={5} xs={6} className="valor">
													<p>
														<Moment format="YYYY-MM-DD HH:mm:ss" utc>
															{selectedMovement.fecha}
														</Moment>
													</p>
													<p>
														<SwitchTipo tipo={selectedMovement.tipo} />
													</p>
													<p>{selectedMovement.name}</p>
													<p>
														{selectedMovement.value} {selectedMovement.symbol}
													</p>
													<p>{selectedMovement.confirmations}</p>
													<p>
														<SwitchMovementStatus tipo={selectedMovement.confirmations} />
													</p>
												</Col>
												<Col sm={1}></Col>
											</Row>

											<div className="footer">
												<p>
													<b>Transaction ID</b> <br />
													<span>{selectedMovement.tx_hash}</span>
												</p>
												<p>
													<b>Destino</b> <br />
													<span>{selectedMovement.dest_address}</span>
												</p>
												<p>
													<b>Tx URL</b> <br />
													<span>
														<a href={selectedMovement.tx_url} target="_blank">
															{selectedMovement.tx_url.substring(0, 50)}...
														</a>
													</span>
												</p>
											</div>
											
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}

						</Card>
					</div>

				:null
			}

		</MainPrivateLayout>
	

			
		
	);

	
};

export default Users;
