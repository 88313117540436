import React, { useContext } from 'react';
import styles from './reportAdmin.module.scss';

// Atoms
import ReportHeader from '../../atoms/reportHeader';
import FilterBetweenDates from '../../atoms/filterBetweenDates';
import ReportAdminDetail from '../../atoms/reportAdminDetail';
import ReturnStart from '../../atoms/returnStart';

const ReportAdmin = () => {

	return(
		<div>
			<ReturnStart />
			<ReportHeader />
			<FilterBetweenDates />
			<ReportAdminDetail />
		</div>
	);

}

export default ReportAdmin;