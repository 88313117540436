import { 
	GET_SECTION_VAULTS,
	SEND_DECLARATION_DEPOSIT,
	SEND_DECLARATION_WITHDRAWAL

} from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';


export const getSectionVaults = createAction(
    GET_SECTION_VAULTS,
    (data) => apiPost(`${URL_ASGARD}/api/admin/vaults/getSectionVaults`, data)
);

export const sendDeclarationDeposit = createAction(
    SEND_DECLARATION_DEPOSIT,
    (data) => apiPost(`${URL_ASGARD}/api/admin/vaults/sendDeclarationDeposit`, data)
);

export const sendDeclarationWithdrawal = createAction(
    SEND_DECLARATION_WITHDRAWAL,
    (data) => apiPost(`${URL_ASGARD}/api/admin/vaults/sendDeclarationWithdrawal`, data)
);