import {
	USER_VERIFY_USER_AND_EMAIL,	
	USER_SIGNUP_SEND_SMS,
	USER_SIGNUP_VERIFY_SMS_CODE,
	USER_SIGNUP_GOOGLE_2FA,
	USER_SIGNUP_COMPLETE_REGISTRATION,
	USER_SIGNUP_VERIFY_MAIL_CODE,
	USER_LOGIN,
	USER_LOGIN_VERIFY_GOOGLE_2FA,
	USER_LOGIN_EMAIL_AUTHORIZE,
	USER_LOGIN_ACCESS,
	SEND_PASSWORD_RESET_LINK,
	PASSWORD_RESET,
	USER_LOGOUT,
	CHANGE_USER_PROPS,
	UPDATE_USER_DATA,
	UPDATE_TOKEN	
} from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';

// Action Creators
export const verifyUserAndEmail = createAction(USER_VERIFY_USER_AND_EMAIL, (user) =>
	apiPost(`${URL_ASGARD}/api/admin/verifyUserAndEmail`, user)
);


export const SignUpSendSMS = createAction(USER_SIGNUP_SEND_SMS, (phone) =>
	apiPost(`${URL_ASGARD}/api/admin/sendSMS`, phone)
);

export const SignUpVerifySMSCode = createAction(USER_SIGNUP_VERIFY_SMS_CODE, (code) =>
	apiPost(`${URL_ASGARD}/api/admin/verifySMS`, code)
);

export const SignUpInitGoogle2fa = createAction(USER_SIGNUP_GOOGLE_2FA, (userEmail) =>
	apiPost(`${URL_ASGARD}/api/admin/initGoogle2fa`, userEmail)
);

export const SignUpCompleteRegistration = createAction(USER_SIGNUP_COMPLETE_REGISTRATION, (user) =>
	apiPost(`${URL_ASGARD}/api/admin/completeRegistration`, user)
);
 
export const SignUpVerifyMailCode = createAction(USER_SIGNUP_VERIFY_MAIL_CODE, (user) =>
	apiPost(`${URL_ASGARD}/api/admin/verifyRegisterEmail`, user)
);

export const LogIn = createAction(USER_LOGIN, (user) => apiPost(`${URL_ASGARD}/api/admin/login`, user));

export const LogInVerifyGoogle2FA = createAction(USER_LOGIN_VERIFY_GOOGLE_2FA, (credentials) =>
	apiPost(`${URL_ASGARD}/api/admin/verifyGoogle2FA`, credentials)
);

export const LogInEmailAuthorize = createAction(USER_LOGIN_EMAIL_AUTHORIZE, (credentials) =>
	apiPost(`${URL_ASGARD}/api/admin/receiveVerifyLogin`, credentials)
);

export const LogInAccess = createAction(USER_LOGIN_ACCESS, (credentials) =>
	apiPost(`${URL_ASGARD}/api/admin/verifyAuthAccess`, credentials)
);

export const UpdateUserData = createAction(UPDATE_USER_DATA, (props) => props);

export const changeUserProps = createAction(CHANGE_USER_PROPS, (props) => props);

export const LogOut = createAction(USER_LOGOUT, (credentials) =>
	apiPost(`${URL_ASGARD}/api/admin/logout`, credentials)
);

export const sendPasswordResetLink = createAction(SEND_PASSWORD_RESET_LINK, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/sendPasswordResetLink`, data)
);

export const passwordReset = createAction(PASSWORD_RESET, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/passwordReset`, data)
);

export const updateToken = createAction(
    UPDATE_TOKEN,
    (data) => apiPost(`${URL_ASGARD}/api/admin/updateToken`, data)
);


