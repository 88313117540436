import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { SignUpVerifyMailCode, LogOut } from '../../store/action-creators/UserActions';
import { Button, Row, Modal, Col, Spinner } from 'reactstrap';
import { ROUTE_SIGNUP, ROUTE_LOGIN, ROUTE_HOME } from '../../constants/routes';


const SignupEmailConfirmation = (props) => {
    const link_access_token = props.match.params.access_token;

    let history = useHistory();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [expiredAccessToken, setExpiredAccessToken] = useState(false);
    const [invalidAccessToken, setInvalidAccessToken] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(SignUpVerifyMailCode(
            {
                access_token: link_access_token
            }
        ))
            .then(result => {
                history.push(ROUTE_LOGIN);
            })
            .catch(error => {
                setIsLoading(false);
                if (error.code === 319) {
                    setExpiredAccessToken(true);
                } else {
                    setInvalidAccessToken(true);
                }
            });
    }, []);

    const cancelRegistration = (route) => {
        let token = sessionStorage.getItem('token');
        dispatch(LogOut({
            token
        }))
            .then(result => {
                history.push(route);
            })
            .catch(error => {
                history.push(route);
            });
    }

    return (
        <div className='signup' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-login.jpg)` }}>
            <img
                src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                alt="GBM Logo"
                className='gbm-logo'
            />
            {
                isLoading ?
                    <Spinner type="grow" color="success" style={{ marginTop: "2rem" }} />
                    :
                    null
            }

            <Modal isOpen={invalidAccessToken} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                            alt="GBM Logo"
                            className='modal-img'
                        />
                    </Row>
                    <Row className="signup-modal-row">
                        <span className="signup-modal-error-title">{"Lo sentimos, algo no ha salido como debería."}</span>
                    </Row>
                    <Row className="modal-google-row" >
                        <p style={{ color: "red" }}>
                            {"El link no es valido."}
                        </p>
                    </Row>
                    <Row className="modal-google-row" >
                        <Col
                            xs={12} sm={12} md={6} lg={6} xl={6}
                            className="signup-modal-col"
                        >
                            <Button
                                color=""
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                className='login-button'
                                onClick={() => cancelRegistration(ROUTE_HOME)}
                            >
                                {'HOME'}
                            </Button>
                        </Col>
                        <Col
                            xs={12} sm={12} md={6} lg={6} xl={6}
                            className="signup-modal-col"
                        >
                            <Button
                                color=""
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
                                className='signup-button'
                                onClick={() => cancelRegistration(ROUTE_SIGNUP)}
                            >
                                {'REGISTRO'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal isOpen={expiredAccessToken} centered className="signup-modal">
                <div className="signup">
                    <Row className="signup-modal-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                            alt="GBM Logo"
                            className='modal-img'
                        />
                    </Row>
                    <Row className="signup-modal-row">
                        <span className="signup-modal-title">{"Confirma tu correo electrónico"}</span>
                    </Row>
                    <Row className="modal-google-row" >
                        <p style={{ color: "#85ad3f" }}>
                            {"Gracias por elegir GBM COIN para tu próxima inversión. Recuerda que tu colaboración salvará nuestra biósfera, su flora y todas las especies que la habitan."}
                        </p>
                    </Row>
                    <Row className="modal-google-row" >
                        <p >
                            {"El token de acceso espiró. Te enviamos un nuevo mensaje a tu correo para que valides tu cuenta."}
                        </p>
                    </Row>
                    <Row className="modal-google-row">
                        <img
                            src={process.env.PUBLIC_URL + '/images/login/salida-sol-sobre-selva-bali.jpg'}
                            alt="jungle img"
                            className='signup-modal-email-img'
                        />
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default SignupEmailConfirmation;