import React, { useState } from 'react';
import { Button, Row, Modal, Form, Input, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { sendPasswordResetLink } from '../../store/action-creators/UserActions';
import { GoMailRead } from "react-icons/go";


const initialFormData = Object.freeze({
  user: "",
  google2fa: ""
});


const LoginForgotPass = () => {
    
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(initialFormData);    
    const [errorUser, setErrorUser] = useState("");
    const [errorGoogle2fa, setErrorGoogle2fa] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);   

    // Modal
    const [modal, setModal] = useState(true);
    const [backdrop, setBackdrop] = useState(true);
    const [keyboard, setKeyboard] = useState(true);

    const handleChange = (e) => {    
        setFormData({
         ...formData,
         // Trimming any whitespace
          [e.target.name]: e.target.value.trim()
        });       
    };   

    const handleSubmit = (e) => {

        setErrorUser("");
        setErrorGoogle2fa("");

        e.preventDefault();
        let error = 0;
        if(!formData.user){
            setErrorUser("Ingresar usuario");
            error = 1;
        }
        if(!formData.google2fa){
            setErrorGoogle2fa("Ingresar código");
            error = 1;
        }
        
        if(error){
            return;
        }       
        

        setIsLoading(true);
        
        let identity = Object.assign( {}, {user: formData.user, one_time_password: formData.google2fa });

        dispatch(sendPasswordResetLink(identity))
            .then(result => {               
                setFormData(initialFormData);
                setIsLoading(false);
                setSendEmail(true);
                setModal(true);                             
            })
            .catch(error => {
               setIsLoading(false);
               if(error.code === 400){
                   setErrorUser("usuario incorrecto");                  
               }
               if(error.code === 401){
                setErrorUser("Cuenta bloqueda. 5 intentos fallidos");
               }
               if(error.code === 402){
                   setErrorGoogle2fa("Google 2FA incorrecto");                   
               }
                                       
        });

    }

    // Modal

    const toggle = () => setModal(!modal);

    const changeBackdrop = e => {
        let value = e.target.value;
        if (value !== 'static') {
          value = JSON.parse(value);
        }
        setBackdrop(value);
    }

    const changeKeyboard = e => {
        setKeyboard(e.currentTarget.checked);
    }


    return (        
       
        <div className='login forgotPass' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
            <img
                src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                alt="GBM Logo"
                className='gbm-logo'
            />
           

                {
                    sendEmail ? 
                    <div>
                        <Modal isOpen={modal} toggle={toggle} backdrop={backdrop} keyboard={keyboard} centered className="login-modal modal-forgotPass">                
                            <Row className="login-modal-row text-center">
                                <GoMailRead size={100} />
                            </Row>
                            <p>Te enviamos un correo para que puedas  <br/>
                               recuperar tu contraseña
                            </p>
                        </Modal>
                    </div>
                    : null
                }

              
                {
                    isLoading ? <Spinner type="grow" color="success" />
                    :                 
                                
                        <div>
                            <h1 className="login-title">Reestrablecer contraseña</h1>

                            <div className="login-form">
                                <Form encType="multipart/form-data">
                                    
                                    <Input                                             
                                        placeholder="Usuario"
                                        type="text"                       
                                        name="user"
                                        value={formData.user}
                                        onChange={handleChange}                      
                                        className="login-input"
                                        autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
                                    />
                                    <p className="login-error-msg">
                                        {errorUser} 
                                    </p>
                                                                                    
                                    <Input                                                                     
                                        placeholder="Google Authenticator"
                                        type="text"                         
                                        name="google2fa"
                                         value={formData.google2fa}                     
                                        onChange={handleChange}
                                        className="login-input"
                                        autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
                                    />
                                    <p className="login-error-msg">
                                        {errorGoogle2fa} 
                                    </p> 
                                                                      
                                </Form>
                                                          
                                <Row className='basic-row'>
                                    <Button
                                        color=""
                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_dorado_inicio.png)` }}
                                        className='login-button'
                                        onClick={handleSubmit}
                                        type='submit'
                                       
                                    >
                                        {'REESTABLECER'}
                                    </Button>
                        
                                </Row>      

                            </div>
                        </div>                    
                }
           
        </div>
        
    );
};

export default LoginForgotPass;