import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Input, FormFeedback, Button, Row } from 'reactstrap';

export class LoginEmailForm extends Component {

    componentDidMount() {
        if (this.txt) {
            this.txt.focus();
        }
    }

    renderMyField = ({ input, meta, type, name, withFocus, placeholder }) => {
        return (
            <div className="login-input-container">
                <Input
                    {...input}
                    id={name}
                    placeholder={placeholder}
                    type={type ? type : "text"}
                    innerRef={withFocus && (txt => this.txt = txt)}
                    invalid={meta.touched && !!meta.error}
                    className="login-input"
                    autoComplete="new-password" // deshabilita el autocomplete ya que autoComplete="off" no funciona mas
                />
                {
                    meta.touched && meta.error ?
                        <FormFeedback className="login-formfeedback">{meta.error}</FormFeedback>
                        :
                        null
                }
            </div>
        );
    }

    render() {
        const { handleSubmit, submitting, error, LoginError } = this.props;

        return (
            <div className='login-form'>
                <Form encType="multipart/form-data" autoComplete="off">
                    {/*    <input style={{ display: "none" }} autoComplete="new-password" />    deshabilita el autocomplete ya que autoComplete="off" no funciona mas */}

                    <Field
                        name="usuario"
                        placeholder="&#xf007;   Usuario"
                        component={this.renderMyField}
                        withFocus={true}
                    />

                    <Field
                        type="password"
                        name="password"
                        placeholder="&#xf023;   Contraseña"
                        component={this.renderMyField}
                    />

                </Form>
                <Row className='basic-row'>
                    <pre className="login-error-msg">
                        {LoginError}
                    </pre>
                </Row>
                <Row className='basic-row'>
                    <Button
                        color=""
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_dorado_inicio.png)` }}
                        className='login-button'
                        onClick={handleSubmit}
                        type='submit'
                        disabled={submitting}
                    >
                        {'INGRESAR'}
                    </Button>
                </Row>

            </div>
        );
    }
}


// El nombre del formulario que se le pasa al HOC reduxForm debe ser unico en toda la app.
export default reduxForm({ form: 'LoginEmailForm' })(LoginEmailForm);