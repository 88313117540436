import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LogInEmailAuthorize, LogOut } from '../../store/action-creators/UserActions';
import { Button, Row, Modal, Col, Spinner } from 'reactstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import { ROUTE_LOGIN, ROUTE_HOME } from '../../constants/routes';

const LoginEmailAuth = (props) => {
	const link_access_token = props.match.params.access_token;

	let history = useHistory();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [accessValidated, setAccessValidated] = useState(false);
	const [invalidAccessToken, setInvalidAccessToken] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		dispatch(
			LogInEmailAuthorize({
				access_token: link_access_token,
			})
		)
			.then((result) => {
				console.log('LogInEmailAuthorize: result: ', result);
				setIsLoading(false);
				setAccessValidated(true);
			})
			.catch((error) => {
				console.log('LogInEmailAuthorize: error: ', error);
				setIsLoading(false);
				setInvalidAccessToken(true);
			});
	}, []);

	const cancelLogin = (route) => {
		let token = sessionStorage.getItem('token');
		dispatch(
			LogOut({
				token,
			})
		)
			.then((result) => {
				history.push(route);
			})
			.catch((error) => {
				history.push(route);
			});
	};

	return (
		<div className="login" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
			<img src={process.env.PUBLIC_URL + '/images/gbm-logo.png'} alt="GBM Logo" className="gbm-logo" />
			{isLoading ? <Spinner type="grow" color="success" style={{ marginTop: '2rem' }} /> : null}
			{console.log('hola')}
			<Modal isOpen={accessValidated} centered className="login-modal">
				<div className="login">
					<Row className="login-modal-row">
						<FaRegCheckCircle size={100} />
					</Row>
					<Row className="login-modal-row">{'Verificación Exitosa!'}</Row>
					<Row className="login-modal-row">
						{'Vuelva a la pantalla donde comenzó el inicio de sesión y presione ACCEDER.'}
					</Row>
				</div>
			</Modal>

			<Modal isOpen={invalidAccessToken} centered className="login-modal">
				<div className="login">
					<Row className="login-modal-row">
						<img
							src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
							alt="GBM Logo"
							className="modal-img"
						/>
					</Row>
					<Row className="login-modal-row">
						<span className="login-modal-error-title">
							{'Lo sentimos, algo no ha salido como debería.'}
						</span>
					</Row>
					<Row className="modal-google-row">
						<p style={{ color: 'red' }}>
							{'El link que ha utilizado no es valido. Por favor vuelva a Iniciar sesión.'}
						</p>
					</Row>
					<Row className="modal-google-row">
						<Col xs={12} sm={12} md={6} lg={6} xl={6} className="login-modal-col">
							<Button
								color=""
								style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
								className="login-button"
								onClick={() => cancelLogin(ROUTE_HOME)}
							>
								{'HOME'}
							</Button>
						</Col>
						<Col xs={12} sm={12} md={6} lg={6} xl={6} className="login-modal-col">
							<Button
								color=""
								style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)` }}
								className="login-button"
								onClick={() => cancelLogin(ROUTE_LOGIN)}
							>
								{'LOGIN'}
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	);
};

export default LoginEmailAuth;
