import React, { useState } from 'react';
import styles from './accessSection.module.scss';
import { getOptions } from './accessSection.js';
import { useHistory } from "react-router-dom";
import Select from 'react-select'
import { Td } from 'react-super-responsive-table';
import TableButton from '../../../../../components/tableButton';

const AccessSection = (props) => {

	const history = useHistory();
	const options = getOptions();
	const [ option, setOption ] = useState("");	

	const onOption = option => {		
	    setOption(option);   
	};
 
	const handleAccess = (option) => {
						
		if(option){
			const pathname = window.location.pathname;
			history.push(pathname+'/'+option.section, props.donated);
		}		
						
	}
	

	return(
		
		<Td className={styles.tdSection}>
			<Select
	          options={options}
	          placeholder="Seleccionar"			                    
	          value={option}
	          className={styles.select}
	          onChange={onOption}
	      	/>
			<TableButton text="acceder" handleClick={() => handleAccess(option)} />
		</Td>

	)

};

export default AccessSection;