import React from 'react';
import Beneficiaries from './beneficiaries.jsx';

const Index = () => {
		
	return(

		<Beneficiaries />

	)

}

export default Index;