import React from 'react';
import styles from './sectionTitle.module.scss';

const SectionTitle = (props) => {	
	
	return(
		 
		<h1 className={styles.title}>{props.text}</h1>

	)

};

export default SectionTitle;