import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import { Row, Col, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select'
import styles from './movements.module.scss';
import MovementsList from '../../atoms/movementsList';
import VaultContext from '../../context/vaultContext';
import { getVaultOptions } from './movements.js';

import { DefineMovementList } from '../../../../services/defineMovementList.js';

const Movements = () => {

	const context = useContext(VaultContext);

	const movementsList = DefineMovementList();
	
	const [txSearched, setTxSearched] = useState("");	
	const [selectedOperation, setSelectedOperation] = useState("");	

	const onSetSelectedOperation = selectedOperation => { 
		setSelectedOperation(selectedOperation);
	}

	const vaultOptions = getVaultOptions();
				
	return(

		<div className={styles.section}>
			
			<div className={styles.header}>
				<Row>
					<Col sm={5}>
						<p className={styles.title}>Movimientos = {movementsList.length}</p>
					</Col>
					<Col sm={7}>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText className={styles.inputGroupText}>
									<BsSearch />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								value={txSearched}
								placeholder='Buscar Hash o Relación'
								onChange={(event) => setTxSearched(event.target.value)}
								autoComplete="new-password"
								className={styles.input}
							/>
						</InputGroup>
					</Col>
				</Row>
				<hr />
			</div>

			<Select
		        options={vaultOptions}
		        value={selectedOperation}
		        onChange={onSetSelectedOperation}
		        className={styles.select}
		    />

		    <MovementsList txSearched = {txSearched} selectedOperation = {selectedOperation} />
			
		</div>

	)

}

export default Movements;