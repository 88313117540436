import React, { useState } from 'react';
import styles from './depositsLinksList.module.scss';
import SubTitle from '../../../components/subTitle';
import DepositsLinksTable from './depositsLinksTable';

const DepositsLinksList = () => {
					
	return(
		 
		<div>
			<SubTitle text="Links de depósitos" />
			<DepositsLinksTable />			
		</div>

	)

};

export default DepositsLinksList;