import React from 'react';
import ReportContableDetail from './reportContableDetail.jsx';

const Index = () => {

	return(

		<ReportContableDetail />

	)

}

export default Index;