import React, { useContext } from 'react';
import { Table, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ReportsContext from '../../context/reportsContext';

// functions
import { getReportsList } from './reportsList.js';

// style
import styles from './reportsList.module.scss';

const ReportList = () => {

	const context = useContext(ReportsContext);
	const reports = getReportsList();

	const onSaveSelectedReport = (report) => {
		context.setData({ ...context.data, selectedReport: report });
	}

	return(

		<Table responsive>
		  <thead>
		    <tr>
		      <th>#</th>
		      <th>Título</th>
		      <th>Descripción</th>
		      <th>Acción</th>
		    </tr>
		  </thead>
		  <tbody>
		   	{reports.length > 0 ?
				reports.map((report, index) => {
					return(

						<tr key={index}>
							<th>{report.num}</th>
							<td>{report.title}</td>
							<td>{report.description}</td>
							<td><Link to={report.link} onClick={() => onSaveSelectedReport(report)}> Acceder </Link></td>
						</tr>

					);
				})
				:null	
			}
		  </tbody>
		</Table>

	);

}

export default ReportList;