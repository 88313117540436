const getFlags = async () => {
	let flagCountry = '[';
	const paises = await fetch('https://restcountries.eu/rest/v2/all')
		.then((response) => response.json())
		.then((flags) => {
			return flags;
		});

	paises.map((flag, index) => {
		if (flag.callingCodes[0] !== '') {
			flagCountry =
				flagCountry +
				`{ imgCode:"${flag.alpha2Code}", label: "${flag.name}", phoneCode: "+${flag.callingCodes[0]}" }`;
		}
		// console.log(flag.callingCodes[0]);
	});

	flagCountry = flagCountry + ']';
	console.log(flagCountry);

	localStorage.setItem('banderas', JSON.stringify(flagCountry));
	console.log(JSON.parse(localStorage.getItem('banderas')));
};

export function flags1() {
	const flags = [
		{ imgCode: 'AR', label: 'Argentina', phoneCode: '+54' },
		{ imgCode: 'AF', label: 'Afghanistan', phoneCode: '+93' },
		{ imgCode: 'AX', label: 'Åland Islands', phoneCode: '+358' },
		{ imgCode: 'AL', label: 'Albania', phoneCode: '+355' },
		{ imgCode: 'DZ', label: 'Algeria', phoneCode: '+213' },
		{ imgCode: 'AS', label: 'American Samoa', phoneCode: '+1684' },
		{ imgCode: 'AD', label: 'Andorra', phoneCode: '+376' },
		{ imgCode: 'AO', label: 'Angola', phoneCode: '+244' },
		{ imgCode: 'AI', label: 'Anguilla', phoneCode: '+1264' },
		{ imgCode: 'AQ', label: 'Antarctica', phoneCode: '+672' },
		{ imgCode: 'AG', label: 'Ant. and Barb.', phoneCode: '+1268' },

		{ imgCode: 'AM', label: 'Armenia', phoneCode: '+374' },
		{ imgCode: 'AW', label: 'Aruba', phoneCode: '+297' },
		{ imgCode: 'AU', label: 'Australia', phoneCode: '+61' },
		{ imgCode: 'AT', label: 'Austria', phoneCode: '+43' },
		{ imgCode: 'AZ', label: 'Azerbaijan', phoneCode: '+994' },
		{ imgCode: 'BS', label: 'Bahamas', phoneCode: '+1242' },
		{ imgCode: 'BH', label: 'Bahrain', phoneCode: '+973' },
		{ imgCode: 'BD', label: 'Bangladesh', phoneCode: '+880' },
		{ imgCode: 'BB', label: 'Barbados', phoneCode: '+1246' },
		{ imgCode: 'BY', label: 'Belarus', phoneCode: '+375' },
		{ imgCode: 'BE', label: 'Belgium', phoneCode: '+32' },
		{ imgCode: 'BZ', label: 'Belize', phoneCode: '+501' },
		{ imgCode: 'BJ', label: 'Benin', phoneCode: '+229' },
		{ imgCode: 'BM', label: 'Bermuda', phoneCode: '+1441' },
		{ imgCode: 'BT', label: 'Bhutan', phoneCode: '+975' },
		{ imgCode: 'BO', label: 'Bolivia', phoneCode: '+591' },
		{ imgCode: 'BQ', label: 'Bonaire', phoneCode: '+5997' },
		{ imgCode: 'BA', label: 'Bosnia and Herzegovina', phoneCode: '+387' },
		{ imgCode: 'BW', label: 'Botswana', phoneCode: '+267' },
		{ imgCode: 'BR', label: 'Brazil', phoneCode: '+55' },
		{ imgCode: 'IO', label: 'British Ind. Oc. T.', phoneCode: '+246' },
		{ imgCode: 'VG', label: 'Virgin I. B.', phoneCode: '+1284' },
		{ imgCode: 'VI', label: 'Virgin I. U.S.', phoneCode: '+1 340' },
		{ imgCode: 'BN', label: 'Brunei Darussalam', phoneCode: '+673' },
		{ imgCode: 'BG', label: 'Bulgaria', phoneCode: '+359' },
		{ imgCode: 'BF', label: 'Burkina Faso', phoneCode: '+226' },
		{ imgCode: 'BI', label: 'Burundi', phoneCode: '+257' },
		{ imgCode: 'KH', label: 'Cambodia', phoneCode: '+855' },
		{ imgCode: 'CM', label: 'Cameroon', phoneCode: '+237' },
		{ imgCode: 'CA', label: 'Canada', phoneCode: '+1' },
		{ imgCode: 'CV', label: 'Cabo Verde', phoneCode: '+238' },
		{ imgCode: 'KY', label: 'Cayman Islands', phoneCode: '+1345' },
		{ imgCode: 'CF', label: 'Central African Republic', phoneCode: '+236' },
		{ imgCode: 'TD', label: 'Chad', phoneCode: '+235' },
		{ imgCode: 'CL', label: 'Chile', phoneCode: '+56' },
		{ imgCode: 'CN', label: 'China', phoneCode: '+86' },
		{ imgCode: 'CX', label: 'Christmas Island', phoneCode: '+61' },
		{ imgCode: 'CC', label: 'Cocos Islands', phoneCode: '+61' },
		{ imgCode: 'CO', label: 'Colombia', phoneCode: '+57' },
		{ imgCode: 'KM', label: 'Comoros', phoneCode: '+269' },
		{ imgCode: 'CG', label: 'Congo', phoneCode: '+242' },
		{ imgCode: 'CD', label: 'Congo', phoneCode: '+243' },
		{ imgCode: 'CK', label: 'Cook Islands', phoneCode: '+682' },
		{ imgCode: 'CR', label: 'Costa Rica', phoneCode: '+506' },
		{ imgCode: 'HR', label: 'Croatia', phoneCode: '+385' },
		{ imgCode: 'CU', label: 'Cuba', phoneCode: '+53' },
		{ imgCode: 'CW', label: 'Curaçao', phoneCode: '+599' },
		{ imgCode: 'CY', label: 'Cyprus', phoneCode: '+357' },
		{ imgCode: 'CZ', label: 'Czech Republic', phoneCode: '+420' },
		{ imgCode: 'DK', label: 'Denmark', phoneCode: '+45' },
		{ imgCode: 'DJ', label: 'Djibouti', phoneCode: '+253' },
		{ imgCode: 'DM', label: 'Dominica', phoneCode: '+1767' },
		{ imgCode: 'DO', label: 'Dominican Republic', phoneCode: '+1809' },
		{ imgCode: 'EC', label: 'Ecuador', phoneCode: '+593' },
		{ imgCode: 'EG', label: 'Egypt', phoneCode: '+20' },
		{ imgCode: 'SV', label: 'El Salvador', phoneCode: '+503' },
		{ imgCode: 'GQ', label: 'Equatorial Guinea', phoneCode: '+240' },
		{ imgCode: 'ER', label: 'Eritrea', phoneCode: '+291' },
		{ imgCode: 'EE', label: 'Estonia', phoneCode: '+372' },
		{ imgCode: 'ET', label: 'Ethiopia', phoneCode: '+251' },
		{ imgCode: 'FK', label: 'Malvinas', phoneCode: '+500' },
		{ imgCode: 'FO', label: 'Faroe Islands', phoneCode: '+298' },
		{ imgCode: 'FJ', label: 'Fiji', phoneCode: '+679' },
		{ imgCode: 'FI', label: 'Finland', phoneCode: '+358' },
		{ imgCode: 'FR', label: 'France', phoneCode: '+33' },
		{ imgCode: 'GF', label: 'French Guiana', phoneCode: '+594' },
		{ imgCode: 'PF', label: 'French Polynesia', phoneCode: '+689' },
		{ imgCode: 'GA', label: 'Gabon', phoneCode: '+241' },
		{ imgCode: 'GM', label: 'Gambia', phoneCode: '+220' },
		{ imgCode: 'GE', label: 'Georgia', phoneCode: '+995' },
		{ imgCode: 'DE', label: 'Germany', phoneCode: '+49' },
		{ imgCode: 'GH', label: 'Ghana', phoneCode: '+233' },
		{ imgCode: 'GI', label: 'Gibraltar', phoneCode: '+350' },
		{ imgCode: 'GR', label: 'Greece', phoneCode: '+30' },
		{ imgCode: 'GL', label: 'Greenland', phoneCode: '+299' },
		{ imgCode: 'GD', label: 'Grenada', phoneCode: '+1473' },
		{ imgCode: 'GP', label: 'Guadeloupe', phoneCode: '+590' },
		{ imgCode: 'GU', label: 'Guam', phoneCode: '+1671' },
		{ imgCode: 'GT', label: 'Guatemala', phoneCode: '+502' },
		{ imgCode: 'GG', label: 'Guernsey', phoneCode: '+44' },
		{ imgCode: 'GN', label: 'Guinea', phoneCode: '+224' },
		{ imgCode: 'GW', label: 'Guinea-Bissau', phoneCode: '+245' },
		{ imgCode: 'GY', label: 'Guyana', phoneCode: '+592' },
		{ imgCode: 'HT', label: 'Haiti', phoneCode: '+509' },
		{ imgCode: 'VA', label: 'Holy See', phoneCode: '+379' },
		{ imgCode: 'HN', label: 'Honduras', phoneCode: '+504' },
		{ imgCode: 'HK', label: 'Hong Kong', phoneCode: '+852' },
		{ imgCode: 'HU', label: 'Hungary', phoneCode: '+36' },
		{ imgCode: 'IS', label: 'Iceland', phoneCode: '+354' },
		{ imgCode: 'IN', label: 'India', phoneCode: '+91' },
		{ imgCode: 'ID', label: 'Indonesia', phoneCode: '+62' },
		{ imgCode: 'CI', label: "Côte d'Ivoire", phoneCode: '+225' },
		{ imgCode: 'IR', label: 'Iran', phoneCode: '+98' },
		{ imgCode: 'IQ', label: 'Iraq', phoneCode: '+964' },
		{ imgCode: 'IE', label: 'Ireland', phoneCode: '+353' },
		{ imgCode: 'IM', label: 'Isle of Man', phoneCode: '+44' },
		{ imgCode: 'IL', label: 'Israel', phoneCode: '+972' },
		{ imgCode: 'IT', label: 'Italy', phoneCode: '+39' },
		{ imgCode: 'JM', label: 'Jamaica', phoneCode: '+1876' },
		{ imgCode: 'JP', label: 'Japan', phoneCode: '+81' },
		{ imgCode: 'JE', label: 'Jersey', phoneCode: '+44' },
		{ imgCode: 'JO', label: 'Jordan', phoneCode: '+962' },
		{ imgCode: 'KZ', label: 'Kazakhstan', phoneCode: '+76' },
		{ imgCode: 'KE', label: 'Kenya', phoneCode: '+254' },
		{ imgCode: 'KI', label: 'Kiribati', phoneCode: '+686' },
		{ imgCode: 'KW', label: 'Kuwait', phoneCode: '+965' },
		{ imgCode: 'KG', label: 'Kyrgyzstan', phoneCode: '+996' },
		{ imgCode: 'LA', label: 'Lao ', phoneCode: '+856' },
		{ imgCode: 'LV', label: 'Latvia', phoneCode: '+371' },
		{ imgCode: 'LB', label: 'Lebanon', phoneCode: '+961' },
		{ imgCode: 'LS', label: 'Lesotho', phoneCode: '+266' },
		{ imgCode: 'LR', label: 'Liberia', phoneCode: '+231' },
		{ imgCode: 'LY', label: 'Libya', phoneCode: '+218' },
		{ imgCode: 'LI', label: 'Liechtenstein', phoneCode: '+423' },
		{ imgCode: 'LT', label: 'Lithuania', phoneCode: '+370' },
		{ imgCode: 'LU', label: 'Luxembourg', phoneCode: '+352' },
		{ imgCode: 'MO', label: 'Macao', phoneCode: '+853' },
		{ imgCode: 'MK', label: 'Macedonia ', phoneCode: '+389' },
		{ imgCode: 'MG', label: 'Madagascar', phoneCode: '+261' },
		{ imgCode: 'MW', label: 'Malawi', phoneCode: '+265' },
		{ imgCode: 'MY', label: 'Malaysia', phoneCode: '+60' },
		{ imgCode: 'MV', label: 'Maldives', phoneCode: '+960' },
		{ imgCode: 'ML', label: 'Mali', phoneCode: '+223' },
		{ imgCode: 'MT', label: 'Malta', phoneCode: '+356' },
		{ imgCode: 'MH', label: 'Marshall Islands', phoneCode: '+692' },
		{ imgCode: 'MQ', label: 'Martinique', phoneCode: '+596' },
		{ imgCode: 'MR', label: 'Mauritania', phoneCode: '+222' },
		{ imgCode: 'MU', label: 'Mauritius', phoneCode: '+230' },
		{ imgCode: 'YT', label: 'Mayotte', phoneCode: '+262' },
		{ imgCode: 'MX', label: 'Mexico', phoneCode: '+52' },
		{ imgCode: 'FM', label: 'Micronesia', phoneCode: '+691' },
		{ imgCode: 'MD', label: 'Moldova (Republic of)', phoneCode: '+373' },
		{ imgCode: 'MC', label: 'Monaco', phoneCode: '+377' },
		{ imgCode: 'MN', label: 'Mongolia', phoneCode: '+976' },
		{ imgCode: 'ME', label: 'Montenegro', phoneCode: '+382' },
		{ imgCode: 'MS', label: 'Montserrat', phoneCode: '+1664' },
		{ imgCode: 'MA', label: 'Morocco', phoneCode: '+212' },
		{ imgCode: 'MZ', label: 'Mozambique', phoneCode: '+258' },
		{ imgCode: 'MM', label: 'Myanmar', phoneCode: '+95' },
		{ imgCode: 'NA', label: 'Namibia', phoneCode: '+264' },
		{ imgCode: 'NR', label: 'Nauru', phoneCode: '+674' },
		{ imgCode: 'NP', label: 'Nepal', phoneCode: '+977' },
		{ imgCode: 'NL', label: 'Netherlands', phoneCode: '+31' },
		{ imgCode: 'NC', label: 'New Caledonia', phoneCode: '+687' },
		{ imgCode: 'NZ', label: 'New Zealand', phoneCode: '+64' },
		{ imgCode: 'NI', label: 'Nicaragua', phoneCode: '+505' },
		{ imgCode: 'NE', label: 'Niger', phoneCode: '+227' },
		{ imgCode: 'NG', label: 'Nigeria', phoneCode: '+234' },
		{ imgCode: 'NU', label: 'Niue', phoneCode: '+683' },
		{ imgCode: 'NF', label: 'Norfolk Island', phoneCode: '+672' },
		{ imgCode: 'KP', label: 'Korea', phoneCode: '+850' },
		{ imgCode: 'MP', label: 'Northern Mariana Islands', phoneCode: '+1670' },
		{ imgCode: 'NO', label: 'Norway', phoneCode: '+47' },
		{ imgCode: 'OM', label: 'Oman', phoneCode: '+968' },
		{ imgCode: 'PK', label: 'Pakistan', phoneCode: '+92' },
		{ imgCode: 'PW', label: 'Palau', phoneCode: '+680' },
		{ imgCode: 'PS', label: 'Palestine, State of', phoneCode: '+970' },
		{ imgCode: 'PA', label: 'Panama', phoneCode: '+507' },
		{ imgCode: 'PG', label: 'Papua New Guinea', phoneCode: '+675' },
		{ imgCode: 'PY', label: 'Paraguay', phoneCode: '+595' },
		{ imgCode: 'PE', label: 'Peru', phoneCode: '+51' },
		{ imgCode: 'PH', label: 'Philippines', phoneCode: '+63' },
		{ imgCode: 'PN', label: 'Pitcairn', phoneCode: '+64' },
		{ imgCode: 'PL', label: 'Poland', phoneCode: '+48' },
		{ imgCode: 'PT', label: 'Portugal', phoneCode: '+351' },
		{ imgCode: 'PR', label: 'Puerto Rico', phoneCode: '+1787' },
		{ imgCode: 'QA', label: 'Qatar', phoneCode: '+974' },
		{ imgCode: 'XK', label: 'Republic of Kosovo', phoneCode: '+383' },
		{ imgCode: 'RE', label: 'Réunion', phoneCode: '+262' },
		{ imgCode: 'RO', label: 'Romania', phoneCode: '+40' },
		{ imgCode: 'RU', label: 'Russian Federation', phoneCode: '+7' },
		{ imgCode: 'RW', label: 'Rwanda', phoneCode: '+250' },
		{ imgCode: 'BL', label: 'Saint Barthélemy', phoneCode: '+590' },
		{ imgCode: 'SH', label: 'Saint Helena', phoneCode: '+290' },
		{ imgCode: 'KN', label: 'Saint Kitts and Nevis', phoneCode: '+1869' },
		{ imgCode: 'LC', label: 'Saint Lucia', phoneCode: '+1758' },
		{ imgCode: 'MF', label: 'Saint Martin (French part)', phoneCode: '+590' },
		{ imgCode: 'PM', label: 'Saint Pierre and Miquelon', phoneCode: '+508' },
		{ imgCode: 'VC', label: 'Saint Vincent and the Grenadines', phoneCode: '+1784' },
		{ imgCode: 'WS', label: 'Samoa', phoneCode: '+685' },
		{ imgCode: 'SM', label: 'San Marino', phoneCode: '+378' },
		{ imgCode: 'ST', label: 'Sao Tome and Principe', phoneCode: '+239' },
		{ imgCode: 'SA', label: 'Saudi Arabia', phoneCode: '+966' },
		{ imgCode: 'SN', label: 'Senegal', phoneCode: '+221' },
		{ imgCode: 'RS', label: 'Serbia', phoneCode: '+381' },
		{ imgCode: 'SC', label: 'Seychelles', phoneCode: '+248' },
		{ imgCode: 'SL', label: 'Sierra Leone', phoneCode: '+232' },
		{ imgCode: 'SG', label: 'Singapore', phoneCode: '+65' },
		{ imgCode: 'SX', label: 'Sint Maarten (Dutch part)', phoneCode: '+1721' },
		{ imgCode: 'SK', label: 'Slovakia', phoneCode: '+421' },
		{ imgCode: 'SI', label: 'Slovenia', phoneCode: '+386' },
		{ imgCode: 'SB', label: 'Solomon Islands', phoneCode: '+677' },
		{ imgCode: 'SO', label: 'Somalia', phoneCode: '+252' },
		{ imgCode: 'ZA', label: 'South Africa', phoneCode: '+27' },
		{ imgCode: 'GS', label: 'South Georgia and', phoneCode: '+500' },
		{ imgCode: 'KR', label: 'Korea (Republic of)', phoneCode: '+82' },
		{ imgCode: 'SS', label: 'South Sudan', phoneCode: '+211' },
		{ imgCode: 'ES', label: 'Spain', phoneCode: '+34' },
		{ imgCode: 'LK', label: 'Sri Lanka', phoneCode: '+94' },
		{ imgCode: 'SD', label: 'Sudan', phoneCode: '+249' },
		{ imgCode: 'SR', label: 'Suriname', phoneCode: '+597' },
		{ imgCode: 'SJ', label: 'Svalbard and Jan Mayen', phoneCode: '+4779' },
		{ imgCode: 'SZ', label: 'Swaziland', phoneCode: '+268' },
		{ imgCode: 'SE', label: 'Sweden', phoneCode: '+46' },
		{ imgCode: 'CH', label: 'Switzerland', phoneCode: '+41' },
		{ imgCode: 'SY', label: 'Syrian Arab Republic', phoneCode: '+963' },
		{ imgCode: 'TW', label: 'Taiwan', phoneCode: '+886' },
		{ imgCode: 'TJ', label: 'Tajikistan', phoneCode: '+992' },
		{ imgCode: 'TZ', label: 'Tanzania, United Republic of', phoneCode: '+255' },
		{ imgCode: 'TH', label: 'Thailand', phoneCode: '+66' },
		{ imgCode: 'TL', label: 'Timor-Leste', phoneCode: '+670' },
		{ imgCode: 'TG', label: 'Togo', phoneCode: '+228' },
		{ imgCode: 'TK', label: 'Tokelau', phoneCode: '+690' },
		{ imgCode: 'TO', label: 'Tonga', phoneCode: '+676' },
		{ imgCode: 'TT', label: 'Trinidad and Tobago', phoneCode: '+1868' },
		{ imgCode: 'TN', label: 'Tunisia', phoneCode: '+216' },
		{ imgCode: 'TR', label: 'Turkey', phoneCode: '+90' },
		{ imgCode: 'TM', label: 'Turkmenistan', phoneCode: '+993' },
		{ imgCode: 'TC', label: 'Turks and Caicos Islands', phoneCode: '+1649' },
		{ imgCode: 'TV', label: 'Tuvalu', phoneCode: '+688' },
		{ imgCode: 'UG', label: 'Uganda', phoneCode: '+256' },
		{ imgCode: 'UA', label: 'Ukraine', phoneCode: '+380' },
		{ imgCode: 'AE', label: 'United Arab Emirates', phoneCode: '+971' },
		{ imgCode: 'GB', label: 'United Kingdom ', phoneCode: '+44' },
		{ imgCode: 'US', label: 'United States of America', phoneCode: '+1' },
		{ imgCode: 'UY', label: 'Uruguay', phoneCode: '+598' },
		{ imgCode: 'UZ', label: 'Uzbekistan', phoneCode: '+998' },
		{ imgCode: 'VU', label: 'Vanuatu', phoneCode: '+678' },
		{ imgCode: 'VE', label: 'Venezuela (Bolivarian Republic of)', phoneCode: '+58' },
		{ imgCode: 'VN', label: 'Viet Nam', phoneCode: '+84' },
		{ imgCode: 'WF', label: 'Wallis and Futuna', phoneCode: '+681' },
		{ imgCode: 'EH', label: 'Western Sahara', phoneCode: '+212' },
		{ imgCode: 'YE', label: 'Yemen', phoneCode: '+967' },
		{ imgCode: 'ZM', label: 'Zambia', phoneCode: '+260' },
		{ imgCode: 'ZW', label: 'Zimbabwe', phoneCode: '+263' },
	];

	return flags;
}

export default getFlags;
