import React, { Component } from 'react';
// import { /*BrowserRouter*/ HashRouter as Router, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import * as routes from '../../constants/routes';
//import News from "../../pages/news/News";
import Dashboard from '../../pages/dashboard/Dashboard';
import Addnews from '../../pages/news/Addnews';
import SearchNews from '../../pages/news/Searchnews';
//import Login from "../../pages/login/LoginEmail";
// login
import Login from '../../pages/login/Login';
import LoginEmail from '../../pages/login/LoginEmail';
import LoginEmailAuth from '../../pages/login/LoginEmailAuth';
import LoginForgotPass from '../../pages/login/LoginForgotPass';
import LoginPasswordReset from '../../pages/login/LoginPasswordReset';

//signUp
import Signup from '../../pages/signup/Signup';
import SignupEmail from '../../pages/signup/SignupEmail';
import SignupSms from '../../pages/signup/SignupSms';
import SignupEmailConfirmation from '../../pages/signup/SignupEmailConfirmation';
import Movimientos from '../../pages/movimientos/Movimientos';

// ADMINISTRADORES

import Administradores from '../../pages/administradores/administradores';
import Users from '../../pages/users/users';
import usersMovements from '../../pages/users/usersMovements';
import usersAddresses from '../../pages/users/usersAddresses';
import Roles from '../../pages/roles/roles.js';
import movementDetails from '../../pages/movimientos/components/detailsMov';
import Vaults from '../../pages/vaults/organism';
import Codigosdedescuento from '../../pages/codigosdedescuento/codigosdedescuento';
import SalesByCode from '../../pages/salesByCode/salesByCode';
import Profile from '../../pages/profile/Profile';
import EnableAdmin from '../../pages/enableAdmin/enableAdmin';
import AssignEarnings from '../../pages/assignEarnings/assignEarnings';
import InternalEarnings from '../../pages/internalEarnings/internalEarnings';
import Logs from '../../pages/logs/Logs';
import Accounting from '../../pages/accounting/accounting';
import Reports from '../../pages/reports/organism';
import Donations from '../../pages/donations';
import DonationsHitos from '../../pages/donations/beneficiaries/beneficiariesTable/accessSection/hitos';
import DonationsMovements from '../../pages/donations/beneficiaries/beneficiariesTable/accessSection/movements';
import DonationsSendMoney from '../../pages/donations/beneficiaries/beneficiariesTable/accessSection/sendMoney';
import DonationsManageDonated from '../../pages/donations/beneficiaries/beneficiariesTable/accessSection/manageDonated';
import DonationsNewDonation from '../../pages/donations/newDonation';
import DonationsHitosNewHito from '../../pages/donations/beneficiaries/beneficiariesTable/accessSection/hitos/newHito';
import DepositsLinks from '../../pages/depositsLinks';
import DepositsLinksNewDepositLink from '../../pages/depositsLinks/newDepositLink';


/* ----------- PROFILES ----------- */

import ProfilesAsociadosMovements from '../../pages/profiles/asociados/movements';
import ProfilesVendedoresVentas from '../../pages/profiles/vendedores/ventas';



class MainRouter extends Component {
	render() {
		return ( 
			<Router>
				<ScrollToTop />
				<Switch>
					{/* <PublicRoute exact path={routes.ROUTE_LOGIN} component={Login} /> */}
					<PublicRoute exact path={routes.ROUTE_HOME} component={Login} />
					<PublicRoute exact path={routes.ROUTE_LOGIN} component={Login} />
					<PublicRoute exact path={routes.ROUTE_LOGIN_EMAIL} component={LoginEmail} />
					<PublicRoute exact path={routes.ROUTE_LOGIN_EMAIL_AUTH} component={LoginEmailAuth} />
					<PublicRoute exact path={routes.ROUTE_LOGIN_FORGOT_PASSWORD} component={LoginForgotPass} />
					<PublicRoute exact path={routes.ROUTE_LOGIN_PASSWORD_RESET} component={LoginPasswordReset} />
					<PublicRoute exact path={routes.ROUTE_SIGNUP} component={Signup} />
					<PublicRoute exact path={routes.ROUTE_SIGNUP_EMAIL} component={SignupEmail} />
					<PublicRoute exact path={routes.ROUTE_SIGNUP_SMS} component={SignupSms} />

					<PrivateRoute exact path={routes.ROUTE_DASHBOARD} component={Dashboard} />
					<PrivateRoute exact path={routes.ROUTE_ADD_NEWS} component={Addnews} />
					<PrivateRoute exact path={routes.ROUTE_SEARCH_NEWS} component={SearchNews} />										
					<PrivateRoute exact path={routes.ROUTE_SIGNUP} component={Signup} />
					<PrivateRoute exact path={routes.ROUTE_SIGNUP_EMAIL} component={SignupEmail} />
					<PrivateRoute exact path={routes.ROUTE_SIGNUP_SMS} component={SignupSms} />
					<PrivateRoute exact path={routes.ROUTE_ADMINISTRADORES} component={Administradores} />
					<PrivateRoute exact path={routes.ROUTE_MOVIMIENTOS} component={Movimientos} />
					<PrivateRoute exact path={routes.ROUTE_USUARIOS} component={Users} />
					<PrivateRoute exact path={routes.ROUTE_USERS_MOVEMENTS} component={usersMovements} />
					<PrivateRoute exact path={routes.ROUTE_USERS_ADDRESSES} component={usersAddresses} />
					<PrivateRoute exact path={routes.ROUTE_ROLES} component={Roles} />					
					<PrivateRoute exact path={routes.ROUTE_SALES_BY_CODE} component={SalesByCode} />
					<PrivateRoute exact path={routes.ROUTE_PROFILE} component={Profile} />					
					<PrivateRoute exact path={routes.ROUTE_MOVIMIENTOS_DETALLE} component={movementDetails} />
					<PrivateRoute exact path={routes.ROUTE_CODIGOSDEDESCUENTO} component={Codigosdedescuento} />
					<PrivateRoute exact path={routes.ROUTE_VAULTS} component={Vaults} />
					<PrivateRoute exact path={routes.ROUTE_ENABLE_ADMIN} component={EnableAdmin} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS} component={Donations} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_HITOS} component={DonationsHitos} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_MOVEMENTS} component={DonationsMovements} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_SENDMONEY} component={DonationsSendMoney} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_MANAGE_DONATED} component={DonationsManageDonated} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_NEW_DONATION} component={DonationsNewDonation} />
					<PrivateRoute exact path={routes.ROUTE_DONATIONS_HITOS_NEW_HITO} component={DonationsHitosNewHito} />
					<PrivateRoute exact path={routes.ROUTE_DEPOSITS_LINKS} component={DepositsLinks} />
					<PrivateRoute exact path={routes.ROUTE_DEPOSITS_LINKS_NEW_DEPOSIT_LINK} component={DepositsLinksNewDepositLink} />
					<PrivateRoute exact path={routes.ROUTE_ASSIGN_EARNINGS} component={AssignEarnings} />
					<PrivateRoute exact path={routes.ROUTE_INTERNAL_EARNINGS} component={InternalEarnings} />
					<PrivateRoute exact path={routes.ROUTE_LOGS} component={Logs} />
					<PrivateRoute exact path={routes.ROUTE_ACCOUNTING} component={Accounting} />
					<PrivateRoute exact path={routes.ROUTE_PROFILES_ASOCIADOS_MOVEMENTS} component={ProfilesAsociadosMovements} />
					<PrivateRoute exact path={routes.ROUTE_PROFILES_VENDEDORES_VENTAS} component={ProfilesVendedoresVentas} />
					<PrivateRoute exact path={routes.ROUTE_REPORTS} component={Reports} />
					<PublicRoute
						exact
						path={routes.ROUTE_SIGNUP_EMAIL_CONFIRMATION}
						component={SignupEmailConfirmation}
					/>
				</Switch>
			</Router>
		);
	}
}

export default MainRouter;
