import { 
    GET_NEWS, 
    NEWS_GET_ALL, 
    CHANGE_NEWS_PROPS, 
    CREATE_NEWS, 
    EDIT_NEWS, 
    SET_NEWS_STATE, 
    DELETE_NEWS,
    GET_NEWS_STATES,
    SEARCH_NEWS
} from '../action-types';
import { handleActions } from 'redux-actions';

const initialState = {
    totalResults: 0,
    articles: [],
    error: ''
};

function updateState(state, newProps) {
    let newState = Object.assign({}, state, newProps);
    return newState;
}

export const news = handleActions(
    {
        [CHANGE_NEWS_PROPS]: (state, action) => {
            let newState = updateState(state, action.payload);
            return newState;
        },

        [NEWS_GET_ALL]: (state, action) => {

            if (action.error) {
                return ({
                    articles: [...state.articles],
                    error: action.payload.error
                });
            }

            return ({
                totalResults: action.payload.totalResults,
                articles: [...action.payload.articles],
                error: ''
            });
        },

        [GET_NEWS]: (state, action) => {
            return action.payload;
        },

        [CREATE_NEWS]: (state, action) => {
            return action.payload;
        },

        [EDIT_NEWS]: (state, action) => {
            return action.payload;
        },

        [DELETE_NEWS]: (state, action) => {
            return action.payload;
        },

        [SET_NEWS_STATE]: (state, action) => {
            return action.payload;
        },

        [GET_NEWS_STATES]: (state, action) => {
            return action.payload;
        },

        [SEARCH_NEWS]: (state, action) => {
            return action.payload;
        }
        
    },
    initialState
);