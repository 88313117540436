import React, { useEffect, useState, useContext } from 'react';
import MainPrivateLayout from '../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../functions/roles';
import { animateScroll as scroll } from 'react-scroll';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card } from 'reactstrap';
import { useDispatch } from 'react-redux';
import ReportsContext from '../context/reportsContext';

import { Init } from './reports.js';

// Molecules
import ReportsStorage from '../molecules/reportsStorage';
import ReportSale from '../molecules/reportSale';
import ReportAdmin from '../molecules/reportAdmin';
import ReportWallet from '../molecules/reportWallet';
import ReportSalary from '../molecules/reportSalary';
import ReportContable from '../molecules/reportContable';
  
const Reports = () => {
		
	const user = useSelector((store) => store.user.user);		
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);	

	const { dataContext, setDataContext } = Init();
	
	return (
		<MainPrivateLayout>

			{ 
				verifyRoles(user.roles,rolesHabilitado) ?

						<div className="page-content-container section-vaults">
							<span className="page-title">Reportes</span>
							<Card className="main-card">
								<ReportsContext.Provider value={{data:dataContext,setData:setDataContext}}>
									<Router>
										<Switch>

											<Route path="/reports" exact > <ReportsStorage /> </Route>
										  	<Route path="/reports/sale" exact > <ReportSale /> </Route>
										  	<Route path="/reports/admin" exact > <ReportAdmin /> </Route>
										  	<Route path="/reports/wallet" exact > <ReportWallet /> </Route>
										  	<Route path="/reports/salary" exact > <ReportSalary /> </Route>
										  	<Route path="/reports/contable" exact > <ReportContable /> </Route>
											
										</Switch>
									</Router>
								</ReportsContext.Provider>
																	          
							</Card>
						</div>

				

				:null
			}
		</MainPrivateLayout>
	);
};

export default Reports;
