const getOptions = () =>{

	const options = [
	  { value: '1', label: 'GBM Coin (erc20)', symbol: "GBM", protocol: "erc20" },
	  { value: '2', label: 'GBM Coin (trc20)', symbol: "GBM", protocol: "trc20" }
	];	

	return options;

}

export { getOptions };