import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { LogOut, LogInAccess } from '../../store/action-creators/UserActions';

function PrivateRoute({ component: Component, ...rest }) {
	// const [token, setToken] = useState(localStorage.getItem('token'));
	const dispatch = useDispatch();
	const history = useHistory();
	const savedToken = sessionStorage.getItem('token');
	const [isLoading, setIsLoading] = useState(true);
	const [verifiedAccess, setVerifiedAccess] = useState(false);

	useEffect(() => {
		if (savedToken) {
			dispatch(
				LogInAccess({
					token: savedToken,
				})
			)
				.then((result) => {
					setVerifiedAccess(true);
					setIsLoading(false);
				})
				.catch((error) => {
					dispatch(
						LogOut({
							token: savedToken,
						})
					)
						.then((result) => {
							 history.push(ROUTE_LOGIN);
						})
						.catch((error) => {
							 history.push(ROUTE_LOGIN);
						});
					setVerifiedAccess(false);
					setIsLoading(false);
				});
		} else {
			setVerifiedAccess(false);
			setIsLoading(false);
		}
	}, []);

	return (
		<Route
			// Al no tener un path definido siempre se ejecuta la funcion de la propiedad render
			{...rest}
			render={(props) =>
				savedToken ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: ROUTE_LOGIN, state: { referer: props.location } }} />
				)
			}
		/>
	);
}

export default PrivateRoute;
