import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import { useHistory, useLocation } from "react-router-dom";
import styles from './hitos.module.scss';
import MainPrivateLayout from '../../../../../../config/MainPrivateLayout';
import { useSelector } from 'react-redux';
import { verifyRoles } from '../../../../../../functions/roles';
import '../../../../../../styles/styles.scss';
import Button1 from '../../../../../../components/button1';
import SubTitle from '../../../../../../components/subTitle';
import Username from '../../../../../../components/username';
import Back from '../../../../../../components/back';
import HitosTable from './hitosTable';

const Hitos = () => {

	const history = useHistory();

	const location = useLocation();
	const donated = location.state;	
	
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);
	const user = useSelector((store) => store.user.user);

	const handleNewHito = () => {
		
		const pathname = window.location.pathname;		
		history.push(pathname+'/newHito', donated);		

	}

	const handleBack = () => {
		history.push("/donations");
	}

	return(
		
		<MainPrivateLayout>	
			{

				verifyRoles(user.roles,rolesHabilitado) ?
					<div className="page-content-container">						
						<Card className="back-content-section">							
							<Back handleClick={handleBack} />
							<Username text={donated.usuario} />
							<div className={styles.devButton}>
								<Button1 text="nuevo hito" handleClick={handleNewHito} />
							</div>
							<SubTitle text="lista de hitos" />
							<HitosTable donated={donated} />
						</Card>						
					</div>
				:null


			}	
		</MainPrivateLayout>

	)

};

export default Hitos;