const getOptions = () =>{

	const options = [
	  { value: '1', label: 'Hitos', section: "hitos" },
	  { value: '2', label: 'Movimientos', section: "movements" },
	  { value: '3', label: 'Enviar Dinero', section: "sendMoney" },
	  { value: '4', label: 'Gestión', section: "management" }
	];	

	return options;

}

export { getOptions };