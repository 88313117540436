import React, { useState, useContext } from 'react';
import { Row, Col, Popover, PopoverHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import styles from './externalVault.module.scss';

import VaultContext from '../../context/vaultContext';
import { DefineVaults } from '../../../../services/defineVaults.js';

import ExPopover from './exPopover';

const ExternalVault = () => {
	
	const context = useContext(VaultContext);

	const vaults = DefineVaults();
	
	const onDeposit = (selectedVault) => {				
	    context.setData({ ...context.data,selectedVault:selectedVault });
	}
    

	return(

		<div className={styles.section}>				
			
			<h1 className={styles.title}>Bóveda externa</h1>												
			{ 
				vaults.length > 0 ?

				<div>  
					<Row>
						{vaults.map((vault, index) => {
								  
							if(vault.tipo === "external_vault") {
								return (
									<Col sm={6} key={index}>
										<div className={styles.vault}>								            								            
								            <span className={styles.logoFrame}><img src={'../images/cryptos/'+(vault.symbol).toLowerCase()+'.png'} alt="" className={styles.logo} /></span>
								            <div className={styles.content}>
								            	{
								            		vault.symbol === "GBM" && vault.vGBM ?
								            		<>
								            			{vault.vGBM.map((list, index) => {
															return(
																<>
																	<span>{list.name}</span> (<span id={"Popover"+list.address} className={styles.popoverBalance}>{list.protocol}-{list.network} </span>) <Link to={`/vaults/${context.data.company}/deposit`} onClick={() => onDeposit(list)}>depositar </Link> <br/>
																	<ExPopover index={list.address} vault={list} />
						                                               
																</>
															)									            						
								            			})}
								            		</>
								            		:
								           	  		<><span>{vault.name}</span> <Link to={`/vaults/${context.data.company}/deposit`} onClick={() => onDeposit(vault)}>depositar </Link></>
								            	}
								              	<span className={styles.number}>{vault.balance} {vault.symbol}</span>
								              	<span>${vault.usd}</span>
								            </div>
								        </div>
									</Col>															
								);
							}																									
						})}	
					</Row>
				</div>
				:null
	
			}
									
		</div>	

	)

}

export default ExternalVault;