import React, { Component, useState } from "react";
import { reduxForm, Field } from "redux-form";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import {
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Col,
  Row,
  Container,
  InputGroupButtonDropdown,
} from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import NewsButton from "../../components/NewsButton";
import { TINYMCE_KEY } from "../../constants/keys";
import { TOOLBAR, PLUGINS } from "../../constants/tinymce_config";
import { setPropsToInitialValues } from "../../HOCs/setPropsToInitialValues";
const DEFIMAGE = process.env.PUBLIC_URL + "/images/no-image.png";


registerLocale('es', es);

const validate = (values) => {

  const error = {};

  if (!values.title) {
    error.title = "Por favor complete el título de la noticia.";
  }

  if (!values.subtitle) {
    error.subtitle = "Por favor complete el subtítulo de la noticia.";
  }

  if (!values.publisheddate) {
    error.publisheddate = "Por favor complete la fecha de publicación de la noticia.";
  }

  if (!values.tags) {
    error.tags = "Por favor complete los tags de la noticia.";
  }

  if (!values.id && !values.imgupload) {
    error.imgupload = "Por favor selecciona una foto de portada de la noticia.";
  }

  return error;
};

const stringToBoolean = (string) => {
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

export class AddnewsForm extends Component {


  constructor(props) {
    super(props);

    let admin = localStorage.getItem("admin");
    if (!admin) admin = "1";
    this.state = {
      id: null,
      content: "",
      picture: null,
      admin: stringToBoolean(admin),
      addclass: "auto",
      startDate: new Date()
    };
    if (props.id) {
      this.state.id = props.id;
      this.state.addclass = "100%";
    }
    if (props.picture) this.state.picture = props.picture;
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange = (event, input) => {
    try {
      event.preventDefault();
      let imageFile = event.target.files[0];
      if (imageFile) {
        const localImageUrl = URL.createObjectURL(imageFile);
        const imageObject = new window.Image();
        imageObject.onload = () => {
          imageFile.width = imageObject.naturalWidth;
          imageFile.height = imageObject.naturalHeight;
          URL.revokeObjectURL(imageFile);
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          canvas.height = imageObject.naturalHeight;
          canvas.width = imageObject.naturalWidth;
          ctx.drawImage(imageObject, 0, 0);
          let base64 = canvas.toDataURL(imageFile.type);
          this.setState({ picture: base64 });
        };
        imageObject.src = localImageUrl;
      } else {
        this.setState({ picture: null });
      }
      input.onChange(imageFile);
    } catch (error) {
      this.setState({ picture: null });
    }
  };

  handleSelectChange = (event, input) => {
    try {
      event.preventDefault();
      input.onChange(event.target.value);
    } catch (error) {
      console.log("error", error);
    }
  };

  renderMyField = ({ input, meta, label, accept, type, name, placeholder }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input
          {...input}
          id={name}
          type={type ? type : "text"}
          accept={accept ? accept : null}
          placeholder={placeholder}
          invalid={meta.touched && !!meta.error}
          locale="es"
          className="contacto-input"
        />
        {meta.touched && meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  handleDatePickerChange = (date, input) => {
    try {
      input.onChange(date);
      this.setState({startDate: date});
    } catch (error) {
      console.log("error", error);
    }
  };

  renderDatePicker = ({ input, meta, label, name }) => {
    return (
      <FormGroup>
        {<Label for={name}>{label}</Label>}
        <DatePicker 
            {...input}
            fixedHeight
            isClearable
            closeOnScroll
            id={name}
            dateFormat="dd/MM/yyyy"
            todayButton="Hoy"
            selected={this.state.startDate}
            onChange={(date) => this.handleDatePickerChange(date, input)}
            className="contacto-input form-control"
            invalid={meta.error}
            style={{ textAlign: "center" }}
          />
        {meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  renderVisibility = ({ input, meta, label, type, name }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input
          {...input}
          id={name}
          type={type ? type : "select"}
          invalid={meta.touched && !!meta.error}
          className="contacto-input"
        >
          <option value="1">Pública</option>
          <option value="2">Privada</option>
          <option value="3">Ambas</option>
        </Input>
        {meta.touched && meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  renderLanguage = ({ input, meta, label, type, name }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input
          {...input}
          id={name}
          type={type ? type : "select"}
          invalid={meta.touched && !!meta.error}
          className="contacto-input"
        >
          <option value="en">Inglés</option>
          <option value="es">Español</option>
        </Input>
        {meta.touched && meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  renderImageInput = ({ input, name, label, type, meta }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Input
          id={name}
          type={type}
          invalid={meta.touched && !!meta.error}
          accept="image/png, image/jpeg"
          className="contacto-input"
          onChange={(event) => this.handleImageChange(event, input)}
        />
        {meta.touched && meta.error && (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        )}
      </FormGroup>
    );
  };

  handleEditorChange = (handler) => (content, editor) => {
    handler(content);
  };

  renderEditor = ({
    input: { onChange, ...inputProps },
    meta,
    label,
    name,
    placeholder,
  }) => {
    return (
      <FormGroup>
        <Label for={name}>{label}</Label>
        <Editor
          {...inputProps}
          id={name}
          className={
            meta.touched && !!meta.error ? "clase-error" : "clase-comun"
          }
          apiKey={TINYMCE_KEY}
          initialValue={placeholder}
          onEditorChange={this.handleEditorChange(onChange)}
          init={{
            height: 750,
            width: "100%",
            menubar: false,
            plugins: [PLUGINS],
            toolbar: TOOLBAR,
          }}
        />
        {meta.touched && meta.error ? (
          <FormFeedback className="signup-formfeedback">
            {meta.error}
          </FormFeedback>
        ) : null}
      </FormGroup>
    );
  };

  render() {
    const {
      handleSubmit,
      handleDelete,
      handlePreview,
      handleSuspend,
      handlePublish,
      submitting,
      pristine,
      submitSucceeded,
      error,
    } = this.props;

    return (
      <>
        <Container fluid>
          <Row>
            {this.state.id ? (
              <Col>
                <NewsButton
                  color="primary"
                  className="news-button float-right"
                  label="Vista previa"
                  customStyle={{
                    width: "100%",
                    height: "0.5rem",
                    fontSize: "1rem",
                  }}
                  handleClick={handlePreview}
                  disabled={submitting}
                />
              </Col>
            ) : null}

            {this.state.id && this.state.admin ? (
              <Col>
                <NewsButton
                  color="primary"
                  className="news-button float-right"
                  label="Publicar"
                  customStyle={{
                    width: "100%",
                    height: "0.5rem",
                    fontSize: "1rem",
                  }}
                  handleClick={handlePublish}
                  disabled={submitting}
                />
              </Col>
            ) : null}

            <Col>
              <NewsButton
                color={"success"}
                className="news-button float-right"
                label={this.state.id ? "Actualizar" : "Crear"}
                customStyle={{
                  width: this.state.addclass,
                  height: "0.5rem",
                  fontSize: "1rem",
                }}
                handleClick={handleSubmit}
                disabled={submitting}
              />
            </Col>

            {this.state.id ? (
              <Col>
                <NewsButton
                  color="warning"
                  className="news-button float-right"
                  label="Suspender"
                  customStyle={{
                    width: "100%",
                    height: "0.5rem",
                    fontSize: "1rem",
                  }}
                  handleClick={handleSuspend}
                  disabled={submitting}
                />
              </Col>
            ) : null}

            {this.state.id ? (
              <Col>
                <NewsButton
                  color="danger"
                  className="news-button float-right"
                  label="Eliminar"
                  customStyle={{
                    width: "100%",
                    height: "0.5rem",
                    fontSize: "1rem",
                  }}
                  handleClick={handleDelete}
                  disabled={submitting}
                />
              </Col>
            ) : null}
          </Row>
        </Container>

        <Container fluid>
          <Row
            className="contacto-s1-row clearfix"
            style={{ paddingBottom: "2rem" }}
          ></Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Field
                label="Título"
                name="title"
                placeholder="Título de la noticia"
                component={this.renderMyField}
                withFocus={true}
                value={this.state.title}
              />
            </Col>
            <Col xs={12} sm={12} md={9}>
              <Field
                label="Subtítulo"
                name="subtitle"
                placeholder="Subtítulo de la noticia"
                component={this.renderMyField}
                withFocus={true}
              />
            </Col>
            <Col xs={12} sm={12} md={3}>
              <Field
                label="F. Publicación"
                name="publisheddate"
                component={this.renderDatePicker}
                withFocus={true}
              />
            </Col>
          </Row>

          <Row className="contacto-s1-row">
            <Col xs={12} sm={12} md={6}>
              <Field
                label="Tags"
                name="tags"
                placeholder="Tags de la noticia"
                component={this.renderMyField}
                withFocus={true}
              />
            </Col>
            
            <Col xs={12} sm={12} md={3}>
              <Field
                label="Idioma"
                name="language"
                component={this.renderLanguage}
                withFocus={true}
              />
            </Col>

            <Col xs={12} sm={12} md={3}>
              <Field
                label="Visualización"
                name="visibility"
                component={this.renderVisibility}
                withFocus={true}
              />
            </Col>

            <Col xs={12} sm={12} md={6}>
              <Field
                name="imgupload"
                label="Foto de la noticia"
                type="file"
                component={this.renderImageInput}
              />
            </Col>

            <Col xs={12} sm={12} md={6}>
              <img
                name="imgpreview"
                style={{ height: "auto", width: "10rem", maxHeight: "10rem" }}
                src={this.state.picture ? this.state.picture : DEFIMAGE}
                alt="Vista previa"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Field
                label="Contenido de la noticia"
                name="richeditor"
                placeholder=""
                component={this.renderEditor}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default setPropsToInitialValues(
  reduxForm({ form: "AddnewsForm", touchOnBlur: false, validate })(AddnewsForm)
);