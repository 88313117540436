import React, { useState, useContext } from 'react';

const getReportsList = () => {
  
    const reportsList = [        
        { num: 1, name: "sale", link: "/reports/sale", title: "Reporte de ventas", description: "Reporte de ventas"},
        { num: 2, name: "admin", link: "/reports/admin", title: "Reporte de administración", description: "Reporte de administración"},
        { num: 3, name: "wallet", link: "/reports/wallet", title: "Reporte de wallet", description: "Reporte de wallet"},
        { num: 4, name: "salary", link: "/reports/salary", title: "Reporte de sueldo", description: "Reporte de sueldo"},
        { num: 5, name: "contable", link: "/reports/contable", title: "Reporte contable", description: "Reporte contable"}
    ];

    return reportsList;
  
}

export { getReportsList };