import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { LogIn, LogInVerifyGoogle2FA, LogInAccess, changeUserProps } from '../../store/action-creators/UserActions';
import { saveCotizaciones } from '../../store/action-creators/GlobalActions';
import generateSha256 from '../../services/generateSha256';
import { Input, FormFeedback, Button, Row, Col, Spinner, Modal, InputGroup } from 'reactstrap';
import LoginForm from './LoginForm';
import { ROUTE_VAULTS, ROUTE_LOGIN_FORGOT_PASSWORD, ROUTE_SIGNUP, ROUTE_PROFILE } from '../../constants/routes';
import { SubmissionError } from 'redux-form';
import { FiArrowLeft } from 'react-icons/fi';
import { GoMailRead } from 'react-icons/go';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation, Trans } from 'react-i18next';

const validate = (values) => {
	let errors = {};
	let generalError = '';

	let reqPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)\S{8,}$/;
					  
	if (!values.usuario || !values.password) {
		generalError = 'Todos los campos son requeridos.\n';
	}

	if (!values.usuario) {
		errors.usuario = 'Ingresar usuario';
	}

	if (!values.password) {
		errors.password = 'Ingresar una contraseña';
	}

	if (values.usuario && values.usuario.length < 5) {
		errors.usuario = 'Usuario incorrecto';
		generalError = 'error';
	}
	if (values.password && (values.password.length < 8 || !reqPassword.test(values.password))) {
		errors.password = 'Contraseña incorrecta';
		generalError = 'error';
	}

	return {
		errors,
		generalError,
	};
};

const LoginEmail = () => {
	const dispatch = useDispatch();
	let history = useHistory();

	const { t, i18n } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [LoginError, setLoginError] = useState('');
	const savedToken = sessionStorage.getItem('token');
	const token = useSelector((store) => store.user.token);	

	const [googleAuth2FAModal, setGoogleAuth2FAModal] = useState(false);
	const [googleAuth2FACode, setGoogleAuth2FACode] = useState('');
	const [googleAuth2FACodeError, setGoogleAuth2FACodeError] = useState('');
	const [googleAuth2FALoading, setGoogleAuth2FALoading] = useState(false);

	const [accessModal, setAccessModal] = useState(false);
	const [accessError, setAccessError] = useState('');
	const [accessLoading, setAccessLoading] = useState(false);

	useEffect(() => {
		return () => {
			// delete errors generated by unsuccess login
			dispatch(changeUserProps({ error: '' }));
		};
	}, []);

	const handleSubmit = (values) => {
		/* 
        Para que la propiedad submitting de redux-form se setee correctamente tenemos que devolver una promesa
        */

		return new Promise(async (resolve, reject) => {
			const errorsObject = validate(values);
			console.log('errorsObject', errorsObject);
			const { generalError, errors } = errorsObject;

			if (generalError) {
				reject(new SubmissionError(errors));
			} else {
				setIsLoading(true);
				let password = await generateSha256(values.password);				
				let user = Object.assign({}, values, {
					password: password					
				});

				dispatch(LogIn(user))
					.then((result) => {
						console.log('LogIn result', result);
						resolve();
					})
					.catch((error) => {
						setIsLoading(false);
						if (error.code == 402 || error.code == 405) {
							setLoginError('Usuario o contraseña incorrecto');
							return;
						} 
						if (error.code == 403) {
							setLoginError('Cuenta bloqueda. 5 intentos fallidos');
							return;
						}	
						if (error.code == 407) {
							setLoginError('Cuenta suspendida');
							return;
						}	
						
					});
			}
		});
	};

	const handleOnSubmitSuccess = () => {
		// setIsLoading(false);
		// setGoogleAuth2FACodeError('');
		// setGoogleAuth2FALoading(true);
		// setGoogleAuth2FAModal(false);
		// setAccessModal(true);
		setGoogleAuth2FAModal(true);
	};

	const VerifyGoogleAuth2FACode = () => {
		if (!googleAuth2FACode) {
			setGoogleAuth2FACodeError('ingresar código');
			// setGoogleAuth2FACodeError('');
			// setGoogleAuth2FALoading(true);
			setGoogleAuth2FAModal(true);
			// setAccessModal(true);
		} else {
			setGoogleAuth2FACodeError('');
			setGoogleAuth2FALoading(true);

			let credentials = {
				one_time_password: googleAuth2FACode,
				token: token ? token : savedToken,
			};

			dispatch(LogInVerifyGoogle2FA(credentials))
				.then((result) => {
					console.log('LogInVerifyGoogle2FA result', result);
					setGoogleAuth2FACodeError('');
					setGoogleAuth2FALoading(false);
					setGoogleAuth2FAModal(false);
					setAccessModal(true);
				})
				.catch((error) => {
					setAccessModal(false);
					setGoogleAuth2FALoading(false);
					let errorMessage = 'Código incorrecto';
					setGoogleAuth2FACodeError(errorMessage);
				});
		}
	};

	const VerifyLogInAccess = () => {
		setAccessError('');
		setAccessLoading(true);
		let credentials = {
			token: token ? token : savedToken,
		};		

		dispatch(LogInAccess(credentials))
			.then((res) => {				
				let preferences = JSON.parse(res.payload.preferences);
				dispatch(saveCotizaciones(res.payload.cotizaciones));
				setAccessLoading(false);
				setAccessModal(false);

				// console.log(preferences);
				// i18n.changeLanguage(preferences.language);
				// console.log('antes');
				history.push(ROUTE_PROFILE);
				// console.log('despues');
			})
			.catch((error) => {
				setAccessLoading(false);
				let errorMessage = 'Acceso no autorizado';
				setAccessError(errorMessage);
			});
	};

	const goBack = () => {
		history.goBack();
	};

	return (
		<div className="login" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
			<img src={process.env.PUBLIC_URL + '/images/gbm-logo.png'} alt="GBM Logo" className="gbm-logo" />
			{isLoading ? (
				<Spinner type="grow" color="success" style={{ marginTop: '2rem' }} />
			) : (
				<div>
					<div className="form-container">
						<Row className="basic-row back">
							<Col sm={6}></Col>
							<Col sm={6}>
								<span onClick={() => goBack()} className="go-back-link">
									<FiArrowLeft />
									&nbsp;{'Volver'}
								</span>
							</Col>
						</Row>
						<div className="welcome-message">{'Bienvenido'}</div>
						<LoginForm
							onSubmit={handleSubmit}
							onSubmitSuccess={handleOnSubmitSuccess}
							LoginError={LoginError}
						/>
					</div>
					<Row className="login-footer">
						<Link to={ROUTE_LOGIN_FORGOT_PASSWORD}>{'¿Olvidaste tu contraseña?'}</Link>
					</Row>
					<Row className="login-footer">
						<span>{'¿No tienes una cuenta? '}</span> &nbsp;
						<Link to={ROUTE_SIGNUP}>{'Regístrate ahora'}</Link>
					</Row>
				</div>
			)}

			<Modal isOpen={googleAuth2FAModal} centered className="login-modal">
				<div className="login">
					<Row className="modal-google-row">
						<img
							src={process.env.PUBLIC_URL + '/images/login/googleauth.png'}
							alt="Google Authenticator"
							className="modal-img"
						/>
					</Row>
					{googleAuth2FALoading ? (
						<Spinner type="grow" color="success" style={{ marginTop: '2rem' }} />
					) : (
						<>
							<Row className="login-modal-row">
								{'Ingrese el código generado por Google Authenticator'}
							</Row>
							<Row className="login-modal-row">
								<div className="login-input-container">
									<InputGroup>
										<Input
											id={'googleAuth2FACode'}
											placeholder="&#xf05d; &nbsp; Codigo"
											type={'text'}
											className="login-input google2fa"
											autoComplete="new-password"
											invalid={!!googleAuth2FACodeError}
											value={googleAuth2FACode}
											onChange={(event) => setGoogleAuth2FACode(event.target.value)}
										/>
									</InputGroup>

									{googleAuth2FACodeError ? (
										<FormFeedback className="login-formfeedback">
											{googleAuth2FACodeError}
										</FormFeedback>
									) : null}
								</div>
							</Row>
							<Row className="login-modal-row button">
								<Button
									color=""
									style={{
										backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)`,
									}}
									className="login-button"
									onClick={() => VerifyGoogleAuth2FACode()}
								>
									{'Enviar código'}
								</Button>
							</Row>
						</>
					)}
				</div>
			</Modal>

			<Modal isOpen={accessModal} centered className="login-modal">
				<div className="login">
					<Row className="login-modal-row">
						<GoMailRead size={100} />
					</Row>
					{accessLoading ? (
						<Spinner type="grow" color="success" style={{ marginTop: '2rem' }} />
					) : (
						<>
							<Row className="login-modal-row">{'Te enviamos un mail para autorizar el acceso.'}</Row>
							<Row className="login-modal-row">
								{
									'Despues de acceder al link enviado en el mail, vuelve a esta pantalla y presiona el boton ACCEDER.'
								}
							</Row>
							{accessError ? (
								<Row className="modal-google-row">
									<p style={{ color: 'red' }}>{accessError}</p>
								</Row>
							) : null}
							<Row className="login-modal-row button">
								<Button
									color=""
									style={{
										backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)`,
									}}
									className="login-button"
									onClick={() => VerifyLogInAccess()}
								>
									{'ACCEDER'}
								</Button>
							</Row>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default LoginEmail;
