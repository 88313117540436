import { GET_REPORT_SALE, GET_REPORT_ADMIN, GET_REPORT_WALLET, GET_REPORT_SALARY, GET_REPORT_CONTABLE } from '../action-types';
import { createAction } from 'redux-actions';
import { apiPost } from '../../api/Api';
import { URL_ASGARD } from '../../api/urls';

export const getReportSale = createAction(GET_REPORT_SALE, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/reports/getReportSale`, data)
);

export const getReportAdmin = createAction(GET_REPORT_ADMIN, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/reports/getReportAdmin`, data)
);

export const getReportWallet = createAction(GET_REPORT_WALLET, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/reports/getReportWallet`, data)
);

export const getReportSalary = createAction(GET_REPORT_SALARY, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/reports/getReportSalary`, data)
);

export const getReportContable = createAction(GET_REPORT_CONTABLE, (data) =>
	apiPost(`${URL_ASGARD}/api/admin/reports/getReportContable`, data)
);