import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { enableEmail, getAdminsEnabled, delAdminsEnabled} from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import Select from 'react-select';
import moment from 'moment';
import { verifyRoles } from '../../functions/roles';


const EnableAdmin = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);
	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);	
	const [sectionEnableEmail, setSectionEnableEmail] = useState(false);
	const [total, setTotal] = useState(0);
	const [emailSearched, setEmailSearched] = useState("");
	const [newEmail, setNewEmail] = useState("");
	const [emailsList, setEmailsList] = useState("");	
	const [errorEnable, setErrorEnable] = useState("");
	const [refresh, setRefresh] = useState("");
	const [responseEnable, setResponseEnable] = useState("");
	const [rolesHabilitado, setRolesHabilitado] = useState([1]);

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });

		dispatch(getAdminsEnabled(data))
			.then((res) => {				
				setEmailsList(res.payload.emails);
				setTotal(res.payload.total);
			})
			.catch((error) => {
				
			});
	}, []);

    
	const onShowEnableEmail = () => {	
		setSectionRegistros(false);
		setSectionEnableEmail(true);
	}

	const onBackInit = () => {
		setSectionEnableEmail(false);
		setSectionRegistros(true);		
	};

	const onEnableEmail = () => {
				
		if(!newEmail){
			setErrorEnable("Ingresar email");
			return;
		}

		setErrorEnable("");
		setResponseEnable("");

		let data = Object.assign({}, { token: savedToken, email: newEmail });
		dispatch(enableEmail(data))
		.then((res) => {				
			
			let objeto = JSON.stringify({ 
                created_at: "",
                email: "",               
                owner: ""          
            });
            objeto = JSON.parse(objeto);

        	objeto.created_at = res.payload.created_at;
        	objeto.email = res.payload.email;
        	objeto.owner = res.payload.owner;

        	emailsList.push(objeto);

        	// Ordena las ganancias por fecha. Mayor a menor
	        emailsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); 	        

    		setEmailsList(emailsList);
    		setResponseEnable(moment().format('YYYY-MM-DD hh:mm:ss')+". "+newEmail+"  se habilitó con exito");
        	

		})
		.catch((error) => { });

	};

	const onDelete = (email) => {
		
		let data = Object.assign({}, { token: savedToken, id_admin_enabled: email.id_admin_enabled });

		dispatch(delAdminsEnabled(data))
			.then((res) => {				
							
				let i;
				for(i=0;i<emailsList.length;i++){
					if(emailsList[i].id_admin_enabled == email.id_admin_enabled){						
						emailsList.splice(i,1);
					}
				}
				
				let newTotal = total - 1;								
				setTotal(newTotal);
	        	setEmailsList(emailsList);

	        	setRefresh(Math.random()*1000);

			})
			.catch((error) => { });		
	}

	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-enableAdmin">				

						<span className="page-title">Habilitar emails</span>

						<Card className="admin-main-card">														

							{sectionRegistros ? (
								<div className="registros">
									
									<Button className="btn-enable" onClick={onShowEnableEmail}>Habilitar Email</Button>
									
									<div className="header">
										
										<Row>
											<Col sm={8}>
												<p className="title">Emails = {total}</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={emailSearched}
														placeholder='Buscar email'
														onChange={(event) => setEmailSearched(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="emails-list">								
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>
													<Th>Email</Th>											
													<Th>Owner</Th>
													<Th>Eliminar</Th>
												</Tr>
											</Thead>
											<Tbody>
												{emailsList.length > 0 ?
													emailsList.map((email, index) => {
														
														if (email.email.toLowerCase().indexOf(emailSearched) >= 0 || emailSearched === ''){														
															return (
																<Tr key={index}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{email.created_at}
																		</Moment>
																	</Td>
																	<Td>{email.email}</Td>
																	<Td>{email.owner}</Td>
																	<Td><a href="#!" onClick={()=>onDelete(email)}>eliminar</a></Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}


							{sectionEnableEmail ? (
								<div className="enableEmail">							
									<Row>
										<Col sm={2}></Col>
										<Col sm={8} className="content">
											<p className="lnk-back" onClick={onBackInit}><a href="#!">volver</a></p>
											<h1 className="title">HABILITAR EMAIL</h1>

											<InputGroup className="inputGroup">
										        <InputGroupAddon addonType="prepend">								          
										          <InputGroupText className="btn-enable" onClick={onEnableEmail}>Habilitar Email </InputGroupText>
										        </InputGroupAddon>
										        <Input 
										        	value={newEmail}  
										        	onChange={(event) => setNewEmail(event.target.value)}
										        />
										    </InputGroup>		

							                <br/>
							                <p className="error">{errorEnable}</p>
							                <p className="response">{responseEnable}</p>					               
															
										</Col>
										<Col sm={2}></Col>
									</Row>
								</div>
							) : null}
					
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default EnableAdmin;
