import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogOut, updateToken } from '../store/action-creators/UserActions';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { IDLE_SESSION_TIMEOUT } from '../constants/general';
import {
	ROUTE_HOME,
	ROUTE_VAULTS,
	ROUTE_DONADORES,
	ROUTE_PROFILE,
	ROUTE_SEARCH_NEWS,
	ROUTE_SEARCH_USERS,
	/*ROUTE_LOGIN,*/
	ROUTE_DASHBOARD,
	ROUTE_ADMINISTRADORES,
	ROUTE_MOVIMIENTOS,
	ROUTE_USUARIOS,
	ROUTE_ROLES,
	ROUTE_SELLERS,
	ROUTE_SOCIOS,
	ROUTE_SALES_BY_CODE,
	ROUTE_CODIGOSDEDESCUENTO,
	ROUTE_ENABLE_ADMIN,
	ROUTE_ASSIGN_EARNINGS,
	ROUTE_INTERNAL_EARNINGS,
	ROUTE_PROFILES_ASOCIADOS_MOVEMENTS,
	ROUTE_PROFILES_VENDEDORES_VENTAS,
	ROUTE_USERS_MOVEMENTS,
	ROUTE_USERS_ADDRESSES,
	ROUTE_LOGS,
	ROUTE_ACCOUNTING,
	ROUTE_REPORTS,
	ROUTE_DONATIONS,
	ROUTE_DEPOSITS_LINKS
} from '../constants/routes';
import {
	Container,
	Row,
	Collapse,
	Modal,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import SidebarItem from '../components/SidebarItem';
import SidebarDropDown from '../components/SideBarDropDown';
import MobileSidebarItem from '../components/MobileSidebarItem';
import SidebarMobileDropDown from '../components/SideBarMobileDropDown';
import HexagonPhoto from '../components/HexagonPhoto';
import { FaBars } from 'react-icons/fa';

import { FiPhoneCall } from 'react-icons/fi'; ///##########

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const InactivityModal = (props) => {
	const { resetInactivity, inactiveUserTimeThresholdMin, onLogOut } = props;

	const [timeLeft, setTimeLeft] = useState(30);

	useEffect(() => {
		const logoutCountDown = setTimeout(() => {
			let newValue = timeLeft - 1;
			if (newValue === 0) {
				onLogOut();
			} else {
				setTimeLeft(newValue);
			}
		}, 1000);

		return () => clearTimeout(logoutCountDown);
	});

	return (
		<Modal isOpen={true} centered className="main-layout-modal">
			<Row className="main-layout-modal-row">
				<img src={process.env.PUBLIC_URL + '/images/gbm-logo.png'} alt="GBM Logo" className="modal-img" />
			</Row>
			<Row className="main-layout-modal-row">
				<span className="main-layout-modal-title">{'¿Sigues ahi?'}</span>
			</Row>
			<Row className="main-layout-modal-row">
				<p className="main-layout-modal-description">
					{`Han pasado ${inactiveUserTimeThresholdMin} min sin actividad. Seras deslogueado en ${timeLeft} segundos. Para seguir conectado haz click en el siguiente boton:`}
				</p>
			</Row>
			<Row className="main-layout-modal-row">
				<Button
					color=""
					style={{
						backgroundImage: `url(${process.env.PUBLIC_URL}/images/boton_sin_borde.png)`,
					}}
					className="main-layout-modal-button"
					onClick={() => resetInactivity()}
				>
					{'CANCELAR'}
				</Button>
			</Row>
		</Modal>
	);
};

const MainPrivateLayout = (props) => {
	const dispatch = useDispatch();
	let history = useHistory();

	const savedToken = sessionStorage.getItem('token');
	
	const user = useSelector((store) => store.user.user);
	const [avatar, setAvatar] = useState('');
	
	useEffect(() => {		
		if(user && user.avatar){
			let url = process.env.REACT_APP_ASGARD+`/api/avatar/${user.avatar}?token=${savedToken}`;			
			setAvatar(url);	
		}					
	}, [user]);

	/* ---- Actualiza el token cada X tiempo ---- */

	// Terminar el proceso al salir del componente
	var tokenTimer;
	useEffect(() => {
		return () => {
			clearInterval(tokenTimer);
		};
	}, []);

	// Actualiza el token cada 60 min. En backend el token caduca en 65 min
	/* NO ELIMINAR
	useEffect(() => {
		tokenTimer = setInterval(function () {
			
			let data = Object.assign({}, { token: savedToken });
			dispatch(updateToken(data))
				.then((res) => { })				
				.catch((error) => {});

		}, 1440000);
	}, [savedToken]);
	*/
	

	const { children } = props;

	const [isOpen, setIsOpen] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(false);

	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
	let pages = [];


	const toggleMobileSidebar = () => setMobileSidebarOpen(!mobileSidebarOpen);

	const onToggle = () => {
		setIsCollapsed(!isCollapsed);
	};

	const toggle = () => setIsOpen(!isOpen);

	const onLogOut = () => {
		let token = sessionStorage.getItem('token');

		dispatch(
			LogOut({
				token,
			})
		)
			.then((result) => {
				history.push(ROUTE_HOME);
			})
			.catch((error) => {
				history.push(ROUTE_HOME);
			});
	};
	/*
  const goDashboard = () => {
    history.push(ROUTE_DASHBOARD);
  };
  */

	const ViewProfile = () => {
		history.push(ROUTE_PROFILE); //
	};

	

	// Verificación de los roles del usuario logueado
			

	if(user.roles){

		pages = [		
			{
				label: 'BÓVEDAS',
				iconPath: '/images/mainlayout/sidebar-icons/inicio_icon_desk.png',
				route: ROUTE_VAULTS,
				roles: [1,4,14,17]
			},			
			{
				label: 'ADMINISTRADORES',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_ADMINISTRADORES,
				roles: [1]
			},
			{ 
				label: 'ROLES', 
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png', 
				route: ROUTE_ROLES,
				roles: [1]
			},

			{
				label: 'USUARIOS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_USUARIOS,
				roles: [1,17]
			},

			{
				label: 'CODIGOS DE DESCUENTO',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_CODIGOSDEDESCUENTO,
				roles: [1]
			},
			{
				label: 'VENTAS POR CÓDIGO',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_SALES_BY_CODE,
				roles: [1]
			},
			{
				label: 'ACCOUNTING',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_ACCOUNTING,
				roles: [1,9]
			},	
			{
				label: 'ASIGNAR GANANCIAS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_ASSIGN_EARNINGS,
				roles: [1]
			},	
			{
				label: 'GANANCIAS INTERNAS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_INTERNAL_EARNINGS,
				roles: [1]
			},	
			{
				label: 'MOVIMIENTOS DE USUARIOS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_USERS_MOVEMENTS,
				roles: [1]
			},
			{
				label: 'DIRECCIONES PÚBLICAS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_USERS_ADDRESSES,
				roles: [1]
			},
			{
				label: 'HABILITAR ADMIN',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_ENABLE_ADMIN,
				roles: [1]
			},
			{
				label: 'MOVIMIENTOS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_PROFILES_ASOCIADOS_MOVEMENTS,
				roles: [4,14]
			},
			{
				label: 'LINKS DE DEPÓSITOS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_DEPOSITS_LINKS,
				roles: [1]
			},
			{
				label: 'DONACIONES',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_DONATIONS,
				roles: [1]
			},
			{
				label: 'LOGS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_LOGS,
				roles: [1,17]
			},
			{
				label: 'REPORTES',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_REPORTS,
				roles: [1]
			},
			{
				label: 'MIS VENTAS',
				iconPath: '/images/mainlayout/sidebar-icons/usuario.png',
				route: ROUTE_PROFILES_VENDEDORES_VENTAS,
				roles: [15]
			}			
		];

		// Elimina las secciones

		let i,j,t,found;
		for(i=0;i<pages.length;i++){					

			found = 0;

			for(j=0;j<user.roles.length;j++){

				for(t=0;t<pages[i].roles.length;t++){
					if(user.roles[j].id_admin_role == pages[i].roles[t]){
						found = 1;					
					}
				}
			}

			if(!found){
				pages.splice(i,1);
				i--;
			}
		}
		
	}

	pages[pages.length] = {
		label: 'SALIR',
        iconPath: '/images/mainlayout/sidebar-icons/logout.png',
        handleClick: onLogOut,
	}


	const [idleModal, setIdleModal] = useState(false);

	const inactiveUserTimeThresholdMin = IDLE_SESSION_TIMEOUT;
	const inactiveUserTimeThreshold = 1000 * 60 * inactiveUserTimeThresholdMin;

	const handleOnIdle = (event) => {
		setIdleModal(true);
	};

	const handleOnActive = (event) => {
		// se llama cuando el usuario deja de estar inactivo
	};

	const handleOnAction = (e) => {
		// se llama para cada uno de los eventos pasados en la prop events
	};

	const { reset } = useIdleTimer({
		timeout: inactiveUserTimeThreshold, // limite de tiempo en milisegundos que el usuario puede permanecer inactivo antes de que se ejecute onIdle
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		events: [
			'mousemove',
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mouseWheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'visibilitychange',
		],
		debounce: 500,
		eventsThrottle: 300, // mejora perfomance al evitar que onMouseMove ejecuto los metodos multiples veces
	});

	const resetInactivity = () => {
		setIdleModal(false);
		reset();
	};

	return (
		<Container fluid id="wrapper" className="section-mainPrivateLayout">
			{/* <!-- Page Content --> */}

			<Navbar
				className="navbar-gbm"
				expand="md"
				light
				style={{
					backgroundImage: `url(${process.env.PUBLIC_URL}/images/mainlayout/gradiente_header.png)`,
				}}
			>
				<NavbarBrand>
					<img
						src={process.env.PUBLIC_URL + '/images/nav-bar-logo.png'}
						alt="GBM Logo"
						width="auto"
						height="60"
						className="gbm-navbar-logo"
						loading="lazy"
					/>
				</NavbarBrand>
				<NavbarToggler onClick={toggleMobileSidebar} className="navbar-toggler" tag={Button} color="">
					<FaBars size={25} style={{ color: 'white' }} />
				</NavbarToggler>
				
			</Navbar>

			<div className="mainlayout-content">
				{/* MOBILE SIDEBAR */}
				<Modal
					isOpen={mobileSidebarOpen}
					toggle={toggleMobileSidebar}
					className="mobile-sidebar"
					modalTransition={{ timeout: 150 }}
					backdropTransition={{ timeout: 150 }}
				>
					<div className="sidebar-profile-photo" style={{cursor: 'pointer'}} onClick={() => ViewProfile()}>
						<HexagonPhoto id={'mobile-sidebar'} width={64} image={avatar} style={{ cursor: 'pointer' }}/>
					</div>
					<div className="list-group list-group-flush">
						{pages.map((page, index) => {
							if (page.route) {
								return (
									<MobileSidebarItem
										key={`sidebar-${index}-${page.label}`}
										iconPath={page.iconPath}
										label={page.label}
										route={page.route}
									/>
								);
							} else {
								return (
									<MobileSidebarItem
										key={`sidebar-${index}-${page.label}`}
										iconPath={page.iconPath}
										label={page.label}
										handleClick={page.handleClick}
									/>
								);
							}
						})}
					</div>
				</Modal>

				{/* COLLAPSED SIDEBAR */}
				<div
					className="border-right"
					id="sidebar-collapsed"
					className={isCollapsed ? 'collapsed-sidebar collapsed' : 'collapsed-sidebar'}
				>
					<div className="collapsed-sidebar-item" onClick={() => onToggle()}>
						<IoIosArrowForward size={30} style={{ color: '#0ca79b' }} />
					</div>
					<div className="sidebar-profile-photo" style={{cursor: 'pointer'}} onClick={() => ViewProfile()}>
						<HexagonPhoto id={'sidebar'} width={64} image={avatar}  style={{ cursor: 'pointer' }}/>
					</div>
					{pages.map((page, index) => {
						if (page.route) {
							return (
								<SidebarItem
									key={`sidebar-${index}-${page.label}`}
									iconPath={page.iconPath}
									label={page.label}
									route={page.route}
								/>
							);
						} else {
							return (
								<SidebarItem
									key={`sidebar-${index}-${page.label}`}
									iconPath={page.iconPath}
									label={page.label}
									handleClick={page.handleClick}
								/>
							);
						}
					})}
				</div>

				{/* EXPANDED SIDEBAR */}
				<div
					className="border-right"
					id="sidebar-expanded"
					className={isCollapsed ? 'expanded-sidebar collapsed' : 'expanded-sidebar'}
				>
					<div className="expanded-sidebar-profile-section">
						<div className="expanded-sidebar-collapse-button">
							<IoIosArrowBack size={25} className="collapse-icon" onClick={() => onToggle()} />
						</div>
						<div style={{ margin: '20px 0px', cursor: 'pointer' }} onClick={() => ViewProfile()}>
							<HexagonPhoto
								id={'expand-sidebar'}
								width={100}
								image={avatar}																
							/>
						</div>
						<div className="expand-sidebar-profile-row">
							<div className="col-2 expand-sidebar-profile-icon">
								<img
									src={process.env.PUBLIC_URL + '/images/mainlayout/sidebar-icons/usuario.png'}
									width="auto"
									height="20"
									alt=""
									loading="lazy"
								/>
							</div>
							<div className="col-12 expand-sidebar-profile-label name">
								<span>{user.usuario}</span>
							</div>
						</div>
					</div>
					<div className="list-group list-group-flush">
						{pages.map((page, index) => {
							if (page.route) {
								return (
									<SidebarItem
										key={`sidebar-expand-${index}-${page.label}`}
										expand={true}
										iconPath={page.iconPath}
										label={page.label}
										route={page.route}
									/>
								);
							} else {
								return (
									<SidebarItem
										key={`sidebar-expand-${index}-${page.label}`}
										expand={true}
										iconPath={page.iconPath}
										label={page.label}
										handleClick={page.handleClick}
									/>
								);
							}
						})}
					</div>
				</div>

				<div className="page-content">{children}</div>
			</div>

			{idleModal ? (
				<InactivityModal
					resetInactivity={resetInactivity}
					inactiveUserTimeThresholdMin={inactiveUserTimeThresholdMin}
					onLogOut={onLogOut}					
				/>
			) : null}
		</Container>
	);
};

export default MainPrivateLayout;
