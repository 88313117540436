import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import VaultContext from '../../context/vaultContext';
import styles from './movementDetail.module.scss';
import { nameOperation, nameVault, nameReason } from './movementDetail.js';

const MovementDetail = () => {
   	
	scroll.scrollTo(170);

	const context = useContext(VaultContext);

  	return (
  		
  		<div className={styles.section}>
	 		<p className={styles.back}><Link to={`/vaults/${context.data.company}`}>Volver </Link></p>
	 		
				<h1 className={styles.title}>DETALLES DEL MOVIMIENTO</h1>									
				<Table className="table">										
					<Tbody>
						<Tr className={styles.tr}>
							<Td>Id</Td>
							<Td>{context.data.movementDetail.id}								
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Fecha</Td>
							<Td>
								<Moment format="YYYY-MM-DD HH:mm:ss" utc>
									{context.data.movementDetail.updated_at}
								</Moment>
							</Td>							
						</Tr>		 				
						<Tr className={styles.tr}>
							<Td>Usuario</Td>
							<Td>{context.data.movementDetail.usuario}</Td>																										
						</Tr>
						<Tr className={styles.tr}>
							<Td>Bóveda</Td>
							<Td>{nameVault(context.data.movementDetail.vault)}
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Operacion</Td>
							<Td>{nameOperation(context.data.movementDetail.operation)}
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Monto</Td>
							<Td>
								{context.data.movementDetail.value} {context.data.movementDetail.symbol}
								{
									context.data.movementDetail.value_usd ?
									<span> ~ ${context.data.movementDetail.value_usd} USD</span>
									:null																			
								}
								
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Tx Hash</Td>
							<Td>{context.data.movementDetail.tx_hash}								
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Tx URL</Td>
							<Td><a href={context.data.movementDetail.tx_url} target='_blank'>{context.data.movementDetail.tx_url} </a>
							</Td>							
						</Tr>
						<Tr className={styles.tr}>
							<Td>Comentario</Td>
							<Td>{context.data.movementDetail.comment}</Td>
						</Tr>
						{
							context.data.movementDetail.tipo == 107 && context.data.company == "heimdall" ? // 107: Operación desconocida en Blockchain
								<Tr className={styles.tr}>
									<Td>Acción</Td>
									<Td><Link to="declare"><span className={styles.action}>declarar movimiento</span></Link></Td>
								</Tr>
							:null
						}
												
					</Tbody>
				</Table>											

	  	</div>
   )

}

export default MovementDetail;
