import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { verifyUserAndEmail, changeUserProps } from '../../store/action-creators/UserActions';
import generateSha256 from '../../services/generateSha256';
import { Row, Col, Spinner } from 'reactstrap';
import SignupForm from './SignupEmailForm';
import { ROUTE_SIGNUP_SMS, ROUTE_LOGIN } from '../../constants/routes';
import { SubmissionError } from 'redux-form';
//import { isEmpty } from 'lodash';
import { FiArrowLeft } from "react-icons/fi";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const validate = values => {
    let errors = {};
    let generalError = "";    
    let reqPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)\S{8,}$/;                             

    if(!values.documents || values.documents === ""){
        generalError = "Debe aceptar los términos y condiciones";
    }

    if (!values.usuario || !values.email || !values.password || !values.nombre || !values.apellido || !values.pais) {
        generalError = 'Todos los campos son requeridos.\n';
    }   

    if (!values.nombre) {
        errors.nombre = 'Ingrese un nombre';
    }

    if (!values.apellido) {
        errors.apellido = 'Ingrese un apellido';
    }

    if (!values.pais) {
        errors.pais = 'Ingrese un pais';
    }

    if (!values.usuario) {
        errors.usuario = 'Ingrese un nombre de usuario.';
    } else if (values.usuario.length < 5) {
        errors.usuario = 'Debe contener al menos 5 caracteres.';
        generalError = generalError + 'Debe contener al menos 5 caracteres.\n';
    }

    if (!values.email) {
        errors.email = 'Debe ingresar su email.';
    } else if (!(/@/.test(values.email))) {
        errors.email = 'Formato no valido.';
        generalError = generalError + 'Formato no valido.\n';
    }

    if (!values.password) {
        errors.password = 'Ingrese una contraseña.';
    } else if (values.password.length < 8) {
        errors.password = 'Debe contener al menos 8 caracteres.';
        generalError = generalError + 'Debe contener al menos 8 caracteres.\n';
        
        }else if(!reqPassword.test(values.password)){
            errors.password = 'Mínimo 1 mayúscula, 1 minúscula y 1 número';
            generalError = "error";
        }

    
      
    return {
        errors,
        generalError
    };
};


const SignupEmail = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isLoading, setIsLoading] = useState(false);
    const [SignUpError, setSignUpError] = useState('');
    const userErrorStore = useSelector(store => store.user.error);   

    const [gralError, setGralError] = useState("");

    const handleSubmit = (values) => {

        setGralError("");
        if(!values.documents || values.documents === ""){
            setGralError("Debe aceptar los terminos y condiciones");
        }else{
            
            values.documents = 1;                    
            if(values.photoMap === true){
                values.photoMap = 1;
            }else{
                values.photoMap = 0;
            }
            
        } 
        
            return new Promise(async (resolve, reject) => {
                const errorsObject = validate(values);
                const { errors, generalError } = errorsObject;

                if ((generalError)) {
                    reject(new SubmissionError(
                        errors
                    ));
                }  
                else {
                    setIsLoading(true);
                    let password = await generateSha256(values.password);
                    let googleReCaptchaResponse = await executeRecaptcha("signup_page");
                    let user = Object.assign({}, values, { password: password, "g-recaptcha-response": googleReCaptchaResponse });

                    let data = Object.assign({},{usuario: values.usuario ,email: values.email})

                    dispatch(verifyUserAndEmail(data))
                        .then(result => {
                            dispatch(changeUserProps({ user: user }));
                            resolve();
                        })
                        .catch(err => {
                            setIsLoading(false);
                            if(err.code === 400){
                                setGralError("El usuario ya existe");
                                return
                            }
                            if(err.code === 401){
                                setGralError("Email inhabilitado");
                                return
                            }
                        });
                }
            })
        

    }

    const handleOnSubmitSuccess = () => {
        setIsLoading(false);
        history.push(ROUTE_SIGNUP_SMS);
    }

    useEffect(() => {
        if (userErrorStore !== SignUpError) {
            setSignUpError(userErrorStore);
        }

    },
        [userErrorStore]
    );

    const goBack = () => {
        history.goBack();
    }

    return (
        <div className='signup signupEmail text-center' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
            <img
                src={process.env.PUBLIC_URL + '/images/gbm-logo.png'}
                alt="GBM Logo"
                className='gbm-logo'
            />
            {    
                isLoading ?
                    <Spinner type="grow" className="spinner" color="success" />
                    :
                    <>
                        <div className="form-container">
                            <Row className="basic-row back">
                                <Col sm={6}></Col>
                                <Col sm={6}>
                                    <span onClick={() => goBack()} className="go-back-link">
                                        <FiArrowLeft />&nbsp;{"Volver"}
                                    </span>
                                </Col>
                            </Row>
                            <div className="welcome-message">
                                {"Bienvenido"}
                            </div>
                            <SignupForm
                                onSubmit={handleSubmit}
                                onSubmitSuccess={handleOnSubmitSuccess} 
                                gralError={gralError}                              
                            />                             
                        </div>
                        <Row className="signup-footer">
                            <span>{"¿Ya tienes una cuenta? "}</span>
                                &nbsp;
                <Link to={ROUTE_LOGIN}>{"Acceder"}</Link>
                        </Row>
                    </>
            }
        </div>

    );
};

const SignupEmailWithReCaptcha = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <SignupEmail />
        </GoogleReCaptchaProvider>
    );
};

export default SignupEmailWithReCaptcha;
