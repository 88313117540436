import React, { useState, useContext } from 'react';
import { Table } from 'reactstrap';
import ReportsContext from '../../context/reportsContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Moment from 'react-moment';

// styles
import styles from './reportContableDetail.module.scss';

const ReportContableDetail = () => {

	const context = useContext(ReportsContext);

	return(

		<div>

			<ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="report_salary"
                sheet="report_sale"
                buttonText="Descargar a Excel"
            />


			<Table responsive className={styles.table} id="table-to-xls">
			  <thead>
			    <tr>
			      <th>Fecha</th>
			      <th>ID</th>
			      <th>Tx Hash</th>
			      <th>Transaction Type</th>
			      <th>Vault Name</th>
			      <th>Reason</th>
			      <th>Account Record</th>
			      <th>Description</th>
			      <th>Receiver Account</th>
			      <th>Coin Type</th>
			      <th>Coin Amount</th>
			      <th>Value in USD</th>
			      <th>Value in EUR</th>
			      <th>Fee Coin Type</th>
			      <th>Fee Amount in Coin</th>
			      <th>Fee Amount USD</th>
			      <th>Fee Amount Euro</th>
			      <th>Sender Account</th>
			      <th>Receiver Account</th>
			      <th>Exchange Rate USD/EUR</th>
			      <th>Historial Value ETH</th>
			      <th>Has  the sign been modify?</th>
			      <th>Original Value</th>
			    </tr>
			  </thead>
			  <tbody>
			   	{context.data.reportContable.length > 0 ?
					context.data.reportContable.map((report, index) => {
						return(

							<tr key={index}>
								<td><Moment format="YYYY-MM-DD HH:mm" utc>{report.updated_at}</Moment></td>
								<td>{report.id}</td>
								<td>{report.txHash}</td>
								<td>{report.transactionType}</td>
								<td>{report.boveda}</td>
								<td>{report.motivo}</td>
								<td>{report.accountingRercord}</td>
								<td>{report.description}</td>
								<td>{report.source}</td>
								<td>{report.coinType}</td>
								<td>{report.coinAmount}</td>
								<td>{report.valueUSD}</td>
								<td>{report.valueEUR}</td>
								<td>{report.feeCoinType}</td>
								<td>{report.feeAmountCoin}</td>
								<td>{report.feeAmountUSD}</td>
								<td>{report.feeAmountEUR}</td>
								<td>{report.sender}</td>
								<td>{report.receiver}</td>
								<td>{report.rateUSDEUR}</td>
								<td></td>
								<td></td>
							</tr>

						);
					})
					:null	
				}
			  </tbody>
			</Table>

		</div>

	);

}

export default ReportContableDetail;