import React from 'react';
import ActionsHito from './actionsHito.jsx';

const Index = (props) => {
		
	return(

		<ActionsHito hito={props.hito} />

	)

}

export default Index;