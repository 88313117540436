import React, { useState, useContext } from 'react';
import { Table } from 'reactstrap';
import ReportsContext from '../../context/reportsContext';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Moment from 'react-moment';

// styles
import styles from './reportAdminDetail.module.scss';

const ReportAdminDetail = () => {

	const context = useContext(ReportsContext);

	return(

		<div>

			<ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="report_admin"
                sheet="report_sale"
                buttonText="Descargar a Excel"
            />


			<Table responsive className={styles.table} id="table-to-xls">
			  <thead>
			    <tr>
			      <th>Fecha</th>	
			      <th className={styles.hidden}>Id</th>
				  <th className={styles.hidden}>Txn Hash</th>
			      <th>Tipo de transacción</th>
			      <th>Bóveda</th>
			      <th>Motivo</th>			      			      
			      <th className={styles.hidden}>Dirección cuenta origen</th>
			      <th className={styles.hidden}>Dirección cuenta destino</th>
			      <th>Tipo de Moneda de la transacción</th>
			      <th>Cantidad de la moneda</th>
			      <th className={styles.hidden}>Montos convertidos a USD</th>
			      <th className={styles.hidden}>Montos convertidos a Euros</th>
			      <th className={styles.hidden}>Tipo de moneda de Fee / Gastos</th>
			      <th className={styles.hidden}>Monto del FEE</th>
			      <th className={styles.hidden}>Montos convertidos a USD</th>
			      <th className={styles.hidden}>Montos convertidos a Euros</th>
			      <th className={styles.hidden}>Nombre del dueño de cuenta origen</th>
			      <th className={styles.hidden}>Nombre del dueño de la cuenta destino</th>			      
			    </tr>
			  </thead>
			  <tbody>
			   	{context.data.reportAdmin.length > 0 ?
					context.data.reportAdmin.map((report, index) => {
						return(

							<tr key={index}>
								<td>{report.fecha}</td>	
							    <td className={styles.hidden}>{report.id}</td>
								<td className={styles.hidden}>{report.hash}</td>
							    <td>{report.tipo}</td>
							    <td>{report.boveda}</td>
							    <td>{report.motivo}</td>			      			      
							    <td className={styles.hidden}>{report.source}</td>
							    <td className={styles.hidden}>{report.dest}</td>
							    <td>{report.tipoCoin}</td>
							    <td>{report.cantCoin}</td>
							    <td className={styles.hidden}>{report.cantCoinUSD}</td>
							    <td className={styles.hidden}>{ (report.cantCoinUSD * report.USDEUR).toFixed(2) }</td>
							    <td className={styles.hidden}>{report.tipoCoinFee}</td>
							    <td className={styles.hidden}>{report.cantCoinFee}</td>
							    <td className={styles.hidden}>{report.cantCoinFeeUSD}</td>
							    <td className={styles.hidden}>{ (report.cantCoinFeeUSD * report.USDEUR).toFixed(2) }</td>
							    <td className={styles.hidden}>{report.cantCoinEUR}</td>
							    <td className={styles.hidden}>{report.nombreSource}</td>
							    <td className={styles.hidden}>{report.nombreDest}</td>								
							</tr>

						);
					})
					:null	
				}
			  </tbody>
			</Table>

		</div>

	);

}

export default ReportAdminDetail;