const nameVault = (props) => {
		
	switch (props) {
		case 1:
			return 'Interna';
		case 2:
			return 'Externa';
		case 3:
			return 'Corporativa';
	}
		
}

const idVault = (props) => {
		
	switch (props) {
		case 'internal_vault':
			return 1;
		case 'external_vault':
			return 2;
		case 'corporate_vault':
			return 3;			
	}
		
}

const showSymbolFee = (props) => {

	if(props == "GBM" || props == "DAI" || props == "USDT"){
		return "ETH";
	}

	return props;

}

const getTypeOptions = (operation) => {

	const options = [
	    { value: '100', label: 'Carga de fondos', operation:1 },
		{ value: '101', label: 'Retorno', operation:2 },
		{ value: '102', label: 'SendTesting', operation:2 },
		{ value: '103', label: 'Pago a proveedor', operation:2 },
		{ value: '105', label: 'Honorario Profesional', operation:2 },						
		{ value: '108', label: 'Pago de Servicio', operation:2 },
		{ value: '109', label: 'Transferencia a tercero', operation:2 },
		{ value: '110', label: 'Transferencia entre cuenta propia', operation:2 },
		{ value: '111', label: 'Préstamo', operation:2 },
		{ value: '112', label: 'Venta de Criptoactivos', operation:2 },
		{ value: '113', label: 'Gastos', operation:2 },
	    { value: '106', label: 'Otro', operation:'x' }, // para las 2 operaciones
	    
	]

	let i;
	for(i=0;i<options.length;i++){
		if(options[i].operation != 'x'){
			if( options[i].operation != operation){
				options.splice(i,1);
				i--;
			}
		}
	}

	return options;

}

const nameOperation = (props) => {

	switch (props) {
		case 1:
			return 'Depósito';
		case 2:
			return 'Retiro';
	}	
	
}

export { nameVault, idVault, showSymbolFee, getTypeOptions, nameOperation };