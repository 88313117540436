import React, { useState, useEffect } from "react";
//import { SubmissionError } from "redux-form";
import { Row, Col, Spinner, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { ROUTE_ADD_NEWS } from "../../constants/routes";
import "react-toastify/dist/ReactToastify.css";
import MainPrivateLayout from "../../config/MainPrivateLayout";
import SearchnewsForm from "./SearchnewsForm";
import NewsTable from "../../components/NewsTable";
import { getNews, getNewsStates, findNews } from '../../store/action-creators/NewsActions';

const SearchNews = (props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [edition, setEdition] = useState(0);
  const [published, setPublished] = useState(0);
  const [suspended, setSuspended] = useState(0);
  const [removed, setRemoved] = useState(0);
  const [total, setTotal] = useState(0);
  const savedToken = sessionStorage.getItem('token');
  //const savedToken = sessionStorage.getItem('token');
  //console.log('token', token);
  //console.log('savedToken', savedToken);

  let history = useHistory();

  const handleAddNews = (values) => {
    history.push(ROUTE_ADD_NEWS);
  };

  const handleSubmit = (values) => {
    return new Promise(function (resolve, reject) {
      setIsLoading(true);
      setSearch(values.search);
      dispatch(findNews({token: savedToken, all: 1, search: values.search}))
      .then(res => {
          let news = res.payload.data;
          setTableData(news);
          resolve(news);
          setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
    });
  };

  const handleOnSubmitSuccess = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    let data = {token: savedToken, all: 1};
    console.log('data', data);
    dispatch(getNews(data))
      .then(res => {
          let news = res.payload.data;
          setTableData(news);
          setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
    dispatch(getNewsStates({token: savedToken}))
      .then(res => {
        let estados = res.payload.data;
        console.log('estados', estados);
        if (estados) {
          setEdition(estados.edition);
          setPublished(estados.published);
          setSuspended(estados.suspended);
          setRemoved(estados.removed);
          setTotal(estados.edition + estados.published + estados.suspended + estados.removed);
        } else {
          setEdition(0);
          setPublished(0);
          setSuspended(0);
          setRemoved(0);
          setTotal(0);
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  }, []);

  return (
    <MainPrivateLayout>
      <div className="page-content-container">
        <span className="page-title">Búsqueda de noticias</span>
        <Row className="dashboard-row">
          <Col xs="12" sm="12" md="12" lg="12">
            <ToastContainer
              position="top-right"
              draggable={false}
            ></ToastContainer>
            {isLoading ? (
              <Spinner color="success" />
            ) : (
              <>
                <Row>
                  <Col xs={6} sm={3} md={2}>
                    <Label>{"Total: " + total}</Label>
                  </Col>
                  <Col xs={6} sm={3} md={2}>
                    <Label>{"En edición: " + edition}</Label>
                  </Col>
                  <Col xs={6} sm={3} md={2}>
                    <Label>{"Publicadas: " + published}</Label>
                  </Col>
                  <Col xs={6} sm={3} md={2}>
                    <Label>{"Suspendidas: " + suspended}</Label>
                  </Col>
                  <Col xs={6} sm={3} md={2}>
                    <Label>{"Eliminadas: " + removed}</Label>
                  </Col>
                </Row>
                <SearchnewsForm
                  search={search}
                  onSubmit={handleSubmit}
                  handleAddNews={handleAddNews}
                  onSubmitSuccess={handleOnSubmitSuccess}
                />
                <NewsTable tableData={tableData} search={search} />
              </>
            )}
          </Col>
        </Row>
      </div>
    </MainPrivateLayout>
  );
};

export default SearchNews;
