import React from 'react';
import styles from './back.module.scss';
import { Link } from "react-router-dom";

const Back = (props) => {	
	
	return(
		 
		<p className={styles.back} onClick={props.handleClick}>Volver</p>

	)

};

export default Back;