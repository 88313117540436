import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import { Row, Card, Col, Badge, Button, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_WALLET_BUY } from '../../constants/routes';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { BsSearch } from 'react-icons/bs';
import { getUsersMovements } from '../../store/action-creators/AdminActions';

const UsersMovements = () => {

	const dispatch = useDispatch();	
	const savedToken = sessionStorage.getItem('token');
	const [movimientos, setMovimientos] = useState('');
	const [historySymbol, setHistorySymbol] = useState('');
	const [pendingSymbol, setPendingSymbol] = useState('');
	const [registros, setRegistros] = useState(true);
	const [detail, setDetail] = useState(false);
	const [selectedMovement, setSelectedMovement] = useState('');

	useEffect(() => {
		funGetMovimientos();
	}, []);

	// Terminar el proceso de búsqueda del precio antes de dejar el componente
	var timer;
	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	}, []);

	function funGetMovimientos() {
		let data = Object.assign({}, { token: savedToken });
		dispatch(getUsersMovements(data))
			.then((res) => {
				setMovimientos(res.payload.movements);
			})
			.catch((error) => {});

		timer = setTimeout(funGetMovimientos, 300000);
	}

	function SwitchTipo(props) {
		switch (props.tipo) {
			case 1:
				return "Depósito";
			case 2:
				return "Retiro";
			case 3:
				return "Compra";
			case 4:
				return "Intercambio";
		}
	}

	function SwitchEstado(props) {
		if (props.status == 1) {
			return 'Pendiente';
		} else if(props.status == 2) {
			return 'Completo';
		}
	}

	const onBack = () => {
		setRegistros(true);
		setDetail(false);
	};

	const _handleKeyDown = (e) => {
        if (e.key === 'Enter') funGetMovimientos();
    }

    const acortarTxID = (txid) => {

    	const part1 = txid.substring(0,4);
    	const part2 = txid.substring(txid.length-4,txid.length);    	
    	const res = part1+'...'+part2;

    	return res;

    }

    const verifySwap = (input) => {

    	if(input.indexOf('|') >= 0){
    		// es swap
    		const part = input.split('|');
    		input = <span>{part[0]} <br></br> {part[1]}</span>;

    	}

    	return input;

    }

    const formatSwapByTxID = (input,pos) => {

    	switch(pos){

    		case 1:
    			input = input.substring(0, input.indexOf('|'));
    		break;

    		case 2:    			
    			input = input.substring(input.indexOf('|')+1, input.length );
    		break;

    	}

		return input;    	

    }

	return (
		<MainPrivateLayout>
			<div className="page-content-container section-movimientos">
				<span className="page-title">MOVIMIENTOS</span>

				<Card className="main-card">
					{registros ? (
						<div className="registros">
							<p>Se actualiza cada 5 min</p>
							<div className="pending">
								<Row>
									<Col sm={8}>
										<p className="title">MOVIMIENTOS PENDIENTES</p>
									</Col>
									<Col sm={4}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" className="prepend">
												<InputGroupText>
													<BsSearch />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="buscar activo..."
												onChange={(event) => setPendingSymbol(event.target.value)}
												autoComplete="new-password"
												onKeyDown={(e) => _handleKeyDown(e)}
											/>
										</InputGroup>
									</Col>
								</Row>

								<hr />

								<Table className="table">
									<Thead>
										<Tr>
											<Th>Fecha</Th>
											<Th>Usuario</Th>
											<Th>Operación</Th>
											<Th>Activo</Th>
											<Th>Cantidad</Th>											
										</Tr>
									</Thead>
									<Tbody>
										{movimientos.length > 0
											? movimientos.map((movimiento, index) => {
													if (
														movimiento.status == 1 && 
														(movimiento.asset.indexOf(pendingSymbol.toUpperCase()) >= 0 ||
															pendingSymbol === '')
													) {
														return (
															<Tr key={index}>
																<Td>
																	<Moment format="YYYY-MM-DD HH:mm:ss" utc>
																		{movimiento.fecha}
																	</Moment>
																</Td>
																<Td>{movimiento.usuario}</Td>
																<Td>																	
																	<SwitchTipo tipo={movimiento.tipo} />
																</Td>
																<Td>{verifySwap(movimiento.asset)}</Td>
																<Td>
																	{verifySwap(movimiento.amount)}
																</Td>																
															</Tr>
														);
													}
											  })
											: null}
									</Tbody>
								</Table>
							</div>{' '}
							{/* end pending */}
							
							<div className="history">
								<Row>
									<Col sm={8}>
										<p className="title">HISTORIAL</p>
									</Col>
									<Col sm={4}>
										<InputGroup>
											<InputGroupAddon addonType="prepend" className="prepend">
												<InputGroupText>
													<BsSearch />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Buscar activo..."
												onChange={(event) => setHistorySymbol(event.target.value)}
												autoComplete="new-password"
												onKeyDown={(e) => _handleKeyDown(e)}
											/>
										</InputGroup>
									</Col>
								</Row>
								<hr />

								<Table className="table">
									<Thead>
										<Tr>
											<Th>Fecha</Th>
											<Th>Usuario</Th>
											<Th>Operación</Th>
											<Th>Activo</Th>
											<Th>Cantidad</Th>
											<Th>TXID</Th>
										</Tr>
									</Thead>
									<Tbody>
										{movimientos.length > 0
											? movimientos.map((movimiento, index) => {
													if (
														movimiento.status == 2 &&
														(movimiento.asset.indexOf(historySymbol.toUpperCase()) >=0 ||
															historySymbol === '')
													) {
														return (
															<Tr key={index}>
																<Td>
																	<Moment format="YYYY-MM-DD HH:mm:ss" utc>
																		{movimiento.fecha}
																	</Moment>
																</Td>
																<Td>{movimiento.usuario}</Td>
																<Td>																	
																	<SwitchTipo tipo={movimiento.tipo} />
																</Td>
																<Td>																	
																	{verifySwap(movimiento.asset)}																		
																</Td>
																<Td>
																	{verifySwap(movimiento.amount)}
																</Td>
																<Td>
																	{
																		movimiento.tipo == 4 ?
																		<>
																			<a href={formatSwapByTxID(movimiento.txUrl,1)} target="_blank">
																				{acortarTxID(formatSwapByTxID(movimiento.txHash,1))}
																			</a>
																			<br />
																			<a href={formatSwapByTxID(movimiento.txUrl,2)} target="_blank">
																				{acortarTxID(formatSwapByTxID(movimiento.txHash,2))}
																			</a>
																		</>
																		:
																			<a href={movimiento.txUrl} target="_blank">
																				{acortarTxID(movimiento.txHash)}
																			</a>
																																				
																	}
																		
																</Td>
															</Tr>
														);
													}
											  })
											: null}
									</Tbody>
								</Table>
							</div>{' '}
							{/* History */}
						</div>
					) : null}
					
				</Card>
			</div>
		</MainPrivateLayout>
	);
};

export default UsersMovements;
