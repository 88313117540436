import React from 'react';
import ReportSale from './reportSale.jsx';

const Index = () => {

	return(

		<ReportSale />

	)

}

export default Index;