import React from 'react';
import ReportsStorage from './reportsStorage.jsx';

const Index = (props) => {
	
	return(

		<ReportsStorage  />

	)

}

export default Index;