import React from 'react';
import styles from './button1.module.scss';
import { Button } from 'reactstrap';

const Button1 = (props) => {	
	
	return(
		 
		<Button className={styles.button} onClick={props.handleClick}>{props.text}</Button>

	)

};

export default Button1;