import React, { useState, useEffect } from 'react';
import styles from './depositLinkCopy.module.scss';
import { Popover, PopoverHeader } from 'reactstrap';
import { copyToClipboard } from '../../../../../services/copyToClipboard';
import { FiCopy } from 'react-icons/fi';

const DepositLinkCopy = (props) => {	
 	
	const [popoverOpen, setPopoverOpen] = useState(false);

	const onPopover = () => {
		setPopoverOpen(true);
		setTimeout(function () {
			setPopoverOpen(false);
		}, 1000);
	};
	
	return(
		<span>
			<FiCopy
				id="popover"
				className={styles.icon}
				onClick={() => copyToClipboard(props.link)}
			/>
			<Popover
				placement="top"
				isOpen={popoverOpen}
				target="popover"
				toggle={onPopover}
			>
				<PopoverHeader>
					Copiado!
				</PopoverHeader>
			</Popover>
		</span>
	)

};

export default DepositLinkCopy;