export function verifyRoles(roles,rolesHabilitado){

	if(roles){
		
		let i,j;
		for(i=0;i<roles.length;i++){
			for(j=0;j<rolesHabilitado.length;j++){
				if(roles[i].id_admin_role == rolesHabilitado[j]){
					return true;					
				}
			}
		}			

		return false;
		
	}		

}	