import React from 'react';
import SectionTitle from './sectionTitle.jsx';

const Index = (props) => {
		
	return(

		<SectionTitle text={props.text} />

	)

}

export default Index;