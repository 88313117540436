import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import VaultContext from '../../context/vaultContext';
import Qr from '../qr';
import styles from './deposit.module.scss';

const Deposit = () => {

	const context = useContext(VaultContext);

	scroll.scrollTo(170);

  	return (
  	
		<div className={styles.section}>							
			<p className={styles.back}><Link to={`/vaults/${context.data.company}`}>Volver </Link></p>
			<div className="text-center">
				<h1 className={styles.title}>Solo depositar {context.data.selectedVault.name}</h1>
				<Qr />
			</div>
			<p className={styles.address}>{context.data.selectedVault.address}</p>
		</div>  

   )

}

export default Deposit;
