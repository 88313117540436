import React from 'react';
import ManageDonated from './manageDonated.jsx';

const Index = () => {

	return(

		<ManageDonated />

	)

}

export default Index;