import React, { createContext } from 'react';

const structContext = {
	selectedReport: {},
	reportSale: {},
	reportAdmin: {},
	reportWallet: {},
	reportSalary: {},
	reportContable: {}
}  

const ReportsContext = createContext();

export { structContext };
export default ReportsContext;