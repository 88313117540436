import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Button } from 'reactstrap';
import { ROUTE_SIGNUP_EMAIL, ROUTE_LOGIN } from '../../constants/routes';
import { FaRegEnvelope } from 'react-icons/fa';

const Signup = () => {
	return (
		<div className="signup" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/Bg-Login.jpg)` }}>
			<img src={process.env.PUBLIC_URL + '/images/gbm-logo.png'} alt="GBM Logo" className="gbm-logo" />
			<div className="signup-options">
				<div className="signup-title">{'Regístrate con tu cuenta de:'}</div>
				{/* <Row className="signup-option text-center"> */}
				{/* <Button
                        color=""
                        className="signup-option-button"
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/images/login/facebook_logo.svg'}
                            alt="Facebook Logo"
                            className="signup-icon"
                        />
                        <span className="button-label">
                            {"Facebook"}
                        </span>
                    </Button>
                </Row>
                <Row className="signup-option">
                    <Button
                        color=""
                        className="signup-option-button"
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/images/login/twitter_logo.svg'}
                            alt="Twitter Logo"
                            className="signup-icon"
                        />
                        <span className="button-label">
                            {"Twitter"}
                        </span>
                    </Button>
                </Row> */}
				<Row className="signup-option">
					<Button color="" className="signup-option-button" tag={Link} to={ROUTE_SIGNUP_EMAIL}>
						<FaRegEnvelope size={'10%'} style={{ color: '#086326' }} />
						<span className="button-label">{'Email'}</span>
					</Button>
				</Row>
			</div>
			<Row className="signup-footer">
				<span>{'¿Ya tienes una cuenta? '}</span>
				&nbsp;
				<Link to={ROUTE_LOGIN}>{'Acceder'}</Link>
			</Row>
		</div>
	);
};

export default Signup;
