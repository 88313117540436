import React from 'react';
import DepositsLinksTable from './depositsLinksTable.jsx';

const Index = (props) => {
		
	return(

		<DepositsLinksTable />

	)

}

export default Index;