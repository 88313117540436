import React, { useEffect, useState } from 'react';
import MainPrivateLayout from '../../config/MainPrivateLayout';
import {
	Label,
	FormGroup,
	Row,
	Card,
	Col,
	Badge,
	Button,
	InputGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { getUsersAddresses } from '../../store/action-creators/AdminActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import { verifyRoles } from '../../functions/roles';


const UsersAddresses = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector((store) => store.user.user);

	const savedToken = sessionStorage.getItem('token');		
	const [sectionRegistros, setSectionRegistros] = useState(true);			
	const [searchedUser, setSearchedUser] = useState("");
	const [addressesList, setAddressesList] = useState([]);
	const [totalAddresses, setTotalAddresses] = useState(0);
	const [rolesHabilitado, setRolesHabilitado] = useState([1,17]);		

	useEffect(() => {
		
		let data = Object.assign({}, { token: savedToken });
		dispatch(getUsersAddresses(data))
			.then((res) => {	

				let i;
				for(i=0;i<res.payload.addresses.length;i++){
					if(res.payload.addresses[i].symbol == "BTC"){
						res.payload.addresses[i].url = "https://live.blockcypher.com/btc/address/"+res.payload.addresses[i].address;
					}else{
						if(res.payload.addresses[i].symbol == "ETH"){
							res.payload.addresses[i].url = "https://etherscan.io/address/"+res.payload.addresses[i].address;
						}else{
							if( res.payload.addresses[i].symbol == "USDT" || res.payload.addresses[i].symbol == "DAI" || res.payload.addresses[i].symbol == "GBM" ){
								if(res.payload.addresses[i].protocol == "erc20"){
									res.payload.addresses[i].url = "https://etherscan.io/address/"+res.payload.addresses[i].address;		
								}
								if(res.payload.addresses[i].protocol == "trc20"){
									res.payload.addresses[i].url = "https://tronscan.org/#/address/"+res.payload.addresses[i].address;
								}
							}
						}
					}
				}

				setAddressesList(res.payload.addresses);				
				setTotalAddresses(res.payload.totalAddresses);
			})
			.catch((err) => {
				console.log(err);
			});
		
	}, []);


	return (
		<MainPrivateLayout>

			{
				verifyRoles(user.roles,rolesHabilitado) ?

					<div className="page-content-container section-usersAddresses">

						<span className="page-title">Direcciones públicas</span>						

						<Card className="admin-main-card">							

							{sectionRegistros ? (
								<div className="registros">
 
									<div className="header">
										<Row>
											<Col sm={8}>
												<p className="title">Direcciones públicas = {totalAddresses} direcciones</p>
											</Col>
											<Col sm={4}>
												<InputGroup>
													<InputGroupAddon addonType="prepend" className="prepend">
														<InputGroupText>
															<BsSearch />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														value={searchedUser}
														placeholder='Usuario'
														onChange={(event) => setSearchedUser(event.target.value)}
														autoComplete="new-password"
													/>
												</InputGroup>
											</Col>
										</Row>
										<hr />
									</div>

									<div className="list">
										<Table className="table">
											<Thead>
												<Tr>											
													<Th>Fecha</Th>
													<Th>Usuario</Th>
													<Th>Símbolo</Th>
													<Th>Address</Th>
												</Tr>
											</Thead>
											<Tbody>
												{addressesList.length > 0 ?
													addressesList.map((address, index) => {
														
														if (address.usuario.toLowerCase().indexOf(searchedUser) >= 0 || searchedUser === ''){														
															return (
																<Tr key={index}>
																	<Td>
																		<Moment format="YYYY-MM-DD HH:mm" utc>
																			{address.created_at}
																		</Moment>
																	</Td>
																	<Td>{address.usuario}</Td>																	
																	<Td>{address.symbol}</Td>
																	<Td>

																			<a href={address.url} target="_blank">
																				{address.address}
																			</a>
																	</Td>
																</Tr>
															);
														}
															
													  })
													: null
												}
											</Tbody>
										</Table>
									</div>
								
								</div>
							) : null}						
							
						</Card>
					</div>
				: null
			}
		</MainPrivateLayout>
	);
};

export default UsersAddresses;
