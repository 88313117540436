import React, { useState } from 'react';
import { structContext } from '../context/reportsContext';

const Init = () => {
	
	const savedToken = sessionStorage.getItem('token');	
	const [dataContext,setDataContext] = useState(structContext);

    return { dataContext, setDataContext };

}

export { Init }

	

