// global
export const CHANGE_GLOBAL_PROPS = 'CHANGE_GLOBAL_PROPS';
export const COTIZACIONES = 'COTIZACIONES';
// user

export const USER_VERIFY_USER_AND_EMAIL = 'USER_VERIFY_USER_AND_EMAIL';
export const USER_SIGNUP_SEND_SMS = 'USER_SIGNUP_SEND_SMS';
export const USER_SIGNUP_VERIFY_SMS_CODE = 'USER_SIGNUP_VERIFY_SMS_CODE';
export const USER_SIGNUP_GOOGLE_2FA = 'USER_SIGNUP_GOOGLE_2FA';
export const USER_SIGNUP_COMPLETE_REGISTRATION = 'USER_SIGNUP_COMPLETE_REGISTRATION';
export const USER_SIGNUP_VERIFY_MAIL_CODE = 'USER_SIGNUP_VERIFY_MAIL_CODE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CHANGE_USER_PROPS = 'CHANGE_USER_PROPS';

// User login
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_VERIFY_GOOGLE_2FA = 'USER_LOGIN_VERIFY_GOOGLE_2FA';
export const USER_LOGIN_EMAIL_AUTHORIZE = 'USER_LOGIN_EMAIL_AUTHORIZE';
export const USER_LOGIN_ACCESS = 'USER_LOGIN_ACCESS';

// News
export const NEWS_GET_ALL = 'NEWS_GET_ALL';
export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_STATES = 'GET_NEWS_STATES';
export const CREATE_NEWS = 'CREATE_NEWS';
export const EDIT_NEWS = 'EDIT_NEWS';
export const SET_NEWS_STATE = 'SET_NEWS_STATE';
export const DELETE_NEWS = 'DELETE_NEWS';
export const SEARCH_NEWS = 'SEARCH_NEWS';
export const CHANGE_NEWS_PROPS = 'CHANGE_NEWS_PROPS';

// Password Reset
export const SEND_PASSWORD_RESET_LINK = 'SEND_PASSWORD_RESET_LINK';
export const PASSWORD_RESET = 'PASSWORD_RESET';

// Admin
export const GET_ROLES = 'GET_ROLES';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_USERS = 'GET_USERS';
export const SUSPEND_USER = 'SUSPEND_USER';
export const SUSPEND_ADMIN = 'SUSPEND_ADMIN';
export const EDIT_USERS_ROLE = 'EDIT_USERS_ROLE';
export const GET_SECTION_VAULTS = 'GET_SECTION_VAULTS';
export const SEND_DECLARATION_DEPOSIT = 'SEND_DECLARATION_DEPOSIT';
export const SEND_DECLARATION_WITHDRAWAL = 'SEND_DECLARATION_WITHDRAWAL';

// General
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

// codigo

export const CREATE_COUPON = 'CREATE_COUPON';
export const CREATE_DESCUENTO_CUPON = 'CREATE_DESCUENTO_CUPON';
export const GET_ADMINS_PER_ROLE = 'GET_ADMINS_PER_ROLE';
export const GET_COUPON = 'GET_COUPON';
export const ASSIGN_COUPON_CODE_USER = 'ASSIGN_COUPON_CODE_USER';
export const GENERATE_COUPON_CODE = 'GENERATE_COUPON_CODE';
export const GET_SALE_BY_CODE = 'GET_SALE_BY_CODE';
export const GET_ADMINS_ENABLED = 'GET_ADMINS_ENABLED';
export const ENABLE_EMAIL = 'ENABLE_EMAIL';
export const DEL_ADMINS_ENABLED = 'DEL_ADMINS_ENABLED';
export const GET_USER_MOVEMENTS = 'GET_USER_MOVEMENTS';
export const GET_EARNINGS_ASSIGNED = 'GET_EARNINGS_ASSIGNED';
export const ASSIGN_EARNINGSDEL_EARNINGS = 'ASSIGN_EARNINGS';
export const DEL_EARNINGS = 'DEL_EARNINGS';
export const EDIT_ROLES = 'EDIT_ROLES';
export const DISABLE_GOOGLE_2FA_USER = 'DISABLE_GOOGLE_2FA_USER';
export const DELETE_KYC_USER = 'DELETE_KYC_USER';
export const GET_VAULTS_MOVEMENTS = 'GET_VAULTS_MOVEMENTS';
export const UNLOCK_USER = 'UNLOCK_USER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const ASIGN_PASSWORD_USER = 'ASIGN_PASSWORD_USER';

export const PROFILE_ASOCIADOS_GET_VAULTS_MOVEMENTS = 'PROFILE_ASOCIADOS_GET_VAULTS_MOVEMENTS';
export const PROFILE_VENDEDORES_GET_VENTAS = 'PROFILE_VENDEDORES_GET_VENTAS';

export const USERS_MOVEMENTS = 'USERS_MOVEMENTS';
export const USERS_ADDRESSES = 'USERS_ADDRESSES';
export const GET_LOGS = 'GET_LOGS';
export const GET_SECTION_ACCOUNTING = 'GET_SECTION_ACCOUNTING';
export const GET_REPORT_SALE = 'GET_REPORT_SALE';
export const GET_REPORT_ADMIN = 'GET_REPORT_ADMIN';
export const GET_REPORT_WALLET = 'GET_REPORT_WALLET';
export const GET_REPORT_SALARY = 'GET_REPORT_SALARY';
export const GET_REPORT_CONTABLE = 'GET_REPORT_CONTABLE';

export const SEARCH_USER = 'SEARCH_USER';
export const ASIGN_DONATION = 'ASIGN_DONATION';
export const GET_DONATIONS = 'GET_DONATIONS';
export const GET_HITOS_PER_USER = 'GET_HITOS_PER_USER';
export const ADD_HITO = 'ADD_HITO';
export const GET_DONATED_MOVEMENTS = 'GEGET_DONATED_MOVEMENTST';
export const GET_DEPOSITS_LINKS = 'GET_DEPOSITS_LINKS';
export const GENERATE_DEPOSIT_LINK = 'GENERATE_DEPOSIT_LINK';




