import React, { useContext } from 'react';
import styles from './reportContable.module.scss';

// Atoms
import ReportHeader from '../../atoms/reportHeader';
import FilterBetweenDates from '../../atoms/filterBetweenDates';
import ReportContableDetail from '../../atoms/reportContableDetail';
import ReturnStart from '../../atoms/returnStart';

const ReportContable = () => {

	return(
		<div>
			<ReturnStart />
			<ReportHeader />
			<FilterBetweenDates />
			<ReportContableDetail />
		</div>
	);

}

export default ReportContable;