import React from 'react';
import DepositsLinksList from './depositsLinksList.jsx';

const Index = () => {
		
	return(

		<DepositsLinksList />

	)

}

export default Index;